export default function ClockIcon({ className }: IconProps) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' className={className}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.3999 22.3999C17.9227 22.3999 22.3999 17.9227 22.3999 12.3999C22.3999 6.87705 17.9227 2.3999 12.3999 2.3999C6.87705 2.3999 2.3999 6.87705 2.3999 12.3999C2.3999 17.9227 6.87705 22.3999 12.3999 22.3999ZM12.9999 7.1999C12.9999 6.64762 12.5522 6.1999 11.9999 6.1999C11.4476 6.1999 10.9999 6.64762 10.9999 7.1999V11.9999C10.9999 12.2651 11.1053 12.5195 11.2928 12.707L14.6869 16.1011C15.0774 16.4916 15.7106 16.4916 16.1011 16.1011C16.4916 15.7106 16.4916 15.0774 16.1011 14.6869L12.9999 11.5857V7.1999Z'
        fill='url(#paint0_linear_57_15532)'
      />
      <defs>
        <linearGradient
          id='paint0_linear_57_15532'
          x1='2.3999'
          y1='22.3999'
          x2='22.4629'
          y2='2.46329'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#8B1666' />
          <stop offset='1' stopColor='#E52979' />
        </linearGradient>
      </defs>
    </svg>
  )
}

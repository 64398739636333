import { Link } from 'react-router-dom'
import LogoMarkIcon from 'src/components/icons/LogoMarkIcon'
import Layout from 'src/layouts/Layout'

export default function NotFound() {
  return (
    <Layout>
      <div className='container'>
        <img
          src='/images/confetti.png'
          alt=''
          className='absolute left-1/2 top-1/2 -z-10 h-screen -translate-x-1/2 -translate-y-1/2 select-none object-cover'
        />
        <div className='mx-auto flex h-screen max-w-[608px] flex-col items-center justify-center space-y-6'>
          <div className='flex items-center gap-4'>
            <p className='text-[250px] font-bold leading-none text-white'>4</p>
            <LogoMarkIcon className='h-[244px]' />
            <p className='text-[250px] font-bold leading-none text-white'>4</p>
          </div>
          <p className='text-center text-4xl font-semibold text-white'>OOOPS ! PAGE NOT FOUND</p>
          <p className='text-center text-xl text-white'>
            Sorry the page you are looking for does not exist, if you think something is broken, report a problem
          </p>
          <div className='flex gap-3'>
            <Link to='/'>
              <button className='btn-primary-outlined tex-sm h-[54px] w-[190px] font-semibold text-white'>
                Return Home
              </button>
            </Link>
            <button className='btn-primary h-[54px] w-[190px] text-sm font-semibold text-white'>Report Problem</button>
          </div>
        </div>
      </div>
    </Layout>
  )
}

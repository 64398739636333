import { formatEther } from 'viem'
import { cx } from 'src/utils/helpers'
import CupIcon from './icons/CupIcon'

interface Props {
  className?: string
  totalPrize: string
}

export default function TotalPrizePool({ className, totalPrize }: Props) {
  return (
    <div className={cx('space-y-[22px] rounded-2xl bg-card-primary p-[22px]', className)}>
      <div className='flex items-center gap-2'>
        <CupIcon className='h-6' />
        <p className='text-lg font-medium text-white'>Total prize pool</p>
      </div>
      <div>
        <p className='shadow-1 flex h-[58px] items-center rounded-[5px] bg-card-secondary px-2 text-[32px] font-semibold text-white'>
          {formatEther(BigInt(totalPrize))}
        </p>
      </div>
    </div>
  )
}

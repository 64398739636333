import { useState } from 'react'
import useAppDispatch from 'src/hooks/useAppDispatch'
import { setWalletModalOpen } from 'src/redux/slices/modal'
import { cx, shortenAddress } from 'src/utils/helpers'
import { useAccount, useDisconnect } from 'wagmi'
import ChevronDownIcon from '../icons/ChevronDownIcon'
import LogoutIcon from '../icons/LogoutIcon'
import { apiLogout } from 'src/api/auth.api'
import useClickOutside from 'src/hooks/useClickOutside'

interface Props {
  className?: string
}

export default function ConnectWalletButton({ className }: Props) {
  const { address, isConnected, isConnecting } = useAccount()
  const dispatch = useAppDispatch()
  const { disconnect } = useDisconnect()
  const [open, setOpen] = useState<boolean>(false)

  const ref = useClickOutside(() => setOpen(false))

  const handleClick = () => {
    dispatch(setWalletModalOpen(true))
  }

  const handleClickDisconnect = () => {
    disconnect()
    setOpen(false)
    apiLogout()
  }

  if (isConnected) {
    return (
      <div className={cx('relative', className)} ref={ref}>
        <button
          className='flex h-[54px] items-center gap-2.5 rounded-full bg-card-primary pl-[9px] pr-3'
          onClick={() => setOpen((prev) => !prev)}
        >
          <div className='tanuki-gradient h-9 w-9 rounded-full' />
          <p className='text-sm font-medium text-gray'>{shortenAddress(address)} </p>
          <ChevronDownIcon
            className={cx('h-6 stroke-gray transition duration-300 ease-in-out', open ? '-rotate-180' : '')}
          />
        </button>
        <button
          className={cx(
            'absolute -bottom-[5px] flex h-10 w-full translate-y-full items-center gap-3 rounded-lg border border-card-secondary bg-card-primary px-4 transition duration-300 ease-in-out',
            open ? 'pointer-events-auto opacity-100' : 'pointer-events-none opacity-0'
          )}
          onClick={handleClickDisconnect}
        >
          <LogoutIcon className='h-4 stroke-gray' />
          <p className='text-sm font-medium text-white'>Log out</p>
        </button>
      </div>
    )
  }

  return (
    <div className={cx(className)}>
      <button className='btn-primary h-[54px] w-[190px] font-bold' onClick={handleClick}>
        {!isConnecting ? 'Connect wallet' : 'Connecting...'}
      </button>
    </div>
  )
}

interface Props extends IconProps {
  mode?: 'gradient' | 'gray'
}

export default function RandomCube1Icon({ className, mode = 'gradient', onClick = () => null }: Props) {
  return mode === 'gradient' ? (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' className={className} onClick={onClick}>
      <path
        d='M21.33 3.78C21.03 3.34 20.66 2.97 20.22 2.67C19.21 2 17.81 2 15 2H9C6.19 2 4.79 2 3.78 2.67C3.34 2.97 2.97 3.34 2.67 3.78C2.06 4.69 2.01 5.93 2 8.25V15.75C2.01 18.07 2.06 19.31 2.67 20.22C2.97 20.66 3.34 21.03 3.78 21.33C4.79 22 6.19 22 9 22H15C17.81 22 19.21 22 20.22 21.33C20.66 21.03 21.03 20.66 21.33 20.22C21.94 19.31 21.99 18.07 22 15.75V8.25C21.99 5.93 21.94 4.69 21.33 3.78ZM7.06 18.79C6.04 18.79 5.21 17.96 5.21 16.94C5.21 15.92 6.04 15.1 7.06 15.1C8.08 15.1 8.9 15.92 8.9 16.94C8.9 17.96 8.08 18.79 7.06 18.79ZM7.06 8.9C6.04 8.9 5.21 8.08 5.21 7.06C5.21 6.04 6.04 5.21 7.06 5.21C8.08 5.21 8.9 6.04 8.9 7.06C8.9 8.08 8.08 8.9 7.06 8.9ZM12 13.85C10.98 13.85 10.15 13.02 10.15 12C10.15 10.98 10.98 10.15 12 10.15C13.02 10.15 13.85 10.98 13.85 12C13.85 13.02 13.02 13.85 12 13.85ZM16.94 18.79C15.92 18.79 15.1 17.96 15.1 16.94C15.1 15.92 15.92 15.1 16.94 15.1C17.96 15.1 18.79 15.92 18.79 16.94C18.79 17.96 17.96 18.79 16.94 18.79ZM16.94 8.9C15.92 8.9 15.1 8.08 15.1 7.06C15.1 6.04 15.92 5.21 16.94 5.21C17.96 5.21 18.79 6.04 18.79 7.06C18.79 8.08 17.96 8.9 16.94 8.9Z'
        fill='url(#paint0_linear_29_1371)'
      />
      <defs>
        <linearGradient
          id='paint0_linear_29_1371'
          x1='2'
          y1='22'
          x2='22.063'
          y2='2.06339'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#8B1666' />
          <stop offset='1' stopColor='#E52979' />
        </linearGradient>
      </defs>
    </svg>
  ) : mode === 'gray' ? (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' className={className} onClick={onClick}>
      <path
        d='M21.33 3.78C21.03 3.34 20.66 2.97 20.22 2.67C19.21 2 17.81 2 15 2H9C6.19 2 4.79 2 3.78 2.67C3.34 2.97 2.97 3.34 2.67 3.78C2.06 4.69 2.01 5.93 2 8.25V15.75C2.01 18.07 2.06 19.31 2.67 20.22C2.97 20.66 3.34 21.03 3.78 21.33C4.79 22 6.19 22 9 22H15C17.81 22 19.21 22 20.22 21.33C20.66 21.03 21.03 20.66 21.33 20.22C21.94 19.31 21.99 18.07 22 15.75V8.25C21.99 5.93 21.94 4.69 21.33 3.78ZM7.06 18.79C6.04 18.79 5.21 17.96 5.21 16.94C5.21 15.92 6.04 15.1 7.06 15.1C8.08 15.1 8.9 15.92 8.9 16.94C8.9 17.96 8.08 18.79 7.06 18.79ZM7.06 8.9C6.04 8.9 5.21 8.08 5.21 7.06C5.21 6.04 6.04 5.21 7.06 5.21C8.08 5.21 8.9 6.04 8.9 7.06C8.9 8.08 8.08 8.9 7.06 8.9ZM12 13.85C10.98 13.85 10.15 13.02 10.15 12C10.15 10.98 10.98 10.15 12 10.15C13.02 10.15 13.85 10.98 13.85 12C13.85 13.02 13.02 13.85 12 13.85ZM16.94 18.79C15.92 18.79 15.1 17.96 15.1 16.94C15.1 15.92 15.92 15.1 16.94 15.1C17.96 15.1 18.79 15.92 18.79 16.94C18.79 17.96 17.96 18.79 16.94 18.79ZM16.94 8.9C15.92 8.9 15.1 8.08 15.1 7.06C15.1 6.04 15.92 5.21 16.94 5.21C17.96 5.21 18.79 6.04 18.79 7.06C18.79 8.08 17.96 8.9 16.94 8.9Z'
        fill='#667085'
      />
    </svg>
  ) : (
    <></>
  )
}

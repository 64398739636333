export default function SafepalIcon({ className }: IconProps) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 23' fill='none' className={className}>
      <g clipPath='url(#clip0_156_3886)'>
        <path
          d='M10.6355 0.453613C9.95966 0.453613 9.31154 0.722083 8.83369 1.19996L1.32728 8.70652C1.04297 8.9908 0.900816 9.36346 0.900818 9.73612C0.90082 10.1087 1.04298 10.4814 1.32729 10.7657L6.24646 15.6849V6.97612C6.24646 6.32623 6.76921 5.79935 7.4191 5.79935C10.1649 5.79935 16.8485 5.79935 17.7602 5.79935L23.1058 0.453689L10.6355 0.453613Z'
          fill='#262233'
        />
        <path
          d='M6.24671 17.3133H16.5836C17.2335 17.3133 17.7604 16.7865 17.7604 16.1365V7.42773L22.6796 12.347C22.9639 12.6313 23.106 13.0039 23.106 13.3766C23.106 13.7492 22.9639 14.1218 22.6796 14.4062L15.1732 21.9127C14.6953 22.3906 14.0472 22.6591 13.3714 22.6591L0.901062 22.659L6.24671 17.3133Z'
          fill='#262233'
        />
      </g>
      <defs>
        <clipPath id='clip0_156_3886'>
          <rect width='22.2052' height='22.2052' fill='white' transform='translate(0.900818 0.453613)' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default function EthereumIcon({ className }: IconProps) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 18 29' fill='none' className={className}>
      <path d='M8.66833 0.741699V10.8525L17.2141 14.6711L8.66833 0.741699Z' fill='#0D0D0D' fillOpacity='0.602' />
      <path d='M8.66601 0.741699L0.119141 14.6711L8.66601 10.8525V0.741699Z' fill='#262233' />
      <path d='M8.66833 21.2235V28.0936L17.2198 16.2627L8.66833 21.2235Z' fill='#0D0D0D' fillOpacity='0.602' />
      <path d='M8.66601 28.0936V21.2223L0.119141 16.2627L8.66601 28.0936Z' fill='#262233' />
      <path d='M8.66833 19.6337L17.2141 14.6718L8.66833 10.8555V19.6337Z' fill='#0D0D0D' fillOpacity='0.2' />
      <path d='M0.119141 14.6718L8.66601 19.6337V10.8555L0.119141 14.6718Z' fill='#0D0D0D' fillOpacity='0.602' />
    </svg>
  )
}

import Learning1Icon from 'src/components/icons/Learning1Icon'
import Learning2Icon from 'src/components/icons/Learning2Icon'
import Learning3Icon from 'src/components/icons/Learning3Icon'
import Learning4Icon from 'src/components/icons/Learning4Icon'

export default function HowDoesItWorkSection() {
  return (
    <section className='mt-[90px] lg:mt-12'>
      <div className='container space-y-6'>
        <h1>
          How does
          <br />
          Lottery it work?
        </h1>
        <div className='grid grid-cols-4 gap-6 lg:grid-cols-2 sm:grid-cols-1'>
          <div className='space-y-[22px] rounded-2xl bg-card-primary p-[22px]'>
            <Learning1Icon className='mx-auto h-[100px]' />
            <p className='text-center text-sm text-white'>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididun
            </p>
          </div>
          <div className='space-y-[22px] rounded-2xl bg-card-primary p-[22px]'>
            <Learning2Icon className='mx-auto h-[100px]' />
            <p className='text-center text-sm text-white'>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididun
            </p>
          </div>
          <div className='space-y-[22px] rounded-2xl bg-card-primary p-[22px]'>
            <Learning3Icon className='mx-auto h-[100px]' />
            <p className='text-center text-sm text-white'>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididun
            </p>
          </div>
          <div className='space-y-[22px] rounded-2xl bg-card-primary p-[22px]'>
            <Learning4Icon className='mx-auto h-[100px]' />
            <p className='text-center text-sm text-white'>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididun
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default function InfoIcon({ className }: IconProps) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 21 20' fill='none' className={className}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.5 9.29412C10.8898 9.29412 11.2059 9.61015 11.2059 10V13.7647C11.2059 14.1546 10.8898 14.4706 10.5 14.4706C10.1102 14.4706 9.79412 14.1546 9.79412 13.7647V10C9.79412 9.61015 10.1102 9.29412 10.5 9.29412Z'
      />
      <path d='M11.4412 7.17647C11.4412 7.69627 11.0198 8.11765 10.5 8.11765C9.9802 8.11765 9.55882 7.69627 9.55882 7.17647C9.55882 6.65667 9.9802 6.23529 10.5 6.23529C11.0198 6.23529 11.4412 6.65667 11.4412 7.17647Z' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.84315 4.34315C6.29009 2.8962 8.29106 2 10.5 2C12.7089 2 14.7099 2.8962 16.1569 4.34315C17.6038 5.79009 18.5 7.79106 18.5 10C18.5 12.2089 17.6038 14.2099 16.1569 15.6569C14.7099 17.1038 12.7089 18 10.5 18C8.29106 18 6.29009 17.1038 4.84315 15.6569C3.3962 14.2099 2.5 12.2089 2.5 10C2.5 7.79106 3.3962 5.79009 4.84315 4.34315ZM10.5 3.41176C8.68051 3.41176 7.03442 4.14841 5.84141 5.34141C4.64841 6.53442 3.91176 8.18051 3.91176 10C3.91176 11.8195 4.64841 13.4656 5.84141 14.6586C7.03442 15.8516 8.68051 16.5882 10.5 16.5882C12.3195 16.5882 13.9656 15.8516 15.1586 14.6586C16.3516 13.4656 17.0882 11.8195 17.0882 10C17.0882 8.18051 16.3516 6.53442 15.1586 5.34141C13.9656 4.14841 12.3195 3.41176 10.5 3.41176Z'
      />
    </svg>
  )
}

import { useId, useState } from 'react'
import { cx } from 'src/utils/helpers'
import CloseIcon from '../icons/CloseIcon'
import Money from '../Money'
import WadOfMoneyIcon from '../icons/WadOfMoneyIcon'
import useAppDispatch from 'src/hooks/useAppDispatch'
import { setWithdrawModalOpen } from 'src/redux/slices/modal'
import useAppSelector from 'src/hooks/useAppSelector'
import useClickOutside from 'src/hooks/useClickOutside'
import { formatEther, parseEther, zeroAddress } from 'viem'
import LotteryContract from 'src/contracts/Lottery.sol/Lottery.json'
import { LOTTERY_CONTRACT_ADDRESS } from 'src/config'
import toast from 'react-hot-toast'
import { useLotteryContract } from 'src/hooks/useLotteryContract'

export default function WithdrawModal() {
  const id = useId()
  const [loading, setLoading] = useState<boolean>(false)
  const [withdrawAmount, setWithdrawAmount] = useState<string>()
  const { withdraw } = useLotteryContract()
  const dispatch = useAppDispatch()
  const open = useAppSelector((state) => state.modal.withdrawModalOpen)
  const balance = useAppSelector((state) => state.user.balance)

  const handleCloseModal = () => {
    dispatch(setWithdrawModalOpen(false))
  }

  const handleWithdraw = async () => {
    if (!withdrawAmount || isNaN(Number(withdrawAmount))) {
      toast.error('Invalid deposit amount')
      return
    }

    try {
      setLoading(true)
      await withdraw(
        LotteryContract.abi,
        LOTTERY_CONTRACT_ADDRESS,
        parseEther((+withdrawAmount).toString()),
        zeroAddress
      )
    } catch (err) {
      console.log(err)
    } finally {
      setLoading(false)
    }
  }

  const ref = useClickOutside(handleCloseModal)

  return (
    <div
      className={cx(
        'fixed inset-0 z-[60] bg-background/60 backdrop-blur-[10px] transition duration-300 ease-in-out',
        open ? 'pointer-events-auto opacity-100' : 'pointer-events-none opacity-0'
      )}
    >
      <div className='container flex h-full items-center justify-center'>
        <div className='w-full max-w-[400px] rounded-2xl border border-card-secondary bg-card-primary pb-6' ref={ref}>
          <div className='flex items-center justify-between p-4'>
            <p className='text-xl font-semibold text-white'>Withdraw</p>
            <CloseIcon className='h-7 cursor-pointer' onClick={handleCloseModal} />
          </div>
          <div className='flex h-10 items-center justify-center gap-6 bg-card-secondary'>
            <p className='text-sm font-medium text-white'>Your balance</p>
            <Money amount={balance[0]?.amount_wei ? formatEther(balance[0].amount_wei) : null} size='small' />
          </div>
          <div className='space-y-6 p-4'>
            <p className='text-center text-sm font-medium text-white'>Enter the amount you want to withdraw</p>
            <label
              htmlFor={id}
              className='shadow-1 mx-auto flex h-10 w-full max-w-[288px] items-center gap-2 rounded-full bg-card-secondary px-3.5'
            >
              <WadOfMoneyIcon className='h-6' />
              <input
                className='text-sm font-medium text-white'
                id={id}
                value={withdrawAmount}
                onChange={(e) => setWithdrawAmount(e.target.value)}
              />
            </label>
            <button
              className='btn-primary h-[54px] w-full text-sm font-bold text-white'
              disabled={loading}
              onClick={handleWithdraw}
            >
              Withdraw
            </button>
            <p className='text-center text-xs text-gray'>
              By clicking the button “Withdraw”,
              <br />I confirm that I have read&nbsp;
              <span className='cursor-pointer font-semibold text-white'>Privacy Policy</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

import { useEffect, useState } from 'react'
import { apiGetInventoryHistory } from 'src/api/inventory.api'
import InventoryFilter from 'src/components/InventoryFilter'
import Pagination from 'src/components/Pagination'
import ArrowDownIcon from 'src/components/icons/ArrowDownIcon'
import { TicketStatus } from 'src/config'
import { cx, formatDateTime, getTicketFromString } from 'src/utils/helpers'

const tableHeader = [
  { label: 'Draw', value: 'draw' },
  { label: 'Date/Time', value: 'timestamp' },
  { label: 'Combination', value: 'numbers' },
  { label: 'Status', value: '' },
]

interface Props {
  onTabChange: React.Dispatch<'currently' | 'history'>
}

export default function HistoryTable({ onTabChange }: Props) {
  const [sort, setSort] = useState<{ method: string; direction: TSortDirection }>({ method: 'draw', direction: 'desc' })
  const [tickets, setTickets] = useState<any[]>([])
  const [currentPage, setCurrentPage] = useState<number>(0)
  const [hasNextPage, setHasNextPage] = useState<boolean>(false)

  useEffect(() => {
    getInventoryHistory(1, sort)
  }, [sort])

  const getInventoryHistory = async (page: number, sort: { method: string; direction: TSortDirection }) => {
    try {
      const data = await apiGetInventoryHistory(page, sort.method, sort.direction)
      setTickets(data.tickets || [])
      setCurrentPage(page)
      setHasNextPage(data.next_page)
    } catch (err) {
      console.log(err)
    }
  }

  const handleClickHeader = (value: string) => {
    if (value === '') {
      return
    }

    console.log(sort.method, value, sort.direction === 'asc')

    setSort((prev) =>
      prev.method === value
        ? { ...prev, direction: prev.direction === 'asc' ? 'desc' : 'asc' }
        : { method: value, direction: 'desc' }
    )
  }

  return (
    <div className='shadow-1 rounded-2xl bg-card-primary'>
      <div className='space-y-1 px-6 py-5'>
        <p className='text-lg font-medium text-white'>History</p>
        <p className='text-sm text-gray'>It shows which tickets are expired</p>
      </div>
      <div className='flex items-center justify-between border-b border-card-secondary px-6 py-5'>
        <InventoryFilter value='history' onClick={() => onTabChange('currently')} />
      </div>
      <div className='w-full overflow-y-auto sm:p-6'>
        <table className='w-full divide-y divide-card-secondary'>
          <thead>
            <tr>
              {tableHeader.map((th, i) => (
                <th key={i}>
                  <span
                    className='flex w-fit cursor-pointer items-center gap-1'
                    onClick={() => handleClickHeader(th.value)}
                  >
                    <p>{th.label}</p>
                    {th.value && (
                      <ArrowDownIcon
                        className={cx(
                          'h-4',
                          th.value === sort.method
                            ? sort.direction === 'asc'
                              ? 'rotate-180 stroke-white'
                              : 'stroke-white'
                            : 'stroke-gray'
                        )}
                      />
                    )}
                  </span>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {tickets.map((ticket, i) => (
              <tr className='border-b border-card-secondary' key={i}>
                <td className='font-medium text-white'>#{ticket.id}</td>
                <td className='font-medium text-gray'>{formatDateTime(ticket.timestamp)}</td>
                <td>
                  <span className='flex gap-3'>
                    {getTicketFromString(ticket.numbers).map((number: number, i: number) => (
                      <button
                        className={cx(
                          'shadow-1 h-9 w-9 rounded-full bg-card-secondary text-sm font-semibold text-white',
                          i === 5
                            ? 'secondary-gradient shadow-[0px_0px_30px] shadow-[#EB8D39]/40'
                            : 'shadow-1 bg-card-secondary'
                        )}
                      >
                        {number}
                      </button>
                    ))}
                  </span>
                </td>
                <td>
                  {ticket.status === TicketStatus.Claimed && (
                    <button className='shadow-1 main-gradient h-7 w-[120px] cursor-default rounded-lg text-sm font-semibold text-white'>
                      Claimed
                    </button>
                  )}
                  {ticket.status === TicketStatus.Unclaimed && (
                    <button className='shadow-1 btn-secondary-outlined h-7 w-[120px] font-semibold'>Unclaimed</button>
                  )}
                  {ticket.status === TicketStatus.NoWinner && (
                    <button className='shadow-1 h-7 w-[120px] cursor-default rounded-lg bg-[#EF5450] text-sm font-semibold text-white'>
                      No-winner
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className='px-6 pb-4 pt-3'>
        <Pagination
          isFirstPage={currentPage === 1}
          isLastPage={!hasNextPage}
          onPrev={() => getInventoryHistory(currentPage - 1, sort)}
          onNext={() => getInventoryHistory(currentPage + 1, sort)}
        />
      </div>
    </div>
  )
}

import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

interface IModal {
  walletModalOpen: boolean
  depositModalOpen: boolean
  withdrawModalOpen: boolean
  claimModalOpen: boolean
}

const initialState: IModal = {
  walletModalOpen: false,
  depositModalOpen: false,
  withdrawModalOpen: false,
  claimModalOpen: false,
}

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    setWalletModalOpen(state, action: PayloadAction<boolean>) {
      state.walletModalOpen = action.payload
    },
    setDepositModalOpen(state, action: PayloadAction<boolean>) {
      state.depositModalOpen = action.payload
    },
    setWithdrawModalOpen(state, action: PayloadAction<boolean>) {
      state.withdrawModalOpen = action.payload
    },
    setClaimModalOpen(state, action: PayloadAction<boolean>) {
      state.claimModalOpen = action.payload
    },
  },
})

export const { setWalletModalOpen, setDepositModalOpen, setWithdrawModalOpen, setClaimModalOpen } = modalSlice.actions

export default modalSlice.reducer

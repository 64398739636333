interface Props extends IconProps {
  mode?: 'gradient' | 'gray'
}

export default function EraseSquareIcon({ className, mode = 'gradient', onClick = () => null }: Props) {
  return mode === 'gradient' ? (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' className={className} onClick={onClick}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.46447 3.46447C2 4.92893 2 7.28595 2 12C2 16.714 2 19.0711 3.46447 20.5355C4.92893 22 7.28595 22 12 22C16.714 22 19.0711 22 20.5355 20.5355C22 19.0711 22 16.714 22 12C22 7.28595 22 4.92893 20.5355 3.46447C19.0711 2 16.714 2 12 2C7.28595 2 4.92893 2 3.46447 3.46447ZM8.98781 10.2886L13.7114 15.0122L16.33 12.3936C17.4433 11.2803 18 10.7236 18 10.0318C18 9.34008 17.4433 8.7834 16.33 7.67004C15.2166 6.55668 14.6599 6 13.9682 6C13.2764 6 12.7197 6.55668 11.6064 7.67004L8.98781 10.2886ZM12.3936 16.33L12.6507 16.0729L7.92715 11.3493L7.67004 11.6064C6.55668 12.7197 6 13.2764 6 13.9682C6 14.6599 6.55668 15.2166 7.67004 16.33C8.7834 17.4433 9.34008 18 10.0318 18C10.7236 18 11.2803 17.4433 12.3936 16.33Z'
        fill='url(#paint0_linear_29_1380)'
      />
      <defs>
        <linearGradient
          id='paint0_linear_29_1380'
          x1='2'
          y1='22'
          x2='22.063'
          y2='2.06339'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#8B1666' />
          <stop offset='1' stopColor='#E52979' />
        </linearGradient>
      </defs>
    </svg>
  ) : mode === 'gray' ? (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' className={className} onClick={onClick}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.46447 3.46447C2 4.92893 2 7.28595 2 12C2 16.714 2 19.0711 3.46447 20.5355C4.92893 22 7.28595 22 12 22C16.714 22 19.0711 22 20.5355 20.5355C22 19.0711 22 16.714 22 12C22 7.28595 22 4.92893 20.5355 3.46447C19.0711 2 16.714 2 12 2C7.28595 2 4.92893 2 3.46447 3.46447ZM8.98781 10.2886L13.7114 15.0122L16.33 12.3936C17.4433 11.2803 18 10.7236 18 10.0318C18 9.34008 17.4433 8.7834 16.33 7.67004C15.2166 6.55668 14.6599 6 13.9682 6C13.2764 6 12.7197 6.55668 11.6064 7.67004L8.98781 10.2886ZM12.3936 16.33L12.6507 16.0729L7.92715 11.3493L7.67004 11.6064C6.55668 12.7197 6 13.2764 6 13.9682C6 14.6599 6.55668 15.2166 7.67004 16.33C8.7834 17.4433 9.34008 18 10.0318 18C10.7236 18 11.2803 17.4433 12.3936 16.33Z'
        fill='#667085'
      />
    </svg>
  ) : (
    <></>
  )
}

import ConnectWalletModal from './ConnectWalletModal'
import DepositModal from './DepositModal'
import WithdrawModal from './WithdrawModal'

export default function Modals() {
  return (
    <>
      <ConnectWalletModal />
      <DepositModal />
      <WithdrawModal />
    </>
  )
}

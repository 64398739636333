import { configureStore } from '@reduxjs/toolkit'
import config from './slices/config'
import modal from 'src/redux/slices/modal'
import user from './slices/user'

export const store = configureStore({
  reducer: {
    config,
    modal,
    user,
  },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

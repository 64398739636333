import CheckIcon from '../icons/CheckIcon'

interface Props {
  checked?: boolean
  label?: string
  onClick?: () => void
}

export default function CheckBox({ checked = false, label, onClick = () => null }: Props) {
  return (
    <div className='flex w-fit cursor-pointer items-center gap-2' onClick={onClick}>
      <div className='flex h-4 w-4 items-center justify-center rounded bg-card-secondary'>
        {checked && <CheckIcon className='h-3' />}
      </div>
      {label && <p className='text-sm text-white'>{label}</p>}
    </div>
  )
}

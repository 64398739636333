export default function HeroSection() {
  return (
    <section className='relative flex h-[816px] items-center lg:h-[745px] sm:items-end'>
      <img src='/images/background.png' alt='' className='absolute inset-0 h-full w-full object-cover opacity-30' />
      <img
        src='/images/shine.png'
        alt=''
        className='absolute left-1/2 top-0 h-full w-[1440px] -translate-x-1/2 object-cover'
      />
      <img
        src='/images/tanuki.png'
        alt=''
        className='absolute left-[20.9%] top-[14.2%] w-[747px] sm:left-0 sm:top-[102px] sm:w-full'
      />
      <div className='container relative z-10 !max-w-7xl'>
        <div className='ml-auto w-full max-w-[614px] space-y-6 rounded-2xl bg-background/70 px-6 py-12 sm:-mx-4 sm:w-screen sm:max-w-none sm:rounded-none sm:bg-transparent sm:bg-gradient-to-t sm:from-background sm:via-background sm:to-transparent'>
          <p className='text-[40px] font-bold leading-tight text-white sm:text-4xl'>
            Join us today and experience the <span className='text-gradient'>magic of winning</span> with Tanuki
          </p>
          <p className='text-white sm:text-sm'>
            Ready to test your luck and chase that dream win? Powerball is your ticket to a big victory! Join our
            thrilling lottery platform and become one of the lucky few who can change their life with a single click.
          </p>
        </div>
      </div>
    </section>
  )
}

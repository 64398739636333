import { useEffect, useState } from 'react'
import { apiGetTickets } from 'src/api/public.api'
import Number from 'src/components/Number'
import Pagination from 'src/components/Pagination'
import { formatDateTime, getTicketFromString } from 'src/utils/helpers'

const tableHeader = [{ label: 'Draw' }, { label: 'Date/Time' }, { label: 'Combinations' }]

export default function RecentBetsSection() {
  const [tickets, setTickets] = useState<any[]>([])
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [hasNextPage, setHasNextPage] = useState<boolean>(false)

  useEffect(() => {
    getTickets(1)
  }, [])

  const getTickets = async (page: number) => {
    try {
      const data = await apiGetTickets(page)
      setTickets(data.tickets)
      setCurrentPage(page)
      setHasNextPage(data.next_page)
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <section className='mt-[90px] lg:mt-12'>
      <div className='container'>
        <div className='shadow-1 rounded-[15px] bg-card-primary/90'>
          <p className='px-6 py-5 text-lg font-medium text-white'>Recent bets</p>
          <div className='w-full overflow-x-auto'>
            <table className='w-full divide-y divide-card-secondary'>
              <thead>
                <tr className='h-11'>
                  {tableHeader.map((th, i) => (
                    <th className='px-6 text-xs font-semibold text-white' key={i}>
                      {th.label}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className='divide-y divide-card-secondary'>
                {tickets.map((ticket, i) => (
                  <tr className='h-[72px]' key={i}>
                    <td className='px-6 text-sm font-medium text-white'>#{ticket.drawing_id}</td>
                    <td className='px-6 text-sm font-medium text-gray'>{formatDateTime(ticket.timestamp)}</td>
                    <td className='px-6'>
                      <span className='flex gap-3'>
                        {getTicketFromString(ticket.numbers).map((number, j) => (
                          <Number
                            key={`${i}-${j}`}
                            number={number}
                            theme={j === 5 ? 'secondary' : ''}
                            thickShadow={j === 5}
                          />
                        ))}
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className='px-6 pb-4 pt-3'>
            <Pagination
              isFirstPage={currentPage === 1}
              isLastPage={!hasNextPage}
              onPrev={() => getTickets(currentPage - 1)}
              onNext={() => getTickets(currentPage + 1)}
            />
          </div>
        </div>
      </div>
    </section>
  )
}

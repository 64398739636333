import { cx } from 'src/utils/helpers'
import EraseSquareIcon from './icons/EraseSquareIcon'
import RandomCube1Icon from './icons/RandomCube1Icon'
import CloseIcon from './icons/CloseIcon'

interface Props {
  className?: string
  ticket: number[]
  ticketNumber: number
  onChangeTicketNumber?: React.Dispatch<{ index: number; value: string }>
  onClickAutoFill?: () => void
  onClickErase?: () => void
  onClickRemove?: () => void
}

export default function TicketToBuy({
  className,
  ticket,
  ticketNumber,
  onChangeTicketNumber = () => null,
  onClickAutoFill = () => null,
  onClickErase = () => null,
  onClickRemove = () => null,
}: Props) {
  return (
    <div className={cx('relative', className)}>
      <CloseIcon className='absolute -right-4 -top-4 z-[1] h-8 cursor-pointer' onClick={onClickRemove} />
      <div className='relative flex items-center justify-between rounded-2xl bg-card-primary px-[22px] py-4'>
        <div>
          <p className='text-lg font-medium text-white'>Ticket #{ticketNumber.toString().padStart(3, '0')}</p>
          <p className='text-sm text-gray'>Choose 6 numbers</p>
        </div>
        <div className='flex gap-1.5'>
          {ticket[0] !== 0 && <EraseSquareIcon className='h-6 cursor-pointer' mode='gray' onClick={onClickErase} />}
          <RandomCube1Icon className='h-6 cursor-pointer' mode='gray' onClick={onClickAutoFill} />
        </div>
        <div className='absolute bottom-0 w-[calc(100%-44px)] border-t-2 border-dashed border-background' />
      </div>
      <div className='flex justify-between rounded-2xl bg-card-primary px-[22px] py-4'>
        {ticket.map((number, i) => (
          <div
            className={cx(
              'flex h-9 w-9 items-center justify-center rounded-full text-sm',
              number === 0 ? 'bg-card-secondary font-medium text-gray' : 'tanuki-gradient font-semibold text-white',
              className
            )}
            key={i}
          >
            <input
              type='text'
              className='w-9 text-center'
              value={number}
              onChange={(e) => onChangeTicketNumber({ index: i, value: e.target.value })}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

export default function Learning4Icon({ className }: IconProps) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 106 99' fill='none' className={className}>
      <path
        d='M94.2244 35.1683C94.2244 48.5879 83.3393 59.4729 69.9198 59.4729C56.5003 59.4729 45.6152 48.5879 45.6152 35.1683C45.6152 33.6378 45.7622 32.1808 46.0438 30.7237C48.113 19.4346 58.043 10.8638 69.9198 10.8638C81.7966 10.8638 91.7266 19.4346 93.7958 30.7115C94.0774 32.1685 94.2244 33.6256 94.2244 35.1561V35.1683Z'
        fill='#222A37'
      />
      <path
        d='M93.7952 30.7115C93.3667 32.9521 92.6565 35.1194 91.628 37.1151C89.069 42.2699 84.7224 46.3717 79.3472 48.6124C76.4698 49.8613 73.2619 50.5347 69.9192 50.5347C66.5766 50.5347 63.3686 49.8613 60.4913 48.6124C55.1161 46.3717 50.7817 42.2822 48.2104 37.1151C47.1819 35.1194 46.4718 32.9521 46.0432 30.7115C46.5452 27.9688 47.5003 25.373 48.8838 23.0222C53.0591 15.7737 60.9198 10.8638 69.9192 10.8638C78.9187 10.8638 86.7794 15.7737 90.9424 23.0344C92.326 25.3853 93.2932 27.981 93.783 30.7237L93.7952 30.7115Z'
        fill='#485262'
      />
      <path
        d='M90.9548 23.0344C79.3596 29.7197 60.5037 29.7197 48.8962 23.0344C53.0592 15.7737 60.92 10.8638 69.9194 10.8638C78.9188 10.8638 86.7795 15.7737 90.9425 23.0344H90.9548Z'
        fill='#00CDAC'
      />
      <path
        d='M76.3847 23.4384C72.8094 25.4954 67.018 25.4954 63.4427 23.4384C59.8674 21.3814 59.8674 18.0265 63.4427 15.9695C67.018 13.9125 72.8094 13.9125 76.3847 15.9695C79.96 18.0265 79.96 21.3814 76.3847 23.4384Z'
        fill='#161D27'
      />
      <path
        d='M60.4916 48.6129C59.8916 51.6372 57.2836 52.6657 54.1981 50.8903C50.6106 48.8211 47.7209 43.8132 47.7209 39.6869C47.7209 38.6952 47.9046 37.8014 48.223 37.1279C50.782 42.2827 55.1286 46.3845 60.5038 48.6252L60.4916 48.6129Z'
        fill='#151A26'
      />
      <path
        d='M60.639 47.1557C60.639 47.6944 60.6022 48.1842 60.492 48.6127C55.1169 46.3721 50.7824 42.2825 48.2112 37.1155C49.1662 34.9483 51.4804 34.3728 54.1863 35.9401C57.7493 38.0093 60.6267 43.0172 60.6267 47.1434L60.639 47.1557Z'
        fill='#161D27'
      />
      <path
        d='M92.118 39.687C92.118 43.8133 89.2407 48.8333 85.6409 50.8903C82.5431 52.6657 79.9474 51.6372 79.3474 48.6129C84.7226 46.3723 89.057 42.2827 91.6283 37.1157C91.9466 37.7891 92.1303 38.683 92.1303 39.6747L92.118 39.687Z'
        fill='#161D27'
      />
      <path
        d='M91.6282 37.1282C89.0692 42.2829 84.7225 46.3847 79.3474 48.6254C79.2372 48.1968 79.2004 47.6948 79.2004 47.1683C79.2004 43.0421 82.0778 38.022 85.6408 35.965C88.3468 34.3977 90.6609 34.9732 91.616 37.1404L91.6282 37.1282Z'
        fill='#161D27'
      />
      <path
        d='M68.4134 19.0916C68.7073 19.0426 69.0012 19.0549 69.3073 19.1161C69.2338 18.9202 69.2583 18.761 69.344 18.6386C69.4419 18.5161 69.5521 18.4059 69.6868 18.3325C69.9929 18.161 70.3603 18.0753 70.801 18.0876C71.2418 18.0876 71.6704 18.2223 72.0867 18.4671C72.503 18.712 72.7234 18.9569 72.7356 19.214C72.7479 19.4712 72.6009 19.6793 72.3071 19.863C72.1724 19.9364 72.001 20.0099 71.7806 20.0589C71.5602 20.1201 71.303 20.1201 70.9847 20.0589C71.0704 20.2425 71.0827 20.4262 70.9847 20.5854C70.8867 20.7445 70.7398 20.9037 70.5072 21.0384C70.1521 21.2466 69.7236 21.3323 69.2215 21.3323C68.7195 21.3323 68.2298 21.1853 67.7645 20.916C67.2992 20.6466 67.0666 20.3772 67.0788 20.0956C67.0788 19.814 67.2625 19.5691 67.6176 19.3732C67.8502 19.2385 68.1196 19.1406 68.4134 19.1038V19.0916ZM68.2053 20.1936C68.2053 20.316 68.291 20.4384 68.4747 20.5486C68.6583 20.6588 68.8665 20.7078 69.0869 20.7078C69.3073 20.7078 69.5154 20.6466 69.7236 20.5242C69.9317 20.4017 70.0419 20.2793 70.0419 20.1568C70.0419 20.0344 69.9562 19.9119 69.7725 19.814C69.5889 19.7038 69.393 19.6548 69.1726 19.6548C68.9522 19.6548 68.744 19.716 68.5236 19.8385C68.3155 19.9609 68.2175 20.0834 68.2053 20.2058V20.1936ZM70.1521 19.1283C70.1521 19.2385 70.2256 19.3365 70.397 19.4344C70.5684 19.5324 70.7398 19.5814 70.9235 19.5691C71.1071 19.5691 71.2786 19.5201 71.4255 19.4344C71.5847 19.3365 71.6704 19.2385 71.6704 19.1283C71.6704 19.0181 71.5847 18.9202 71.4255 18.8222C71.2663 18.7243 71.0827 18.6753 70.899 18.6753C70.7153 18.6753 70.5317 18.7243 70.3725 18.8222C70.2256 18.9079 70.1399 19.0059 70.1399 19.1161L70.1521 19.1283Z'
        fill='url(#paint0_linear_57_15459)'
      />
      <path
        d='M51.6879 43.2249C51.7981 43.0657 51.945 42.9922 52.1532 43.0045C51.945 42.7351 51.8226 42.4902 51.7614 42.2698C51.7002 42.0494 51.6757 41.8658 51.6757 41.7066C51.6757 41.3515 51.7859 41.1189 52.0185 41.0087C52.2511 40.8985 52.5695 40.9597 52.9858 41.1924C53.4021 41.425 53.7204 41.7433 53.9531 42.1229C54.1857 42.5025 54.2959 42.8698 54.2959 43.2126C54.2959 43.3718 54.2714 43.5187 54.2102 43.6657C54.149 43.8126 54.0143 43.8861 53.8184 43.9106C54.0265 44.1677 54.1735 44.4248 54.2837 44.7064C54.3816 44.988 54.4428 45.2574 54.4428 45.5268C54.4428 45.9308 54.3081 46.2002 54.051 46.3349C53.7939 46.4696 53.4266 46.3961 52.9613 46.1267C52.496 45.8574 52.1409 45.5145 51.9083 45.086C51.6634 44.6574 51.5532 44.2534 51.5532 43.8493C51.5532 43.58 51.6022 43.3596 51.7124 43.2126L51.6879 43.2249ZM52.545 44.9391C52.6552 45.135 52.8021 45.2819 52.9858 45.3921C53.1694 45.5023 53.3164 45.5145 53.4266 45.4533C53.5368 45.3798 53.5857 45.2329 53.5857 45.0003C53.5857 44.7676 53.5368 44.535 53.4266 44.3513C53.3164 44.1677 53.1694 44.0085 52.9858 43.9106C52.8021 43.8004 52.6552 43.7881 52.545 43.8493C52.4348 43.9106 52.3858 44.0697 52.3858 44.3146C52.3858 44.5595 52.4348 44.7676 52.545 44.9513V44.9391ZM52.594 42.7841C52.6919 42.9433 52.8144 43.078 52.9858 43.1759C53.1572 43.2739 53.2796 43.2861 53.3776 43.2371C53.4633 43.1759 53.5123 43.0657 53.5123 42.882C53.5123 42.6861 53.4633 42.5147 53.3776 42.3555C53.2919 42.1964 53.1572 42.0617 52.9858 41.976C52.8266 41.878 52.6919 41.8535 52.594 41.9148C52.496 41.9637 52.4593 42.0862 52.4593 42.2821C52.4593 42.4535 52.5083 42.6249 52.594 42.7963V42.7841Z'
        fill='#141B25'
      />
      <path
        d='M85.579 44.694C85.6892 44.4123 85.8361 44.1552 86.0443 43.9348C85.8361 43.8981 85.7137 43.8001 85.6525 43.6655C85.5913 43.5185 85.5668 43.3716 85.5668 43.2124C85.5668 42.8573 85.677 42.5023 85.9096 42.1227C86.1423 41.7431 86.4606 41.437 86.8769 41.1921C87.2932 40.9473 87.6115 40.886 87.8442 41.0085C88.0768 41.1187 88.187 41.3513 88.187 41.7064C88.187 41.8656 88.1625 42.0492 88.1013 42.2696C88.0401 42.49 87.9054 42.7104 87.7095 42.9675C87.9176 42.9798 88.0646 43.0655 88.1748 43.2247C88.2727 43.3838 88.3339 43.592 88.3339 43.8736C88.3339 44.2777 88.1993 44.694 87.9421 45.1348C87.685 45.5633 87.3177 45.9184 86.8524 46.1877C86.3871 46.4571 86.0321 46.5183 85.7994 46.3714C85.5545 46.2245 85.4443 45.9551 85.4443 45.5511C85.4443 45.2817 85.4933 45.0123 85.6035 44.7307L85.579 44.694ZM86.4361 45.4286C86.5463 45.5021 86.6932 45.4776 86.8769 45.3674C87.0606 45.2572 87.2075 45.1103 87.3177 44.9144C87.4279 44.7185 87.4769 44.5103 87.4769 44.2777C87.4769 44.045 87.4279 43.8736 87.3177 43.8124C87.2075 43.7512 87.0606 43.7634 86.8769 43.8736C86.6932 43.9838 86.5463 44.1307 86.4361 44.3144C86.3259 44.4981 86.2769 44.7185 86.2769 44.9633C86.2769 45.2082 86.3259 45.3551 86.4361 45.4164V45.4286ZM86.4851 43.2124C86.583 43.2736 86.7055 43.2492 86.8769 43.1512C87.0483 43.0533 87.1708 42.9308 87.2687 42.7594C87.3544 42.6002 87.4034 42.4288 87.4034 42.2574C87.4034 42.0615 87.3544 41.939 87.2687 41.8901C87.183 41.8411 87.0483 41.8533 86.8769 41.9513C86.7177 42.0492 86.583 42.1717 86.4851 42.3309C86.3871 42.49 86.3504 42.6737 86.3504 42.8573C86.3504 43.0288 86.3994 43.1512 86.4851 43.2002V43.2124Z'
        fill='url(#paint1_linear_57_15459)'
      />
      <path
        d='M57.6269 51.5999C57.6269 65.0195 46.7419 75.9045 33.3223 75.9045C19.9028 75.9045 9.03003 65.0195 9.03003 51.5999C9.03003 50.0694 9.17696 48.6124 9.45857 47.1553C11.5278 35.8785 21.4456 27.3076 33.3346 27.3076C45.2236 27.3076 55.1413 35.8785 57.2106 47.1553C57.4922 48.6124 57.6392 50.0694 57.6392 51.5999H57.6269Z'
        fill='#222A37'
      />
      <path
        d='M57.2103 47.1554C56.7817 49.396 56.0716 51.5632 55.0431 53.559C52.4841 58.7138 48.1374 62.8156 42.7622 65.0562C39.8849 66.3051 36.6769 66.9786 33.3343 66.9786C29.9916 66.9786 26.7837 66.3051 23.9063 65.0562C18.5311 62.8156 14.1967 58.726 11.6255 53.559C10.597 51.5632 9.8868 49.396 9.45825 47.1554C9.96026 44.4127 10.9153 41.8169 12.2989 39.4661C16.4619 32.2053 24.3226 27.2954 33.322 27.2954C42.3214 27.2954 50.1822 32.2053 54.3452 39.4661C55.7288 41.8169 56.696 44.4127 57.1858 47.1554H57.2103Z'
        fill='#485262'
      />
      <path
        d='M54.3578 39.4661C42.7627 46.1513 23.9067 46.1513 12.2993 39.4661C16.4623 32.2053 24.323 27.2954 33.3225 27.2954C42.3219 27.2954 50.1826 32.2053 54.3456 39.4661H54.3578Z'
        fill='url(#paint2_linear_57_15459)'
      />
      <path
        d='M39.7998 39.8823C36.2245 41.9393 30.433 41.9393 26.8577 39.8823C23.2824 37.8253 23.2824 34.4704 26.8577 32.4134C30.433 30.3564 36.2245 30.3564 39.7998 32.4134C43.375 34.4704 43.375 37.8253 39.7998 39.8823Z'
        fill='#161D27'
      />
      <path
        d='M23.9063 65.0441C23.3064 68.0684 20.6984 69.0969 17.6129 67.3215C14.0131 65.2522 11.1357 60.2444 11.1357 56.1181C11.1357 55.1263 11.3194 54.2325 11.6378 53.5591C14.1968 58.7139 18.5434 62.8156 23.9186 65.0563L23.9063 65.0441Z'
        fill='#161D27'
      />
      <path
        d='M24.0406 63.5873C24.0406 64.1261 24.0038 64.6158 23.8936 65.0444C18.5185 62.8037 14.1841 58.7142 11.6128 53.5471C12.5678 51.3799 14.882 50.8045 17.5879 52.3717C21.151 54.441 24.0283 59.4488 24.0283 63.5751L24.0406 63.5873Z'
        fill='#161D27'
      />
      <path
        d='M55.5328 56.1181C55.5328 60.2444 52.6554 65.2645 49.0557 67.3215C45.9579 69.0969 43.3622 68.0684 42.7622 65.0441C48.1374 62.8034 52.4718 58.7139 55.0431 53.5469C55.3614 54.2203 55.5451 55.1141 55.5451 56.1059L55.5328 56.1181Z'
        fill='#161D27'
      />
      <path
        d='M55.0308 53.5588C52.4718 58.7136 48.1251 62.8154 42.75 65.056C42.6398 64.6275 42.603 64.1255 42.603 63.599C42.603 59.4727 45.4804 54.4526 49.0434 52.3956C51.7494 50.8284 54.0635 51.4039 55.0186 53.5711L55.0308 53.5588Z'
        fill='#161D27'
      />
      <path
        d='M31.0577 33.7724L31.621 33.4541C31.8903 33.601 32.0863 33.6867 32.2087 33.7357C32.4046 33.8092 32.6128 33.8459 32.8331 33.8459C32.9801 33.8459 33.127 33.8214 33.2862 33.7602C33.3841 33.7235 33.4454 33.699 33.4821 33.6867L34.1678 34.0785L30.0048 36.4784L29.1599 35.9886L32.0373 34.3357L31.0577 33.7724Z'
        fill='url(#paint3_linear_57_15459)'
      />
      <path
        d='M32.233 36.8212C32.6248 36.7232 33.2003 36.7232 33.9472 36.7844C34.5961 36.8457 35.0369 36.8701 35.2573 36.8701C35.6124 36.8701 35.894 36.7967 36.1144 36.6742C36.2858 36.5763 36.3837 36.4538 36.4082 36.3314C36.4327 36.209 36.347 36.0988 36.1633 35.9886C35.9185 35.8416 35.6491 35.8049 35.3797 35.8539C35.2205 35.8906 35.0246 35.9641 34.7798 36.0988L33.9839 35.6335C34.3512 35.4376 34.7063 35.3029 35.0369 35.2539C35.6736 35.1437 36.2613 35.2539 36.8245 35.5723C37.2653 35.8294 37.498 36.0988 37.5102 36.3926C37.5224 36.6865 37.3388 36.9436 36.9347 37.1762C36.6286 37.3599 36.2613 37.4579 35.8328 37.4946C35.5511 37.5191 35.1716 37.4946 34.7063 37.4579L34.1431 37.3966C33.788 37.3599 33.5431 37.3477 33.4084 37.3354C33.2737 37.3354 33.1268 37.3354 32.9921 37.3599L34.8165 38.4129L34.0941 38.8292L31.2412 37.1762C31.5473 37.0048 31.8901 36.8824 32.2575 36.8089L32.233 36.8212Z'
        fill='url(#paint4_linear_57_15459)'
      />
      <path
        d='M13.7568 57.2081V56.5592C14.014 56.6939 14.1976 56.7796 14.2956 56.8163C14.4548 56.8653 14.5894 56.8653 14.6996 56.7918C14.7731 56.7428 14.8221 56.6571 14.8588 56.5347C14.8833 56.4612 14.8955 56.4 14.8955 56.3633L15.5812 56.7551V61.567L14.7364 61.0773V57.7591L13.7568 57.1959V57.2081Z'
        fill='#C42272'
      />
      <path
        d='M16.9899 61.4448C17.1123 61.1876 17.3817 61.0162 17.8225 60.906C18.202 60.8081 18.4469 60.7346 18.5571 60.6611C18.7285 60.5509 18.8142 60.3673 18.8142 60.1224C18.8142 59.9142 18.7652 59.7183 18.6673 59.5347C18.5693 59.3388 18.4347 59.1918 18.251 59.0939C18.0061 58.947 17.8347 58.9592 17.7367 59.1184C17.6878 59.2163 17.651 59.3877 17.6388 59.6571L16.8429 59.1918C16.8552 58.7878 16.9164 58.4939 17.0388 58.3103C17.2592 57.9552 17.651 57.9429 18.202 58.2613C18.6428 58.5184 18.9979 58.8612 19.255 59.2898C19.5121 59.7183 19.6468 60.1714 19.6468 60.6366C19.6468 60.9917 19.5489 61.2611 19.3652 61.4203C19.2428 61.5305 19.0469 61.6284 18.7652 61.6896L18.4347 61.7631C18.2265 61.8121 18.0918 61.8488 18.0184 61.8855C17.9449 61.9223 17.8837 61.9713 17.8225 62.0325L19.6468 63.0855V63.9181L16.7939 62.2651C16.7939 61.9223 16.8674 61.6407 16.9899 61.4325V61.4448Z'
        fill='#C42272'
      />
      <path
        d='M46.7908 59.9623V59.3134C47.0479 59.1542 47.2316 59.0318 47.3295 58.946C47.4887 58.8114 47.6234 58.6522 47.7336 58.4563C47.807 58.3338 47.856 58.1869 47.8927 58.0155C47.9172 57.9175 47.9295 57.8441 47.9295 57.8073L48.6151 57.4155V62.2275L47.7703 62.7172V59.3991L46.7908 59.9623Z'
        fill='#141B25'
      />
      <path
        d='M50.024 60.4647C50.1465 60.0729 50.4158 59.5832 50.8566 58.971C51.2362 58.4445 51.4811 58.0771 51.5913 57.8812C51.7627 57.5751 51.8484 57.2935 51.8484 57.0486C51.8484 56.8405 51.7994 56.7058 51.7015 56.6201C51.6035 56.5466 51.4688 56.5589 51.2852 56.6568C51.0403 56.8037 50.8689 56.9996 50.7709 57.269C50.7219 57.4282 50.6852 57.6363 50.673 57.918L49.8771 58.3832C49.8893 57.9669 49.9506 57.5996 50.073 57.2813C50.2934 56.6691 50.6852 56.216 51.2362 55.8854C51.677 55.6283 52.0321 55.5671 52.2892 55.7018C52.5463 55.8365 52.681 56.1303 52.681 56.5956C52.681 56.9507 52.583 57.318 52.3994 57.6976C52.2769 57.9547 52.081 58.273 51.7994 58.6526L51.4688 59.1056C51.2607 59.3873 51.126 59.5954 51.0525 59.7178C50.9791 59.8403 50.9179 59.9627 50.8566 60.0852L52.681 59.0322V59.8648L49.8281 61.5177C49.8281 61.1626 49.9016 60.8198 50.024 60.4647Z'
        fill='#141B25'
      />
      <path
        d='M96 64.432C96 77.8515 85.115 88.7365 71.6954 88.7365C58.2759 88.7365 47.3909 77.8515 47.3909 64.432C47.3909 62.9015 47.5378 61.4444 47.8194 59.9874C49.8887 48.7105 59.8064 40.1396 71.6954 40.1396C83.5845 40.1396 93.5022 48.7105 95.5714 59.9874C95.8531 61.4444 96 62.9015 96 64.432Z'
        fill='#222A37'
      />
      <path
        d='M95.5711 59.9874C95.1426 62.2281 94.4324 64.3953 93.4039 66.3911C90.8449 71.5458 86.4982 75.6476 81.1231 77.8883C78.2457 79.1372 75.0378 79.8106 71.6951 79.8106C68.3525 79.8106 65.1445 79.1372 62.2671 77.8883C56.892 75.6476 52.5576 71.5581 49.9863 66.3911C48.9578 64.3953 48.2476 62.2281 47.8191 59.9874C48.3211 57.2447 49.2761 54.649 50.6597 52.2981C54.8227 45.0373 62.6834 40.1274 71.6829 40.1274C80.6823 40.1274 88.543 45.0373 92.706 52.2981C94.0896 54.649 95.0569 57.2447 95.5466 59.9874H95.5711Z'
        fill='#485262'
      />
      <path
        d='M92.7304 52.2981C81.1352 58.9834 62.2793 58.9834 50.6719 52.2981C54.8349 45.0373 62.6956 40.1274 71.695 40.1274C80.6944 40.1274 88.5552 45.0373 92.7182 52.2981H92.7304Z'
        fill='url(#paint5_linear_57_15459)'
      />
      <path
        d='M78.1721 52.7148C74.5968 54.7718 68.8053 54.7718 65.23 52.7148C61.6548 50.6578 61.6548 47.3029 65.23 45.2459C68.8053 43.1889 74.5968 43.1889 78.1721 45.2459C81.7474 47.3029 81.7474 50.6578 78.1721 52.7148Z'
        fill='#161D27'
      />
      <path
        d='M62.2672 77.8756C61.6672 80.8999 59.0592 81.9284 55.9737 80.153C52.3739 78.0838 49.4966 73.0759 49.4966 68.9496C49.4966 67.9579 49.6802 67.0641 49.9986 66.3906C52.5576 71.5454 56.9043 75.6472 62.2794 77.8878L62.2672 77.8756Z'
        fill='#161D27'
      />
      <path
        d='M62.4146 76.4189C62.4146 76.9576 62.3779 77.4474 62.2677 77.8759C56.8925 75.6352 52.5581 71.5457 49.9868 66.3787C50.9419 64.2115 53.256 63.636 55.9619 65.2033C59.525 67.2725 62.4023 72.2803 62.4023 76.4066L62.4146 76.4189Z'
        fill='#161D27'
      />
      <path
        d='M93.9061 68.9497C93.9061 73.0759 91.0287 78.096 87.429 80.153C84.3312 81.9284 81.7355 80.8999 81.1355 77.8756C86.5107 75.635 90.8451 71.5454 93.4163 66.3784C93.7347 67.0518 93.9184 67.9457 93.9184 68.9374L93.9061 68.9497Z'
        fill='#161D27'
      />
      <path
        d='M93.4041 66.3909C90.8451 71.5456 86.4984 75.6474 81.1233 77.8881C81.0131 77.4595 80.9763 76.9575 80.9763 76.431C80.9763 72.3048 83.8537 67.2847 87.4167 65.2277C90.1227 63.6604 92.4368 64.2359 93.3919 66.4031L93.4041 66.3909Z'
        fill='#161D27'
      />
      <path
        d='M67.7891 47.952C68.1809 47.8541 68.7564 47.8541 69.5033 47.9153C70.1522 47.9765 70.593 48.001 70.8134 48.001C71.1685 48.001 71.4501 47.9275 71.6705 47.8051C71.8419 47.7071 71.9399 47.5847 71.9644 47.4623C71.9889 47.3398 71.9032 47.2296 71.7195 47.1194C71.4746 46.9725 71.2052 46.9358 70.9359 46.9847C70.7767 47.0215 70.5808 47.0949 70.3359 47.2296L69.54 46.7643C69.9074 46.5684 70.2624 46.4338 70.593 46.3848C71.2297 46.2746 71.8175 46.3848 72.3807 46.7031C72.8215 46.9603 73.0541 47.2296 73.0663 47.5235C73.0786 47.8173 72.8949 48.0745 72.4909 48.3071C72.1848 48.4908 71.8174 48.5887 71.3889 48.6255C71.1073 48.6499 70.7277 48.6255 70.2624 48.5887L69.6992 48.5275C69.3441 48.4908 69.0993 48.4785 68.9646 48.4663C68.8299 48.4663 68.683 48.4663 68.5483 48.4908L70.3726 49.5438L69.6502 49.9601L66.7974 48.3071C67.1035 48.1357 67.4463 48.0132 67.8136 47.9398L67.7891 47.952Z'
        fill='url(#paint6_linear_57_15459)'
      />
      <path
        d='M73.8496 51.3553L73.3844 51.0859L72.4905 51.6002L71.6947 51.1349L72.5885 50.6206L70.9355 49.6656L71.6457 49.2493L75.7107 48.6738L76.6291 49.2003L74.0211 50.7064L74.4863 50.9757L73.8496 51.343V51.3553ZM73.2129 50.2533L75.0128 49.2126L72.1844 49.6534L73.2252 50.2533H73.2129Z'
        fill='url(#paint7_linear_57_15459)'
      />
      <path
        d='M51.774 72.8808C51.8965 72.6237 52.1658 72.4522 52.6066 72.342C52.9862 72.2441 53.2311 72.1706 53.3413 72.0972C53.5127 71.987 53.5984 71.8033 53.5984 71.5584C53.5984 71.3503 53.5494 71.1544 53.4515 70.9707C53.3535 70.7748 53.2188 70.6279 53.0352 70.5299C52.7903 70.383 52.6189 70.3952 52.5209 70.5544C52.4719 70.6524 52.4352 70.8238 52.423 71.0931L51.6271 70.6279C51.6393 70.2238 51.7006 69.93 51.823 69.7463C52.0434 69.3912 52.4352 69.379 52.9862 69.6973C53.427 69.9544 53.7821 70.2973 54.0392 70.7258C54.2963 71.1544 54.431 71.6074 54.431 72.0727C54.431 72.4278 54.333 72.6971 54.1494 72.8563C54.0269 72.9665 53.831 73.0644 53.5494 73.1257L53.2188 73.1991C53.0107 73.2481 52.876 73.2848 52.8025 73.3216C52.7291 73.3583 52.6679 73.4073 52.6066 73.4685L54.431 74.5215V75.3541L51.5781 73.7011C51.5781 73.3583 51.6516 73.0767 51.774 72.8685V72.8808Z'
        fill='#C42272'
      />
      <path
        d='M57.7493 76.2354L57.284 75.9661V77.0068L56.4882 76.5415V75.5008L54.8352 74.5457V73.7254L56.3657 71.6929L57.284 72.2194V75.2192L57.7493 75.4885V76.2232V76.2354ZM56.4759 74.7661V72.6969L55.4352 74.1662L56.4759 74.7661Z'
        fill='#C42272'
      />
      <path
        d='M85.8495 76.2113C85.9719 75.8195 86.2413 75.3297 86.6821 74.7175C87.0616 74.191 87.3065 73.8237 87.4167 73.6278C87.5881 73.3217 87.6738 73.0401 87.6738 72.7952C87.6738 72.5871 87.6249 72.4524 87.5269 72.3667C87.429 72.2932 87.2943 72.3054 87.1106 72.4034C86.8657 72.5503 86.6943 72.7462 86.5964 73.0156C86.5474 73.1748 86.5107 73.3829 86.4984 73.6645L85.7025 74.1298C85.7148 73.7135 85.776 73.3462 85.8984 73.0278C86.1188 72.4156 86.5106 71.9626 87.0616 71.632C87.5024 71.3749 87.8575 71.3137 88.1146 71.4484C88.3718 71.583 88.5064 71.8769 88.5064 72.3422C88.5064 72.6973 88.4085 73.0646 88.2248 73.4564C88.1024 73.7135 87.9065 74.0319 87.6249 74.4114L87.2943 74.8645C87.0861 75.1461 86.9514 75.3542 86.878 75.4767C86.8045 75.5991 86.7433 75.7215 86.6821 75.844L88.5064 74.791V75.6236L85.6536 77.2766C85.6536 76.9215 85.727 76.5786 85.8495 76.2236V76.2113Z'
        fill='#C42272'
      />
      <path
        d='M91.8247 72.6726L91.3595 72.942V73.9827L90.5636 74.448V73.4072L88.9106 74.3623V73.5419L90.4412 69.734L91.3595 69.2075V72.2073L91.8247 71.938V72.6726ZM90.5514 72.6726V70.6033L89.5106 73.2726L90.5514 72.6726Z'
        fill='#C42272'
      />
      <defs>
        <linearGradient
          id='paint0_linear_57_15459'
          x1='67.0784'
          y1='20.9265'
          x2='69.5134'
          y2='17.0331'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#02AAB0' />
          <stop offset='1' stopColor='#00CDAC' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_57_15459'
          x1='85.4443'
          y1='45.7615'
          x2='89.0342'
          y2='44.032'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#02AAB0' />
          <stop offset='1' stopColor='#00CDAC' />
        </linearGradient>
        <linearGradient
          id='paint2_linear_57_15459'
          x1='12.2993'
          y1='44.48'
          x2='24.4258'
          y2='14.988'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#8B1666' />
          <stop offset='1' stopColor='#E52979' />
        </linearGradient>
        <linearGradient
          id='paint3_linear_57_15459'
          x1='29.1599'
          y1='36.4784'
          x2='31.8529'
          y2='32.0473'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#8B1666' />
          <stop offset='1' stopColor='#E52979' />
        </linearGradient>
        <linearGradient
          id='paint4_linear_57_15459'
          x1='31.2412'
          y1='38.8292'
          x2='34.392'
          y2='33.4018'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#8B1666' />
          <stop offset='1' stopColor='#E52979' />
        </linearGradient>
        <linearGradient
          id='paint5_linear_57_15459'
          x1='50.6719'
          y1='57.3121'
          x2='62.7983'
          y2='27.82'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#8B1666' />
          <stop offset='1' stopColor='#E52979' />
        </linearGradient>
        <linearGradient
          id='paint6_linear_57_15459'
          x1='66.7974'
          y1='49.9601'
          x2='69.9482'
          y2='44.5327'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#8B1666' />
          <stop offset='1' stopColor='#E52979' />
        </linearGradient>
        <linearGradient
          id='paint7_linear_57_15459'
          x1='70.9355'
          y1='51.6002'
          x2='73.3314'
          y2='46.9682'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#8B1666' />
          <stop offset='1' stopColor='#E52979' />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default function TicketIcon({ className }: IconProps) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' className={className}>
      <path
        d='M2.3999 7.2998C2.3999 5.91909 3.51919 4.7998 4.8999 4.7998H19.8999C21.2806 4.7998 22.3999 5.91909 22.3999 7.2998V9.7998C21.0192 9.7998 19.8999 10.9191 19.8999 12.2998C19.8999 13.6805 21.0192 14.7998 22.3999 14.7998V17.2998C22.3999 18.6805 21.2806 19.7998 19.8999 19.7998H4.8999C3.51919 19.7998 2.3999 18.6805 2.3999 17.2998V14.7998C3.78061 14.7998 4.8999 13.6805 4.8999 12.2998C4.8999 10.9191 3.78061 9.7998 2.3999 9.7998V7.2998Z'
        fill='url(#paint0_linear_57_15554)'
      />
      <defs>
        <linearGradient
          id='paint0_linear_57_15554'
          x1='2.3999'
          y1='19.7998'
          x2='16.8709'
          y2='0.626735'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#8B1666' />
          <stop offset='1' stopColor='#E52979' />
        </linearGradient>
      </defs>
    </svg>
  )
}

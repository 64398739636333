import { useId, useState } from 'react'
import { toast } from 'react-hot-toast'
import { cx } from 'src/utils/helpers'
import CloseIcon from '../icons/CloseIcon'
import Money from '../Money'
import InfoIcon from '../icons/InfoIcon'
import WadOfMoneyIcon from '../icons/WadOfMoneyIcon'
import useAppDispatch from 'src/hooks/useAppDispatch'
import { setDepositModalOpen } from 'src/redux/slices/modal'
import useAppSelector from 'src/hooks/useAppSelector'
import useClickOutside from 'src/hooks/useClickOutside'
import { useLotteryContract } from 'src/hooks/useLotteryContract'
import LotteryContract from 'src/contracts/Lottery.sol/Lottery.json'
import { LOTTERY_CONTRACT_ADDRESS } from 'src/config'
import { formatEther, parseEther, zeroAddress } from 'viem'

export default function DepositModal() {
  const id = useId()
  const [depositAmount, setDepositAmount] = useState<string>()
  const [loading, setLoading] = useState<boolean>(false)
  const { deposit } = useLotteryContract()
  const dispatch = useAppDispatch()
  const open = useAppSelector((state) => state.modal.depositModalOpen)
  const balance = useAppSelector((state) => state.user.balance)

  const handleCloseModal = () => {
    dispatch(setDepositModalOpen(false))
  }

  const handleClickAmount = (amount: number) => {
    setDepositAmount(amount.toString())
  }

  const handleDeposit = async () => {
    if (!depositAmount || isNaN(Number(depositAmount))) {
      toast.error('Invalid deposit amount')
      return
    }

    try {
      setLoading(true)
      await deposit(LotteryContract.abi, LOTTERY_CONTRACT_ADDRESS, parseEther((+depositAmount).toString()), zeroAddress)
    } catch (err) {
      console.log(err)
    } finally {
      setLoading(false)
    }
  }

  const ref = useClickOutside(handleCloseModal)

  return (
    <div
      className={cx(
        'fixed inset-0 z-[60] overflow-y-auto bg-background/60 backdrop-blur-[10px] transition duration-300 ease-in-out',
        open ? 'pointer-events-auto opacity-100' : 'pointer-events-none opacity-0'
      )}
    >
      <div className='container flex min-h-screen items-center justify-center py-12'>
        <div className='w-full max-w-[400px] rounded-2xl border border-card-secondary bg-card-primary pb-6' ref={ref}>
          <div className='flex items-center justify-between p-4'>
            <p className='text-xl font-semibold text-white'>Deposit</p>
            <CloseIcon className='h-7 cursor-pointer' onClick={handleCloseModal} />
          </div>
          <div className='flex h-10 items-center justify-center gap-6 bg-card-secondary'>
            <p className='text-sm font-medium text-white'>Your balance</p>
            <Money amount={balance[0]?.amount_wei ? formatEther(balance[0].amount_wei) : null} size='small' />
          </div>
          <div className='space-y-6 p-4'>
            <p className='text-center text-sm font-medium text-white'>Select Deposit Amount</p>
            <button
              className='btn-primary h-[54px] w-full text-sm font-bold text-white'
              disabled={loading}
              onClick={handleDeposit}
            >
              Deposit Now
            </button>
            <div className='flex flex-wrap justify-center gap-3'>
              {[0.01, 0.025, 0.1, 0.25, 0.5, 1, 1.5, 2, 4, 5].map((amount, i) => (
                <button
                  className={cx(
                    'shadow-1 flex h-10 w-[110px] items-center justify-center rounded-full',
                    amount.toString() === depositAmount ? 'tanuki-gradient' : 'border-[1.5px] border-card-secondary'
                  )}
                  key={i}
                  onClick={() => handleClickAmount(amount)}
                >
                  <Money amount={amount} hideUnit size='small' whiteIcon={amount.toString() === depositAmount} />
                </button>
              ))}
            </div>
            <div className='flex flex-col items-center space-y-3'>
              <label
                htmlFor={id}
                className='shadow-1 flex h-10 w-full max-w-[288px] items-center gap-2 rounded-full bg-card-secondary px-3.5'
              >
                <WadOfMoneyIcon className='h-6' />
                <input
                  className='text-sm font-medium text-white'
                  id={id}
                  value={depositAmount}
                  onChange={(e) => setDepositAmount(e.target.value)}
                />
              </label>
              <div className='flex items-center gap-1.5'>
                <InfoIcon className='h-5 fill-gray' />
                <p className='text-sm text-gray'>Deposit limits: minimum 20 maximum 500,000</p>
              </div>
            </div>
            <p className='text-center text-xs text-gray'>
              By clicking the button “Deposit Now”,
              <br />I confirm that I have read&nbsp;
              <span className='cursor-pointer font-semibold text-white'>Privacy Policy</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

import ReactDOM from 'react-dom/client'
import { Toaster } from 'react-hot-toast'
import { Provider } from 'react-redux'
import { WagmiConfig } from 'wagmi'
import 'rc-pagination/assets/index.css'

import App from './App'
import reportWebVitals from './reportWebVitals'
import { store } from './redux/store'
import { config } from './services/wagmi'
import 'src/styles/globals.css'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <Provider store={store}>
    <WagmiConfig config={config}>
      <App />
    </WagmiConfig>
    <Toaster
      position='top-right'
      toastOptions={{
        style: {
          background: '#363636',
          color: '#fff',
        },
      }}
    />
  </Provider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

import PlusIcon from '../icons/PlusIcon'

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {}

export default function PlusButton({ ...props }: Props) {
  return (
    <button className='flex h-9 w-9 items-center justify-center rounded-full bg-card-secondary' {...props}>
      <PlusIcon className='h-6' />
    </button>
  )
}

export default function CupIcon({ className }: IconProps) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' className={className}>
      <path
        d='M21.9999 8.16234L21.9999 8.23487C21.9999 9.09561 21.9999 9.52598 21.7927 9.8781C21.5855 10.2302 21.2093 10.4392 20.4569 10.8572L19.6636 11.298C20.2102 9.44984 20.3926 7.46414 20.4601 5.76597C20.4629 5.69316 20.4662 5.61945 20.4695 5.54497L20.4718 5.49279C21.1231 5.71896 21.4887 5.88758 21.7168 6.20408C22 6.59692 22 7.11873 21.9999 8.16234Z'
        fill='url(#paint0_linear_57_15527)'
      />
      <path
        d='M2 8.16234L2 8.23487C2.00003 9.09561 2.00004 9.52598 2.20723 9.8781C2.41442 10.2302 2.79063 10.4392 3.54305 10.8572L4.33681 11.2982C3.79007 9.45001 3.60767 7.46422 3.54025 5.76597C3.53736 5.69316 3.5341 5.61945 3.53081 5.54497L3.5285 5.49266C2.87701 5.7189 2.51126 5.88752 2.2831 6.20408C1.99996 6.59692 1.99997 7.11873 2 8.16234Z'
        fill='url(#paint1_linear_57_15527)'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16.3771 2.34674C15.2531 2.15709 13.7837 2 12.0002 2C10.2166 2 8.74724 2.15709 7.62318 2.34674C6.48445 2.53887 5.91508 2.63494 5.43937 3.22083C4.96365 3.80673 4.98879 4.43998 5.03907 5.70647C5.21169 10.0544 6.14996 15.4851 11.25 15.9657V19.5H9.8198C9.34312 19.5 8.93271 19.8365 8.83922 20.3039L8.65 21.25H6C5.58579 21.25 5.25 21.5858 5.25 22C5.25 22.4142 5.58579 22.75 6 22.75H18C18.4142 22.75 18.75 22.4142 18.75 22C18.75 21.5858 18.4142 21.25 18 21.25H15.35L15.1608 20.3039C15.0673 19.8365 14.6569 19.5 14.1802 19.5H12.75V15.9657C17.8503 15.4853 18.7886 10.0545 18.9612 5.70647C19.0115 4.43998 19.0367 3.80673 18.5609 3.22083C18.0852 2.63494 17.5159 2.53887 16.3771 2.34674Z'
        fill='url(#paint2_linear_57_15527)'
      />
      <defs>
        <linearGradient
          id='paint0_linear_57_15527'
          x1='2'
          y1='22.75'
          x2='22.7964'
          y2='2.83158'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#8B1666' />
          <stop offset='1' stopColor='#E52979' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_57_15527'
          x1='2'
          y1='22.75'
          x2='22.7964'
          y2='2.83158'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#8B1666' />
          <stop offset='1' stopColor='#E52979' />
        </linearGradient>
        <linearGradient
          id='paint2_linear_57_15527'
          x1='2'
          y1='22.75'
          x2='22.7964'
          y2='2.83158'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#8B1666' />
          <stop offset='1' stopColor='#E52979' />
        </linearGradient>
      </defs>
    </svg>
  )
}

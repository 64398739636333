import axios from 'src/services/axios'

export const apiGetUserDrawings = async (page: number) => {
  const { data } = await axios.post('/get-user-drawings', { page })
  return data
}

export const apiGetCurrentDrawing = async () => {
  const { data } = await axios.get('/get-current-drawing')
  return data
}

export const apiGetTicketsOfDrawing = async (id: number) => {
  const { data } = await axios.post('/get-tickets/', { filter_method: 'drawing', filter_id: id })
  return data
}

import axios from 'src/services/axios'

export const apiGetPublicStats = async () => {
  const { data } = await axios.post('/public/stats')
  return data
}

export const apiGetTicketPrice = async () => {
  const { data } = await axios.post('/public/prices')
  return data
}

export const apiGetRecentDrawings = async (page: number) => {
  const { data } = await axios.post('/public/drawings', { page })
  return data
}

export const apiGetTickets = async (page: number) => {
  const { data } = await axios.post('/public/tickets', { page })
  return data
}

export const apiGetWinnings = async (page: number) => {
  const { data } = await axios.post('/public/winnings', { page })
  return data
}

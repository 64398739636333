import { cx } from 'src/utils/helpers'
import RandomCube1Icon from '../icons/RandomCube1Icon'

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string
}

export default function AutoFillAllTicketsButton({ className, ...props }: Props) {
  return (
    <button
      className={cx('btn-primary-outlined-no-hover shadow-1 flex h-10 items-center gap-2 px-4', className)}
      {...props}
    >
      <RandomCube1Icon className='h-6' />
      <p className='whitespace-nowrap text-sm font-medium text-white'>Auto Fill All</p>
    </button>
  )
}

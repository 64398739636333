import { cx } from 'src/utils/helpers'
import EraseSquareIcon from '../icons/EraseSquareIcon'

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string
}

export default function ClearAllTicketsButton({ className, ...props }: Props) {
  return (
    <button className={cx('shadow-1 flex items-center gap-2', className)} {...props}>
      <EraseSquareIcon className='h-6' />
      <p className='text-sm font-medium text-white'>Clear all</p>
    </button>
  )
}

import { cx } from 'src/utils/helpers'
import CopyIcon from '../icons/CopyIcon'

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string
}

export default function CopyButton({ className, ...props }: Props) {
  return (
    <button className={cx('flex items-center gap-1', className)} {...props}>
      <CopyIcon className='h-6' />
      <p className='text-sm font-medium text-gray'>Copy</p>
    </button>
  )
}

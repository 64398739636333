import { useEffect, useState } from 'react'
import { cx, formatLeftTime } from 'src/utils/helpers'
import ClockIcon from './icons/ClockIcon'

interface Props {
  className?: string
  nextDrawTime: number | null
}

export default function DrawCountdown({ className, nextDrawTime }: Props) {
  const [leftTime, setLeftTime] = useState<number>(0)

  useEffect(() => {
    if (!nextDrawTime) return

    const interval = setInterval(() => {
      const timestamp = Math.floor(Date.now() / 1000)

      setLeftTime(Math.max(0, nextDrawTime - timestamp))
    }, 1000)

    return () => clearInterval(interval)
  }, [nextDrawTime])

  return (
    <div className={cx('space-y-[22px] rounded-2xl bg-card-primary p-[22px]', className)}>
      <div className='flex items-center gap-2'>
        <ClockIcon className='h-6' />
        <p className='text-lg font-medium text-white'>Till the next draw</p>
      </div>
      <div>
        <div className='flex gap-0.5'>
          <p className='shadow-1 flex h-[58px] w-[58px] items-center justify-center rounded-[5px] bg-card-secondary text-[32px] font-semibold text-white'>
            {formatLeftTime(leftTime)[0]}
          </p>
          <p className='shadow-1 flex h-[58px] w-[58px] items-center justify-center rounded-[5px] bg-card-secondary text-[32px] font-semibold text-white'>
            {formatLeftTime(leftTime)[1]}
          </p>
          <p className='shadow-1 flex h-[58px] w-[58px] items-center justify-center rounded-[5px] bg-card-secondary text-[32px] font-semibold text-white'>
            {formatLeftTime(leftTime)[2]}
          </p>
          <p className='shadow-1 flex h-[58px] w-[58px] items-center justify-center rounded-[5px] bg-card-secondary text-[32px] font-semibold text-white'>
            {formatLeftTime(leftTime)[3]}
          </p>
        </div>
        <div className='flex gap-0.5'>
          {['Days', 'Hours', 'Mins', 'Sec'].map((text, i) => (
            <p className='w-[58px] text-center text-sm font-medium leading-loose text-gray' key={i}>
              {text}
            </p>
          ))}
        </div>
      </div>
    </div>
  )
}

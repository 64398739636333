// import ReactPagination from 'rc-pagination'
import { cx } from 'src/utils/helpers'
import ArrowLeftIcon from './icons/ArrowLeftIcon'
import ArrowRightIcon from './icons/ArrowRightIcon'

interface Props {
  className?: string
  isFirstPage?: boolean
  isLastPage?: boolean
  onPrev?: () => void
  onNext?: () => void
}

export default function Pagination({
  className,
  isFirstPage = true,
  isLastPage = true,
  onPrev = () => null,
  onNext = () => null,
}: Props) {
  return (
    <div className='flex justify-between'>
      <button
        className='shadow-1 flex h-9 w-[114px] items-center justify-center gap-2 rounded-full bg-card-secondary text-white disabled:cursor-not-allowed disabled:text-gray'
        disabled={isFirstPage}
        onClick={onPrev}
      >
        <ArrowLeftIcon className={cx('h-5', isFirstPage ? 'stroke-gray' : 'stroke-white')} />
        <p className='text-sm font-medium'>Previous</p>
      </button>
      <button
        className='shadow-1 flex h-9 w-[114px] items-center justify-center gap-2 rounded-full bg-card-secondary text-white disabled:cursor-not-allowed disabled:text-gray'
        disabled={isLastPage}
        onClick={onNext}
      >
        <p className='text-sm font-medium'>Next</p>
        <ArrowRightIcon className={cx('h-5', isLastPage ? 'stroke-gray' : 'stroke-white')} />
      </button>
      {/* <ReactPagination
        prevIcon={
          <div className='shadow-1 flex h-9 w-[114px] items-center justify-center gap-2 rounded-full bg-card-secondary text-white'>
            <ArrowLeftIcon className='h-5' />
            <p className='text-sm font-medium'>Previous</p>
          </div>
        }
        nextIcon={
          <div className='shadow-1 flex h-9 w-[114px] items-center justify-center gap-2 rounded-full bg-card-secondary text-white'>
            <p className='text-sm font-medium'>Next</p>
            <ArrowRightIcon className='h-5' />
          </div>
        }
        pageSize={20}
        total={1000}
      /> */}
    </div>
  )
}

import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { formatEther } from 'viem'
import Money from 'src/components/Money'
import ConnectWalletButton from 'src/components/buttons/ConnectWalletButton'
import LogoIcon from 'src/components/icons/LogoIcon'
import useAppDispatch from 'src/hooks/useAppDispatch'
import useAppSelector from 'src/hooks/useAppSelector'
import { setDepositModalOpen, setWithdrawModalOpen } from 'src/redux/slices/modal'
import { cx } from 'src/utils/helpers'
import MenuIcon from 'src/components/icons/MenuIcon'
import CloseIcon from 'src/components/icons/CloseIcon'

export const menu = [
  { title: 'Inventory', to: '/inventory' },
  { title: 'Draw', to: '/draw' },
  { title: 'Claim', to: '/claim' },
]

interface Props {
  hideHeader?: boolean
}

export default function Header({ hideHeader = false }: Props) {
  const [open, setOpen] = useState<boolean>(false)
  const [scrollPosition, setScrollPosition] = useState<number>(0)
  const dispatch = useAppDispatch()
  const balance = useAppSelector((state) => state.user.balance)
  const isLoggedIn = useAppSelector((state) => state.user.isLoggedIn)

  const handleScroll = () => {
    const position = window.pageYOffset
    setScrollPosition(position)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true })

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const handleClickDeposit = () => {
    console.log('hello')
    dispatch(setDepositModalOpen(true))
  }

  const handleClickWithdraw = () => {
    dispatch(setWithdrawModalOpen(true))
  }

  return (
    <>
      <header
        className={cx(
          'fixed left-0 top-0 z-50 w-full transition-all duration-300 ease-in-out',
          hideHeader ? '' : 'lg:bg-background',
          open ? 'bg-background' : ''
        )}
      >
        <div
          className={cx(
            'container flex h-[102px] items-center justify-between transition-all duration-300 ease-in-out',
            scrollPosition === 0 ? '' : 'bg-background/80'
          )}
        >
          <Link to='/'>
            <LogoIcon className='h-[54px]' />
          </Link>
          {isLoggedIn && (
            <nav className='flex gap-12 xl:gap-6 lg:hidden'>
              {menu.map((link, i) => (
                <Link to={link.to} className='text-sm font-medium tracking-[-0.28px] text-white' key={i}>
                  {link.title}
                </Link>
              ))}
            </nav>
          )}
          <div className='flex gap-3 lg:hidden'>
            {isLoggedIn && (
              <>
                <div className='flex h-[54px] gap-3 rounded-full bg-card-primary p-[7px]'>
                  <Money
                    amount={balance[0]?.amount_wei ? formatEther(balance[0].amount_wei) : null}
                    className='h-10 w-[150px] rounded-full bg-card-secondary px-3.5'
                    hideUnit
                    size='small'
                  />
                  <button className='btn-primary-outlined h-10 px-4 font-medium' onClick={handleClickDeposit}>
                    Deposit
                  </button>
                  <button className='btn-primary-outlined h-10 px-4 font-medium' onClick={handleClickWithdraw}>
                    Withdraw
                  </button>
                </div>
                <Link to='/powerball'>
                  <button className='btn-primary-outlined h-[54px] w-[190px] font-bold xl:w-40'>Get tickets</button>
                </Link>
              </>
            )}
            <ConnectWalletButton />
          </div>
          {!open && <MenuIcon className='hidden h-6 cursor-pointer lg:block' onClick={() => setOpen(true)} />}
          {open && (
            <CloseIcon className='hidden h-6 cursor-pointer lg:block' type='normal' onClick={() => setOpen(false)} />
          )}
        </div>
        {isLoggedIn && (
          <div
            className={cx(
              'container hidden pb-6 transition duration-300 ease-in-out lg:block',
              hideHeader ? 'pointer-events-none opacity-0' : 'pointer-events-auto opacity-100',
              open ? '!pointer-events-auto !opacity-100' : ''
            )}
          >
            <div className='flex h-[54px] justify-center gap-3 rounded-full bg-card-primary p-[7px]'>
              <Money
                amount={balance[0]?.amount_wei ? formatEther(balance[0].amount_wei) : null}
                className='h-10 w-48 rounded-full bg-card-secondary px-3.5 xs:w-full'
                hideUnit
                size='small'
              />
              <button className='btn-primary-outlined h-10 w-32 px-4 font-medium xs:w-fit' onClick={handleClickDeposit}>
                Deposit
              </button>
              <button
                className='btn-primary-outlined h-10 w-32 px-4 font-medium xs:w-fit'
                onClick={handleClickWithdraw}
              >
                Withdraw
              </button>
            </div>
          </div>
        )}
        <div
          className={cx(
            'container absolute bottom-0 grid translate-y-full gap-6 bg-background py-6 shadow-[0px_4px_6px_-2px_#10182808,0px_12px_16px_-4px_#10182814] transition duration-300 ease-in-out',
            open ? 'pointer-events-auto opacity-100' : 'pointer-events-none opacity-0'
          )}
        >
          {menu.map((link, i) => (
            <Link
              to={link.to}
              className='flex h-[54px] w-full items-center justify-center rounded-full bg-card-primary text-white'
              key={i}
              onClick={() => setOpen(false)}
            >
              {link.title}
            </Link>
          ))}
          <Link to='/powerball' onClick={() => setOpen(false)}>
            <button className='btn-primary-outlined h-[54px] w-full font-bold'>Get tickets</button>
          </Link>
          <ConnectWalletButton className='mx-auto' />
        </div>
      </header>
    </>
  )
}

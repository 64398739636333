import { useCallback } from 'react'
import { publicClient, walletClient } from 'src/services/wagmi'
import { useAccount } from 'wagmi'

export const useLotteryContract = () => {
  const { address } = useAccount()

  const deposit = useCallback(
    async (abi: Record<string, any>[], contractAddress: `0x${string}`, amount: bigint, currency: `0x${string}`) => {
      const { request } = await (publicClient as any).simulateContract({
        address: contractAddress,
        abi,
        functionName: 'deposit',
        args: [currency, amount],
        account: address,
        value: amount,
      })
      const hash = await (walletClient as any).writeContract(request)

      const data = await (publicClient as any).waitForTransactionReceipt({ hash })

      return data
    },
    [address]
  )

  const withdraw = useCallback(
    async (abi: Record<string, any>[], contractAddress: `0x${string}`, amount: bigint, currency: `0x${string}`) => {
      const { request } = await (publicClient as any).simulateContract({
        address: contractAddress,
        abi,
        functionName: 'withdraw',
        args: [currency, amount],
        account: address,
        value: amount,
      })
      const data = await (walletClient as any).writeContract(request)

      return data
    },
    [address]
  )

  return { deposit, withdraw }
}

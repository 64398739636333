export default function LogoIcon({ className }: IconProps) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 195 54' fill='none' className={className}>
      <g clipPath='url(#clip0_54_13127)'>
        <path
          d='M27 54C41.9117 54 54 41.9117 54 27C54 12.0883 41.9117 0 27 0C12.0883 0 0 12.0883 0 27C0 41.9117 12.0883 54 27 54Z'
          fill='url(#paint0_linear_54_13127)'
        />
        <path
          d='M27.0004 44.71C21.1204 44.71 15.5904 42.11 11.8304 37.57C9.46039 34.71 7.96039 31.26 7.48039 27.59L7.40039 26.98L11.5404 22.84V12.02H12.7904C15.5004 12.02 17.9504 13.39 19.3704 15.61C21.6604 14.21 24.2704 13.48 27.0004 13.48C29.7304 13.48 32.3704 14.22 34.6704 15.63C36.0904 13.44 38.5704 12.04 41.2404 12.02H42.4904V22.84L46.6004 26.95L46.5204 27.56C46.0504 31.22 44.5604 34.66 42.2004 37.52C38.4404 42.09 32.9004 44.71 26.9904 44.71H27.0004Z'
          fill='white'
        />
        <path
          d='M47.0503 25.64L45.5403 24.13L43.7303 22.32V10.75H41.3703C39.5703 10.77 37.7903 11.27 36.2803 12.25C35.5303 12.74 34.8703 13.33 34.3103 13.99C32.0603 12.82 29.5803 12.22 26.9903 12.22C24.4003 12.22 21.9403 12.82 19.7003 13.98C19.1903 13.38 18.6003 12.84 17.9403 12.38C16.4203 11.33 14.6403 10.77 12.7803 10.77H10.2903V22.32L8.48031 24.13L6.94031 25.67L6.07031 26.54L6.23031 27.76C6.74031 31.66 8.34031 35.33 10.8603 38.37C14.8603 43.19 20.7403 45.96 26.9903 45.96C33.2403 45.96 39.1703 43.17 43.1603 38.32C45.6603 35.28 47.2503 31.62 47.7503 27.73L47.9103 26.51L47.0403 25.64H47.0503ZM43.2303 33.78L37.3203 27.47C36.0703 26.14 33.8503 25.94 32.3903 27.04L32.3603 27.06C30.8303 28.21 30.4303 30.32 31.4203 31.95L32.8903 34.36C35.2303 38.2 32.6403 43.15 28.1403 43.42C27.7603 43.44 27.3803 43.45 27.0003 43.45C26.6203 43.45 26.2403 43.44 25.8703 43.42C21.3803 43.15 18.7903 38.2 21.1203 34.36L22.5403 32.02C23.4503 30.52 23.2603 28.57 22.0103 27.35C20.5103 25.88 18.1103 25.94 16.6803 27.46L10.7703 33.77C9.72031 31.84 9.01031 29.69 8.71031 27.42L10.2503 25.88L12.7903 23.34V13.25C15.5703 13.25 17.9403 14.97 18.9003 17.41C19.8403 16.7 20.8803 16.11 21.9903 15.66L25.0603 20.98C25.9203 22.47 28.0703 22.47 28.9303 20.98L32.0003 15.66C33.1203 16.11 34.1703 16.71 35.1203 17.43C36.0703 14.99 38.4703 13.26 41.2403 13.24V23.33L43.7803 25.87L45.2903 27.38C45.0003 29.66 44.2803 31.81 43.2303 33.76V33.78Z'
          fill='#161D27'
        />
        <path
          d='M28.4601 34.1201H25.5401C24.2301 34.1201 23.5601 35.6901 24.4601 36.6401L26.0701 38.3301C26.5701 38.8601 27.4201 38.8601 27.9201 38.3301L29.5301 36.6401C30.4401 35.6901 29.7601 34.1201 28.4501 34.1201H28.4601Z'
          fill='#161D27'
        />
        <path d='M86.6902 21.28H77.9802V36H73.6602V21.28H64.9902V18H86.7002V21.28H86.6902Z' fill='white' />
        <path
          d='M105.69 36L103.78 31.54H98.7798V28.26H102.34L99.3498 21.31L92.9798 36H88.2598L96.0698 18H102.58L110.39 36H105.67H105.69Z'
          fill='white'
        />
        <path d='M135.25 36H130.68L117.87 23.19V36H113.59V18H118.49L130.94 30.49V18H135.26V36H135.25Z' fill='white' />
        <path
          d='M158.18 32.72V18H162.5V32.97C162.5 34.63 161.13 35.99 159.44 35.99H143.82C142.16 35.99 140.8 34.62 140.8 32.97V18H145.12V32.72H158.19H158.18Z'
          fill='white'
        />
        <path
          d='M180.36 18H185.22L178.09 25.49L187.13 36H182.23L175.5 28.19L168.12 36V18H172.44V26.32L180.36 18Z'
          fill='white'
        />
        <path d='M194.98 36H190.66V18H194.98V36Z' fill='white' />
      </g>
      <defs>
        <linearGradient
          id='paint0_linear_54_13127'
          x1='-3.7369e-07'
          y1='54'
          x2='54.1701'
          y2='0.171151'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#8B1666' />
          <stop offset='1' stopColor='#E52979' />
        </linearGradient>
        <clipPath id='clip0_54_13127'>
          <rect width='194.98' height='54' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

import ChevronRightIcon from '../icons/ChevronRightIcon'

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string
}

export default function DrawHistoryButton({ className }: Props) {
  return (
    <button className='btn-primary-outlined flex h-10 items-center justify-center pl-6 pr-3'>
      <p className='text-sm font-semibold text-white'>Draw History</p>
      <ChevronRightIcon className='h-6 stroke-white' />
    </button>
  )
}

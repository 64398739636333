import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { ClipLoader } from 'react-spinners'
import { apiGetCurrentDrawing, apiGetTicketsOfDrawing } from 'src/api/draw.api'
import Money from 'src/components/Money'
import MyTicket from 'src/components/MyTicket'
import YourTicketInGame from 'src/components/YourTicketInGame'
import DrawHistoryButton from 'src/components/buttons/DrawHistoryButton'
import Layout from 'src/layouts/Layout'
import { getTicketFromString } from 'src/utils/helpers'
import { formatEther } from 'viem'

export default function Draw() {
  const [status, setStatus] = useState<IStatus>({ loading: true, error: false })
  const [drawing, setDrawing] = useState<Record<string, any>>()
  const [tickets, setTickets] = useState<any[]>([])

  useEffect(() => {
    ;(async () => {
      try {
        const data = await apiGetCurrentDrawing()
        setDrawing(data.drawing)
        const { tickets } = await apiGetTicketsOfDrawing(data.drawing.id)
        setTickets(tickets)
        setStatus((prev) => ({ ...prev, error: false }))
      } catch (err) {
        setStatus((prev) => ({ ...prev, error: true }))
      } finally {
        setStatus((prev) => ({ ...prev, loading: false }))
      }
    })()
  }, [])

  return (
    <Layout>
      <div className='container mt-[126px] !max-w-[1086px] space-y-6 lg:mt-[204px]'>
        {status.loading ? (
          <div className='flex justify-center py-20'>
            <ClipLoader color='#E52979' />
          </div>
        ) : drawing ? (
          <>
            <div className='flex items-center justify-between'>
              <h2>Draw #{drawing.id}</h2>
              <Link to='/draw/history'>
                <DrawHistoryButton />
              </Link>
            </div>
            <div className='space-y-6'>
              <YourTicketInGame className='max-w-[330px]' ticketCount={tickets.length} />
              <div className='space-y-[22px] rounded-2xl bg-card-primary p-[22px]'>
                <div className='flex items-center justify-between gap-6 border-b border-card-secondary pb-6 sm:flex-col sm:items-start'>
                  <p className='text-2xl font-medium text-white'>Summary</p>
                  <div className='flex gap-0.5'>
                    {[0, 0, 0, 0, 0, 0].map((number, i) => (
                      <p
                        className='shadow-1 flex h-[58px] w-[58px] items-center justify-center rounded-[5px] bg-card-secondary text-[32px] font-semibold text-white sm:h-12 sm:w-12 sm:text-2xl'
                        key={i}
                      >
                        {number.toString().padStart(2, '0')}
                      </p>
                    ))}
                  </div>
                </div>
                <div className='flex items-center justify-between border-b border-dashed border-card-secondary pb-3'>
                  <p className='font-medium text-white'>Pot Size</p>
                  <Money amount={formatEther(drawing.pot_size[0].amount_wei)} />
                </div>
                <div className='grid grid-cols-3 gap-6 md:grid-cols-2 sm:grid-cols-1'>
                  {tickets.slice(0, 12).map((ticket, i) => (
                    <MyTicket key={i} ticket={getTicketFromString(ticket.numbers)} ticketNumber={ticket.id} />
                  ))}
                </div>
                <button className='btn-primary mx-auto block h-[54px] w-72 text-sm font-bold text-white sm:w-full'>
                  Show winning combination
                </button>
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    </Layout>
  )
}

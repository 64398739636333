import { cx } from 'src/utils/helpers'

interface Props {
  className?: string
  number: number
  theme?: string | null
  thickShadow?: boolean
}

export default function Number({ className, number, theme, thickShadow = false }: Props) {
  return (
    <button
      className={cx(
        'h-9 w-9 rounded-full text-sm',
        number === 0 ? 'font-medium text-gray' : 'font-semibold text-white',
        theme === 'tanuki'
          ? 'tanuki-gradient'
          : theme === 'secondary'
          ? 'secondary-gradient'
          : theme === 'base'
          ? 'base-gradient'
          : theme === 'primary'
          ? 'bg-card-primary'
          : 'bg-card-secondary',
        thickShadow ? 'shadow-[0px_0px_30px] shadow-[#EB8D39]/40' : 'shadow-1',
        className
      )}
    >
      {number.toString().padStart(2, '0')}
    </button>
  )
}

import { createAsyncThunk, createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { apiGetBalance } from 'src/api/auth.api'
import { apiGetTicketPrice } from 'src/api/public.api'

interface IUser {
  isLoggedIn: boolean
  balance: IPrice[]
  ticketPrice: IPrice[]
}

const initialState: IUser = {
  isLoggedIn: false,
  balance: [],
  ticketPrice: [],
}

export const getUserBalance = createAsyncThunk('user/getBalance', async () => {
  try {
    const data = await apiGetBalance()
    return data
  } catch (err: any) {
    throw err
  }
})

export const getTicketPrice = createAsyncThunk('user/getTicketPrice', async () => {
  try {
    const data = await apiGetTicketPrice()
    return data
  } catch (err: any) {
    throw err
  }
})

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setLogin(state, action: PayloadAction<boolean>) {
      state.isLoggedIn = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUserBalance.fulfilled, (state, action: PayloadAction<any>) => {
      state.balance = action.payload.balances

      return state
    })

    builder.addCase(getTicketPrice.fulfilled, (state, action: PayloadAction<any>) => {
      state.ticketPrice = action.payload.prices

      return state
    })
  },
})

export const { setLogin } = userSlice.actions //eslint-disable-line

export default userSlice.reducer

import { cx } from 'src/utils/helpers'

const buttons: { label: string; value: 'currently' | 'history' }[] = [
  { label: 'Currently', value: 'currently' },
  { label: 'History', value: 'history' },
]

interface Props {
  className?: string
  value: 'currently' | 'history'
  onClick: React.Dispatch<'currently' | 'history'>
}

export default function InventoryFilter({ className, value, onClick }: Props) {
  return (
    <div className={cx('shadow-1 h-10 w-fit rounded-full bg-card-secondary', className)}>
      {buttons.map((button, i) => (
        <button
          className={cx(
            'h-10 rounded-full px-4 text-sm',
            button.value === value ? 'tanuki-gradient font-semibold text-white' : 'font-medium text-gray'
          )}
          key={i}
          onClick={() => onClick(button.value)}
        >
          {button.label}
        </button>
      ))}
    </div>
  )
}

import { useEffect, useState } from 'react'
import { formatEther } from 'viem'
import { apiGetWinnings } from 'src/api/claim.api'
import Money from 'src/components/Money'
import Number from 'src/components/Number'
import Pagination from 'src/components/Pagination'
import Layout from 'src/layouts/Layout'
import { formatDateTime, getTicketFromString } from 'src/utils/helpers'

const tableHeader = [
  { label: 'Winning tickets' },
  { label: 'Date/Time' },
  { label: 'Amount' },
  { label: 'Numbers' },
  { label: 'Status' },
]

export default function Claim() {
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [hasNextPage, setHasNextPage] = useState<boolean>(false)
  const [winnings, setWinnings] = useState<any[]>([])

  useEffect(() => {
    getWinnings(1)
  }, [])

  const getWinnings = async (page: number) => {
    try {
      const data = await apiGetWinnings(page)
      setWinnings(data.winnings || [])
      setCurrentPage(page)
      setHasNextPage(data.next_page)
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <Layout>
      <div className='container mt-[126px] space-y-6 lg:mt-[204px]'>
        <h2>Claim Winnings</h2>
        <div className='shadow-1 rounded-2xl bg-card-primary'>
          <div className='space-y-1 px-6 py-5'>
            <p className='text-lg font-medium text-white'>All your winning tickets</p>
            <p className='text-sm text-gray'>
              Here you can choose the one that best suits your plans and wins the favor.
            </p>
          </div>
          <div className='w-full overflow-x-auto'>
            <table className='w-full divide-y divide-card-secondary'>
              <thead>
                <tr>
                  {tableHeader.map((th, i) => (
                    <th key={i}>
                      <span className='flex w-fit cursor-pointer items-center gap-1'>
                        <p>{th.label}</p>
                      </span>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {winnings.map((winning, i) => (
                  <tr className='border-b border-card-secondary' key={i}>
                    <td className='font-medium text-white'>#{winning.id}</td>
                    <td className='font-medium text-gray'>{formatDateTime(winning.ticket.created)}</td>
                    <td>
                      <span className='flex items-center gap-3'>
                        <Money amount={formatEther(winning.tokens[0].amount_wei)} />
                      </span>
                    </td>
                    <td>
                      <span className='flex gap-3'>
                        {getTicketFromString(winning.ticket.numbers).map((number, j) => (
                          <Number key={`${i}-${j}`} number={number} theme='secondary' thickShadow />
                        ))}
                      </span>
                    </td>
                    <td>
                      {winning.claim_complete ? (
                        <button className='shadow-1 main-gradient h-7 w-[120px] cursor-default rounded-lg text-sm font-semibold text-white'>
                          Claimed
                        </button>
                      ) : (
                        <button className='shadow-1 btn-secondary-outlined h-7 w-[120px] font-semibold'>
                          Unclaimed
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className='px-6 pb-4 pt-3'>
            <Pagination
              isFirstPage={currentPage === 1}
              isLastPage={!hasNextPage}
              onPrev={() => getWinnings(currentPage - 1)}
              onNext={() => getWinnings(currentPage + 1)}
            />
          </div>
        </div>
      </div>
    </Layout>
  )
}

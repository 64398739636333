import axios from 'src/services/axios'

export const apiGetChallenge = async () => {
  const { data } = await axios.post('/auth/challenge')
  return data
}

export const apiLogin = async (message: string, address: string, signature: string) => {
  const { data } = await axios.post('/auth/login', { message, address, signature })
  return data
}

export const apiSignedIn = async () => {
  const { data } = await axios.post('auth/signed-in')
  return data
}

export const apiLogout = async () => {
  const { data } = await axios.post('/auth/logout')
  return data
}

export const apiGetBalance = async () => {
  const { data } = await axios.post('/get-balances')
  return data
}

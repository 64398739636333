import { Link } from 'react-router-dom'
import LogoIcon from 'src/components/icons/LogoIcon'
import { menu } from './Header'
import useAppDispatch from 'src/hooks/useAppDispatch'
import { setDepositModalOpen, setWithdrawModalOpen } from 'src/redux/slices/modal'

export default function Footer() {
  const dispatch = useAppDispatch()

  const handleClickDeposit = () => {
    dispatch(setDepositModalOpen(true))
  }

  const handleClickWithdraw = () => {
    dispatch(setWithdrawModalOpen(true))
  }

  return (
    <footer className='bg-card-primary pb-12 pt-16'>
      <div className='container space-y-16'>
        <div className='space-y-8'>
          <Link to='/' className='flex w-fit'>
            <LogoIcon className='h-[54px]' />
          </Link>
          <nav className='flex gap-8'>
            {menu.map((link, i) => (
              <Link to={link.to} className='font-medium text-[#E7E7E8]' key={i}>
                {link.title}
              </Link>
            ))}
            <button className='font-medium text-[#E7E7E8]' onClick={handleClickDeposit}>
              Deposit
            </button>
            <button className='font-medium text-[#E7E7E8]' onClick={handleClickWithdraw}>
              Withdraw
            </button>
          </nav>
        </div>
        <div className='flex justify-between border-t border-card-secondary pt-8'>
          <p className='text-[#5E677B]'>&copy; 2023 Tanuki. All rights reserved.</p>
          <div className='flex gap-4'>
            <button className='text-[#5E677B]'>Terms</button>
            <button className='text-[#5E677B]'>Privacy</button>
            <button className='text-[#5E677B]'>Cookies</button>
          </div>
        </div>
      </div>
    </footer>
  )
}

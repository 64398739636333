import { createConfig, configureChains } from 'wagmi'
import { goerli, mainnet } from 'wagmi/chains'

import { publicProvider } from 'wagmi/providers/public'

import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet'
import { InjectedConnector } from 'wagmi/connectors/injected'
import { MetaMaskConnector } from 'wagmi/connectors/metaMask'
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect'
import { createPublicClient, createWalletClient, custom, http } from 'viem'

// Configure chains & providers with the Alchemy provider.
// Two popular providers are Alchemy (alchemy.com) and Infura (infura.io)
const {
  chains,
  publicClient: _publicClient,
  webSocketPublicClient,
} = configureChains([goerli, mainnet], [publicProvider()])

// Set up wagmi config
export const config = createConfig({
  autoConnect: true,
  connectors: [
    new MetaMaskConnector({ chains }),
    new CoinbaseWalletConnector({
      chains,
      options: {
        appName: 'wagmi',
      },
    }),
    new WalletConnectConnector({
      chains,
      options: {
        projectId: '6095d99290e358306292af3c49e78dd8',
      },
    }),
    new InjectedConnector({
      chains,
      options: {
        name: 'Injected',
        shimDisconnect: true,
      },
    }),
  ],
  publicClient: _publicClient,
  webSocketPublicClient,
})

export const publicClient = createPublicClient({
  chain: goerli,
  transport: http(),
})

export const walletClient = createWalletClient({
  chain: goerli,
  transport: custom(window.ethereum),
})

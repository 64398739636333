export default function Learning1Icon({ className }: IconProps) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 110 100' fill='none' className={className}>
      <g clipPath='url(#clip0_57_13759)'>
        <path d='M66.6751 97.6299L0.225098 59.2599L43.3251 34.3799L109.775 72.7399L66.6751 97.6299Z' fill='#485262' />
        <path
          d='M104.655 72.8098L66.805 94.6598L58.335 89.7698L51.545 85.8498L44.345 81.6998L26.775 71.5498L5.35498 59.1898L43.195 37.3398L54.475 43.8498L72.055 53.9898L79.245 58.1498L86.045 62.0698L104.655 72.8098Z'
          fill='url(#paint0_linear_57_13759)'
        />
        <path d='M79.2243 65.3199L56.1743 52.0099L59.4343 50.1299L82.4843 63.4399L79.2243 65.3199Z' fill='#151A26' />
        <path d='M69.8447 65.8198L55.3247 57.4298L58.5747 55.5498L73.1047 63.9398L69.8447 65.8198Z' fill='#151A26' />
        <path d='M87.605 80.4799L79.145 75.5899L80.285 74.9399L88.745 79.8199L87.605 80.4799Z' fill='#253242' />
        <path d='M84.7144 82.1501L76.2544 77.2601L77.3944 76.6001L85.8544 81.4901L84.7144 82.1501Z' fill='#253242' />
        <path d='M81.8247 83.81L73.3647 78.93L74.5047 78.27L82.9647 83.16L81.8247 83.81Z' fill='#253242' />
        <path d='M78.9345 85.48L73.1646 82.15L74.3046 81.5L80.0745 84.83L78.9345 85.48Z' fill='#253242' />
        <path d='M72.6548 89.1098L64.1948 84.2298L65.3348 83.5698L73.7948 88.4498L72.6548 89.1098Z' fill='#253242' />
        <path d='M69.7646 90.78L63.9946 87.45L65.1346 86.79L70.9046 90.12L69.7646 90.78Z' fill='#253242' />
        <path
          d='M44.8151 60.1901L37.4651 55.9501C36.8751 55.6101 36.9451 55.0101 37.6351 54.6201C38.3151 54.2301 39.3451 54.1801 39.9351 54.5201L47.2851 58.7601C47.8751 59.1001 47.8051 59.7001 47.1151 60.0901C46.4351 60.4801 45.4051 60.5301 44.8151 60.1901Z'
          fill='#253242'
        />
        <path
          d='M54.7449 64.5C53.2649 65.36 50.8649 65.35 49.3849 64.5C47.9049 63.65 47.9049 62.26 49.3849 61.4C50.8649 60.54 53.2649 60.55 54.7449 61.4C56.2249 62.25 56.2249 63.64 54.7449 64.5Z'
          fill='#141B25'
        />
        <path
          d='M62.2547 68.8301C60.7747 69.6801 58.3747 69.6801 56.8947 68.8301C55.4147 67.9801 55.4147 66.5901 56.8947 65.7301C58.3747 64.8701 60.7747 64.8801 62.2547 65.7301C63.7347 66.5801 63.7347 67.9701 62.2547 68.8301Z'
          fill='#151A26'
        />
        <path
          d='M69.7654 73.1701C68.2854 74.0201 65.8854 74.0201 64.4054 73.1701C62.9254 72.3201 62.9254 70.9301 64.4054 70.0701C65.8854 69.2201 68.2854 69.2201 69.7654 70.0701C71.2454 70.9201 71.2454 72.3101 69.7654 73.1701Z'
          fill='#151A26'
        />
        <path
          d='M38.075 64.0803L28.305 58.4403C27.715 58.1003 27.785 57.5003 28.475 57.1103C29.155 56.7203 30.185 56.6703 30.775 57.0103L40.545 62.6503C41.135 62.9903 41.065 63.5903 40.375 63.9803C39.695 64.3703 38.665 64.4203 38.075 64.0803Z'
          fill='#253242'
        />
        <path
          d='M47.9949 68.3903C46.5149 69.2403 44.1149 69.2403 42.6349 68.3903C41.1549 67.5403 41.1549 66.1503 42.6349 65.2903C44.1149 64.4403 46.5149 64.4403 47.9949 65.2903C49.4749 66.1403 49.4749 67.5303 47.9949 68.3903Z'
          fill='#151A26'
        />
        <path
          d='M55.5154 72.7302C54.0354 73.5802 51.6354 73.5802 50.1554 72.7302C48.6754 71.8802 48.6754 70.4902 50.1554 69.6302C51.6354 68.7802 54.0354 68.7802 55.5154 69.6302C56.9954 70.4802 56.9954 71.8702 55.5154 72.7302Z'
          fill='#141B25'
        />
        <path
          d='M63.0252 77.0603C61.5452 77.9103 59.1452 77.9103 57.6652 77.0603C56.1852 76.2103 56.1852 74.8203 57.6652 73.9603C59.1452 73.1103 61.5452 73.1103 63.0252 73.9603C64.5052 74.8103 64.5052 76.2003 63.0252 77.0603Z'
          fill='#141B25'
        />
        <path
          d='M31.3249 67.97L18.5149 60.58C17.9249 60.24 17.9949 59.64 18.6849 59.25C19.3649 58.86 20.3949 58.81 20.9849 59.15L33.7949 66.54C34.3849 66.88 34.3149 67.48 33.6249 67.87C32.9449 68.26 31.9149 68.31 31.3249 67.97Z'
          fill='#253242'
        />
        <path
          d='M41.2547 72.2798C39.7747 73.1298 37.3747 73.1298 35.8947 72.2798C34.4147 71.4298 34.4147 70.0398 35.8947 69.1798C37.3747 68.3198 39.7747 68.3298 41.2547 69.1798C42.7347 70.0298 42.7347 71.4198 41.2547 72.2798Z'
          fill='#141B25'
        />
        <path
          d='M48.7654 76.6202C47.2854 77.4702 44.8854 77.4702 43.4054 76.6202C41.9254 75.7702 41.9254 74.3802 43.4054 73.5202C44.8854 72.6602 47.2854 72.6702 48.7654 73.5202C50.2454 74.3702 50.2454 75.7602 48.7654 76.6202Z'
          fill='#151A26'
        />
        <path
          d='M56.2752 80.9499C54.7952 81.7999 52.3952 81.7999 50.9152 80.9499C49.4352 80.0999 49.4352 78.7099 50.9152 77.8499C52.3952 76.9999 54.7952 76.9999 56.2752 77.8499C57.7552 78.6999 57.7552 80.0899 56.2752 80.9499Z'
          fill='#151A26'
        />
        <path
          d='M49.165 44.64C46.595 43.15 42.415 43.15 39.845 44.64C37.275 46.13 37.275 48.54 39.845 50.02C42.415 51.5 46.595 51.51 49.165 50.02C51.735 48.53 51.735 46.12 49.165 44.64ZM40.815 49.46C38.775 48.28 38.785 46.38 40.815 45.2C42.855 44.02 46.155 44.02 48.185 45.2C50.225 46.38 50.225 48.28 48.185 49.46C46.145 50.64 42.835 50.64 40.795 49.46H40.815Z'
          fill='#151A26'
        />
        <path
          d='M47.0848 48.8301C45.6548 49.6501 43.3448 49.6501 41.9148 48.8301C40.4848 48.0101 40.4848 46.6701 41.9148 45.8401C43.3448 45.0201 45.6548 45.0201 47.0848 45.8401C48.5148 46.6601 48.5148 48.0001 47.0848 48.8301Z'
          fill='#151A26'
        />
        <path d='M66.6751 99.4898L0.225098 61.1198V59.2798L66.6751 97.6498V99.4898Z' fill='#222A37' />
        <path d='M109.775 74.5998L66.6753 99.4898V97.6498L109.775 72.7598V74.5998Z' fill='#222A37' />
        <path d='M66.6751 81.8301L0.225098 43.4701L43.3251 18.5801L109.775 56.9501L66.6751 81.8301Z' fill='#485262' />
        <path
          d='M104.655 57.0203L66.805 78.8703L58.335 73.9803L51.545 70.0603L44.345 65.9103L26.775 55.7603L5.35498 43.3903L43.195 21.5503L54.475 28.0603L72.055 38.2003L79.245 42.3503L86.035 46.2703L104.655 57.0203Z'
          fill='#00CDAC'
        />
        <path d='M79.2243 49.5298L56.1743 36.2198L59.4343 34.3398L82.4843 47.6498L79.2243 49.5298Z' fill='#151A26' />
        <path d='M69.8447 50.0198L55.3247 41.6398L58.5747 39.7598L73.1047 48.1398L69.8447 50.0198Z' fill='#151A26' />
        <path d='M87.605 64.6801L79.145 59.8001L80.285 59.1401L88.745 64.0301L87.605 64.6801Z' fill='#253242' />
        <path d='M84.7144 66.3501L76.2544 61.4701L77.3944 60.8101L85.8544 65.6901L84.7144 66.3501Z' fill='#253242' />
        <path d='M81.8247 68.02L73.3647 63.14L74.5047 62.48L82.9647 67.36L81.8247 68.02Z' fill='#253242' />
        <path d='M78.9345 69.6902L73.1646 66.3602L74.3046 65.7002L80.0745 69.0302L78.9345 69.6902Z' fill='#253242' />
        <path d='M72.6548 73.3203L64.1948 68.4303L65.3348 67.7803L73.7948 72.6603L72.6548 73.3203Z' fill='#253242' />
        <path d='M69.7646 74.99L63.9946 71.66L65.1346 71L70.9046 74.33L69.7646 74.99Z' fill='#253242' />
        <path
          d='M44.8151 44.4001L37.4651 40.1601C36.8751 39.8201 36.9451 39.2201 37.6351 38.8301C38.3151 38.4401 39.3451 38.39 39.9351 38.73L47.2851 42.9701C47.8751 43.3101 47.8051 43.91 47.1151 44.3C46.4351 44.69 45.4051 44.7401 44.8151 44.4001Z'
          fill='#253242'
        />
        <path
          d='M54.7449 48.7002C53.2649 49.5602 50.8649 49.5502 49.3849 48.7002C47.9049 47.8502 47.9049 46.4602 49.3849 45.6002C50.8649 44.7402 53.2649 44.7502 54.7449 45.6002C56.2249 46.4502 56.2249 47.8402 54.7449 48.7002Z'
          fill='#141B25'
        />
        <path
          d='M62.2547 53.0401C60.7747 53.8901 58.3747 53.8901 56.8947 53.0401C55.4147 52.1901 55.4147 50.8001 56.8947 49.9401C58.3747 49.0801 60.7747 49.0901 62.2547 49.9401C63.7347 50.7901 63.7347 52.1801 62.2547 53.0401Z'
          fill='#151A26'
        />
        <path
          d='M69.7654 57.3801C68.2854 58.2301 65.8854 58.2301 64.4054 57.3801C62.9254 56.5301 62.9254 55.1401 64.4054 54.2801C65.8854 53.4301 68.2854 53.4301 69.7654 54.2801C71.2454 55.1301 71.2454 56.5201 69.7654 57.3801Z'
          fill='#151A26'
        />
        <path
          d='M38.075 48.2903L28.305 42.6503C27.715 42.3103 27.785 41.7103 28.475 41.3203C29.155 40.9303 30.185 40.8803 30.775 41.2203L40.545 46.8603C41.135 47.2003 41.065 47.8003 40.375 48.1903C39.695 48.5803 38.665 48.6303 38.075 48.2903Z'
          fill='#253242'
        />
        <path
          d='M47.9949 52.6003C46.5149 53.4503 44.1149 53.4503 42.6349 52.6003C41.1549 51.7503 41.1549 50.3603 42.6349 49.5003C44.1149 48.6503 46.5149 48.6503 47.9949 49.5003C49.4749 50.3503 49.4749 51.7403 47.9949 52.6003Z'
          fill='#151A26'
        />
        <path
          d='M55.5047 56.9304C54.0247 57.7804 51.6247 57.7804 50.1447 56.9304C48.6647 56.0804 48.6647 54.6904 50.1447 53.8304C51.6247 52.9804 54.0247 52.9804 55.5047 53.8304C56.9847 54.6804 56.9847 56.0704 55.5047 56.9304Z'
          fill='#141B25'
        />
        <path
          d='M63.0252 61.2702C61.5452 62.1202 59.1452 62.1202 57.6652 61.2702C56.1852 60.4202 56.1852 59.0302 57.6652 58.1702C59.1452 57.3202 61.5452 57.3202 63.0252 58.1702C64.5052 59.0202 64.5052 60.4102 63.0252 61.2702Z'
          fill='#141B25'
        />
        <path
          d='M31.3249 52.1799L18.5149 44.7899C17.9249 44.4499 17.9949 43.8499 18.6849 43.4599C19.3649 43.0699 20.3949 43.0199 20.9849 43.3599L33.7949 50.7499C34.3849 51.0899 34.3149 51.6899 33.6249 52.0799C32.9449 52.4699 31.9149 52.5199 31.3249 52.1799Z'
          fill='#253242'
        />
        <path
          d='M41.2547 56.4898C39.7747 57.3398 37.3747 57.3398 35.8947 56.4898C34.4147 55.6398 34.4147 54.2498 35.8947 53.3898C37.3747 52.5298 39.7747 52.5398 41.2547 53.3898C42.7347 54.2398 42.7347 55.6298 41.2547 56.4898Z'
          fill='#141B25'
        />
        <path
          d='M48.7654 60.8199C47.2854 61.6699 44.8854 61.6699 43.4054 60.8199C41.9254 59.9699 41.9254 58.5799 43.4054 57.7199C44.8854 56.8599 47.2854 56.8699 48.7654 57.7199C50.2454 58.5699 50.2454 59.9599 48.7654 60.8199Z'
          fill='#151A26'
        />
        <path
          d='M56.2752 65.1599C54.7952 66.0099 52.3952 66.0099 50.9152 65.1599C49.4352 64.3099 49.4352 62.9199 50.9152 62.0599C52.3952 61.2099 54.7952 61.2099 56.2752 62.0599C57.7552 62.9099 57.7552 64.2999 56.2752 65.1599Z'
          fill='#151A26'
        />
        <path
          d='M49.165 28.8499C46.595 27.3599 42.415 27.3599 39.845 28.8499C37.275 30.3399 37.275 32.7499 39.845 34.2299C42.415 35.7099 46.595 35.7199 49.165 34.2299C51.735 32.7399 51.735 30.3299 49.165 28.8499ZM40.815 33.6699C38.775 32.4899 38.785 30.5899 40.815 29.4099C42.855 28.2299 46.155 28.2299 48.185 29.4099C50.225 30.5899 50.225 32.4899 48.185 33.6699C46.145 34.8499 42.835 34.8499 40.795 33.6699H40.815Z'
          fill='#151A26'
        />
        <path
          d='M47.0848 33.0298C45.6548 33.8498 43.3448 33.8498 41.9148 33.0298C40.4848 32.2098 40.4848 30.8698 41.9148 30.0398C43.3448 29.2198 45.6548 29.2198 47.0848 30.0398C48.5148 30.8598 48.5148 32.1998 47.0848 33.0298Z'
          fill='#151A26'
        />
        <path d='M66.6751 83.6902L0.225098 45.3202V43.4902L66.6751 81.8502V83.6902Z' fill='#222A37' />
        <path d='M109.775 58.8102L66.6753 83.6902V81.8502L109.775 56.9702V58.8102Z' fill='#222A37' />
        <path d='M66.6751 63.25L0.225098 24.88L43.3251 0L109.775 38.37L66.6751 63.25Z' fill='#485262' />
        <path
          d='M104.655 38.4402L66.805 60.2802L58.335 55.4002L51.545 51.4802L44.345 47.3202L26.775 37.1802L5.35498 24.8102L43.195 2.97021L54.475 9.47021L72.055 19.6202L79.245 23.7702L86.035 27.6902L104.655 38.4402Z'
          fill='url(#paint1_linear_57_13759)'
        />
        <path d='M79.2243 30.9498L56.1743 17.6398L59.4343 15.7598L82.4843 29.0698L79.2243 30.9498Z' fill='#151A26' />
        <path d='M69.8447 31.4399L55.3247 23.0499L58.5747 21.1699L73.1047 29.5599L69.8447 31.4399Z' fill='#151A26' />
        <path d='M87.605 46.1001L79.145 41.2201L80.285 40.5601L88.745 45.4401L87.605 46.1001Z' fill='#253242' />
        <path d='M84.7144 47.77L76.2544 42.89L77.3944 42.23L85.8544 47.11L84.7144 47.77Z' fill='#253242' />
        <path d='M81.8247 49.4399L73.3647 44.5599L74.5047 43.8999L82.9647 48.7799L81.8247 49.4399Z' fill='#253242' />
        <path d='M78.9345 51.1101L73.1646 47.7801L74.3046 47.1201L80.0745 50.4501L78.9345 51.1101Z' fill='#253242' />
        <path d='M72.6548 54.7299L64.1948 49.8499L65.3348 49.1899L73.7948 54.0799L72.6548 54.7299Z' fill='#253242' />
        <path d='M69.7646 56.3999L63.9946 53.0699L65.1346 52.4199L70.9046 55.7499L69.7646 56.3999Z' fill='#253242' />
        <path
          d='M44.8151 25.8102L37.4651 21.5702C36.8751 21.2302 36.9451 20.6302 37.6351 20.2402C38.3151 19.8502 39.3451 19.8002 39.9351 20.1402L47.2851 24.3802C47.8751 24.7202 47.8051 25.3202 47.1151 25.7102C46.4351 26.1002 45.4051 26.1502 44.8151 25.8102Z'
          fill='#253242'
        />
        <path
          d='M54.7449 30.1202C53.2649 30.9802 50.8649 30.9702 49.3849 30.1202C47.9049 29.2702 47.9049 27.8802 49.3849 27.0202C50.8649 26.1602 53.2649 26.1702 54.7449 27.0202C56.2249 27.8702 56.2249 29.2602 54.7449 30.1202Z'
          fill='#141B25'
        />
        <path
          d='M62.2547 34.46C60.7747 35.31 58.3747 35.31 56.8947 34.46C55.4147 33.61 55.4147 32.22 56.8947 31.36C58.3747 30.5 60.7747 30.51 62.2547 31.36C63.7347 32.21 63.7347 33.6 62.2547 34.46Z'
          fill='#151A26'
        />
        <path
          d='M69.7654 38.7902C68.2854 39.6402 65.8854 39.6402 64.4054 38.7902C62.9254 37.9402 62.9254 36.5502 64.4054 35.6902C65.8854 34.8402 68.2854 34.8402 69.7654 35.6902C71.2454 36.5402 71.2454 37.9302 69.7654 38.7902Z'
          fill='#151A26'
        />
        <path
          d='M38.075 29.7102L28.305 24.0702C27.715 23.7302 27.785 23.1302 28.475 22.7402C29.155 22.3502 30.185 22.3002 30.775 22.6402L40.545 28.2802C41.135 28.6202 41.065 29.2202 40.375 29.6102C39.695 30.0002 38.665 30.0502 38.075 29.7102Z'
          fill='#253242'
        />
        <path
          d='M47.9949 34.0104C46.5149 34.8604 44.1149 34.8604 42.6349 34.0104C41.1549 33.1604 41.1549 31.7704 42.6349 30.9104C44.1149 30.0604 46.5149 30.0604 47.9949 30.9104C49.4749 31.7604 49.4749 33.1504 47.9949 34.0104Z'
          fill='#151A26'
        />
        <path
          d='M55.5047 38.3503C54.0247 39.2003 51.6247 39.2003 50.1447 38.3503C48.6647 37.5003 48.6647 36.1103 50.1447 35.2503C51.6247 34.4003 54.0247 34.4003 55.5047 35.2503C56.9847 36.1003 56.9847 37.4903 55.5047 38.3503Z'
          fill='#141B25'
        />
        <path
          d='M63.0252 42.6901C61.5452 43.5401 59.1452 43.5401 57.6652 42.6901C56.1852 41.8401 56.1852 40.4501 57.6652 39.5901C59.1452 38.7401 61.5452 38.7401 63.0252 39.5901C64.5052 40.4401 64.5052 41.8301 63.0252 42.6901Z'
          fill='#141B25'
        />
        <path
          d='M31.3249 33.6003L18.5149 26.2103C17.9249 25.8703 17.9949 25.2703 18.6849 24.8803C19.3649 24.4903 20.3949 24.4403 20.9849 24.7803L33.7949 32.1703C34.3849 32.5103 34.3149 33.1103 33.6249 33.5003C32.9449 33.8903 31.9149 33.9403 31.3249 33.6003Z'
          fill='#253242'
        />
        <path
          d='M41.2547 37.9102C39.7747 38.7602 37.3747 38.7602 35.8947 37.9102C34.4147 37.0602 34.4147 35.6702 35.8947 34.8102C37.3747 33.9502 39.7747 33.9602 41.2547 34.8102C42.7347 35.6602 42.7347 37.0502 41.2547 37.9102Z'
          fill='#141B25'
        />
        <path
          d='M48.7654 42.2403C47.2854 43.0903 44.8854 43.0903 43.4054 42.2403C41.9254 41.3903 41.9254 40.0003 43.4054 39.1403C44.8854 38.2803 47.2854 38.2903 48.7654 39.1403C50.2454 39.9903 50.2454 41.3803 48.7654 42.2403Z'
          fill='#151A26'
        />
        <path
          d='M56.2752 46.5803C54.7952 47.4303 52.3952 47.4303 50.9152 46.5803C49.4352 45.7303 49.4352 44.3403 50.9152 43.4803C52.3952 42.6303 54.7952 42.6303 56.2752 43.4803C57.7552 44.3303 57.7552 45.7203 56.2752 46.5803Z'
          fill='#151A26'
        />
        <path
          d='M49.165 10.2698C46.595 8.77984 42.415 8.77984 39.845 10.2698C37.275 11.7598 37.275 14.1698 39.845 15.6498C42.415 17.1298 46.595 17.1398 49.165 15.6498C51.735 14.1598 51.735 11.7498 49.165 10.2698ZM40.815 15.0898C38.775 13.9098 38.785 12.0098 40.815 10.8298C42.855 9.64984 46.155 9.64984 48.185 10.8298C50.225 12.0098 50.225 13.9098 48.185 15.0898C46.145 16.2698 42.835 16.2698 40.795 15.0898H40.815Z'
          fill='#151A26'
        />
        <path
          d='M47.0848 14.4497C45.6548 15.2697 43.3448 15.2697 41.9148 14.4497C40.4848 13.6297 40.4848 12.2897 41.9148 11.4597C43.3448 10.6397 45.6548 10.6397 47.0848 11.4597C48.5148 12.2797 48.5148 13.6197 47.0848 14.4497Z'
          fill='#151A26'
        />
        <path d='M66.6751 65.1102L0.225098 26.7402V24.9102L66.6751 63.2702V65.1102Z' fill='#222A37' />
        <path d='M109.775 40.2301L66.6753 65.1101V63.2701L109.775 38.3901V40.2301Z' fill='#222A37' />
      </g>
      <defs>
        <linearGradient
          id='paint0_linear_57_13759'
          x1='5.35498'
          y1='94.6598'
          x2='55.305'
          y2='8.67247'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#8B1666' />
          <stop offset='1' stopColor='#E52979' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_57_13759'
          x1='5.35498'
          y1='60.2802'
          x2='55.292'
          y2='-25.6997'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#8B1666' />
          <stop offset='1' stopColor='#E52979' />
        </linearGradient>
        <clipPath id='clip0_57_13759'>
          <rect width='109.55' height='99.49' fill='white' transform='translate(0.225098)' />
        </clipPath>
      </defs>
    </svg>
  )
}

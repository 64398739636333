import { useEffect, useState } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Modals from './components/modals'
import Claim from './pages/Claim'
import Draw from './pages/Draw'
import DrawHistory from './pages/Draw/DrawHistory'
import Home from './pages/Home'
import Inventory from './pages/Inventory'
import NotFound from './pages/NotFound'
import Powerball from './pages/Powerball'
import useAppDispatch from './hooks/useAppDispatch'
import { getPublicStats } from './redux/slices/config'
import { useAccount, useSignMessage } from 'wagmi'
import { apiGetChallenge, apiLogin } from './api/auth.api'
import { getTicketPrice, getUserBalance, setLogin } from './redux/slices/user'
import useAppSelector from './hooks/useAppSelector'

export default function App() {
  const { address, isConnected } = useAccount()
  const { signMessageAsync } = useSignMessage()
  const [mutate, setMutate] = useState<boolean>(false)
  const dispatch = useAppDispatch()
  const isLoggedIn = useAppSelector((state) => state.user.isLoggedIn)

  useEffect(() => {
    let timer1: any, timer2: any
    dispatch(getPublicStats())

    const fetchPublicStats = async () => {
      try {
        await dispatch(getPublicStats())
      } catch (err) {
        console.log(err)
      } finally {
        timer1 = setTimeout(fetchPublicStats, 60000)
      }
    }

    fetchPublicStats()

    const fetchTicketPrice = async () => {
      try {
        await dispatch(getTicketPrice())
      } catch (err) {
        console.log(err)
      } finally {
        timer2 = setTimeout(fetchTicketPrice, 60000)
      }
    }

    fetchTicketPrice()

    return () => {
      clearTimeout(timer1)
      clearTimeout(timer2)
    }
  }, [dispatch])

  useEffect(() => {
    if (!isConnected || !address || !signMessageAsync) {
      return
    }

    ;(async () => {
      try {
        const authWallet = localStorage.getItem('auth-wallet')
        const authToken = localStorage.getItem('auth-token')
        const authExpireTIme = localStorage.getItem('auth-token-expire')

        if (
          !authWallet ||
          !authToken ||
          !authExpireTIme ||
          authWallet !== address.toLowerCase() ||
          +authExpireTIme * 1000 < Date.now()
        ) {
          const { challenge } = await apiGetChallenge()
          const signature = await signMessageAsync({ message: challenge })
          const { success, authorization, expires } = await apiLogin(challenge, address.toLowerCase(), signature)

          if (success) {
            localStorage.setItem('auth-wallet', address.toLowerCase())
            localStorage.setItem('auth-token', authorization)
            localStorage.setItem('auth-token-expire', expires)
            dispatch(setLogin(true))
          }
        } else {
          dispatch(setLogin(true))
        }
      } catch (err: any) {
        if (err.name === 'ConnectorNotFoundError') {
          setMutate((prev) => !prev)
        }
      }
    })()
  }, [isConnected, address, signMessageAsync, mutate, dispatch])

  useEffect(() => {
    if (!isLoggedIn) {
      return
    }

    let timer1: any

    const fetchUserBalance = async () => {
      try {
        await dispatch(getUserBalance())
      } catch (err) {
        console.log(err)
      } finally {
        timer1 = setTimeout(fetchUserBalance, 60000)
      }
    }

    fetchUserBalance()

    return () => {
      clearTimeout(timer1)
    }
  }, [isLoggedIn, dispatch])

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/powerball' element={<Powerball />} />
          <Route path='/inventory' element={<Inventory />} />
          <Route path='/draw'>
            <Route index element={<Draw />} />
            <Route path='history' element={<DrawHistory />} />
          </Route>
          <Route path='/claim' element={<Claim />} />
          <Route path='/*' element={<NotFound />} />
        </Routes>
      </BrowserRouter>
      <Modals />
    </>
  )
}

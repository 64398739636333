import MinusIcon from '../icons/MinusIcon'

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {}

export default function MinusButton({ ...props }: Props) {
  return (
    <button className='flex h-9 w-9 items-center justify-center rounded-full bg-card-secondary' {...props}>
      <MinusIcon className='h-6' />
    </button>
  )
}

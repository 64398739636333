export default function Learning3Icon({ className }: IconProps) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 114 100' fill='none' className={className}>
      <g clipPath='url(#clip0_57_14157)'>
        <path
          d='M96.7449 46.6201V48.9101C96.7449 50.4201 95.7549 51.8901 93.7849 53.0301C89.8349 55.3201 83.4249 55.3201 79.4849 53.0301C77.5049 51.8901 76.5249 50.4201 76.5249 48.9101V46.6201H96.7549H96.7449Z'
          fill='#222A37'
        />
        <path
          d='M96.7446 45.5898V47.8798C96.7446 49.0398 96.1746 50.1598 95.0146 51.1398C94.6646 51.4398 94.2446 51.7298 93.7746 51.9998C93.6746 52.0598 93.5646 52.1198 93.4446 52.1698C92.7546 52.5498 92.0046 52.8498 91.2046 53.0698C90.6946 53.2398 90.1746 53.3598 89.6346 53.4498C88.9046 53.5898 88.1546 53.6698 87.3946 53.6998C86.8746 53.7298 86.3546 53.7298 85.8346 53.6998C85.0846 53.6698 84.3246 53.5998 83.5946 53.4498C83.0646 53.3598 82.5246 53.2398 82.0246 53.0698C81.2346 52.8498 80.4746 52.5498 79.7846 52.1698C79.6846 52.1198 79.5646 52.0698 79.4546 51.9998C78.9846 51.7298 78.5746 51.4398 78.2246 51.1398C77.0646 50.1498 76.4946 49.0298 76.4946 47.8798V45.5898H96.7246H96.7446Z'
          fill='url(#paint0_linear_57_14157)'
        />
        <path
          d='M79.8046 45.5898V52.1698C79.7046 52.1198 79.5846 52.0698 79.4746 51.9998C79.0046 51.7298 78.5946 51.4398 78.2446 51.1398V45.5898H79.8046Z'
          fill='#222A37'
        />
        <path
          d='M83.6144 45.5901V53.4401C83.0844 53.3501 82.5444 53.2301 82.0444 53.0601V45.5801H83.6144V45.5901Z'
          fill='#222A37'
        />
        <path d='M87.415 45.5898V53.6898C86.895 53.7198 86.375 53.7198 85.855 53.6898V45.5898H87.415Z' fill='#222A37' />
        <path
          d='M91.2248 45.5898V53.0698C90.7148 53.2398 90.1948 53.3598 89.6548 53.4498V45.5998H91.2248V45.5898Z'
          fill='#222A37'
        />
        <path
          d='M95.0148 45.5898V51.1398C94.6648 51.4398 94.2448 51.7298 93.7748 51.9998C93.6748 52.0598 93.5648 52.1198 93.4448 52.1698V45.5898H95.0048H95.0148Z'
          fill='#222A37'
        />
        <path
          d='M93.7847 49.72C89.8347 52 83.4247 52 79.4747 49.72C75.5247 47.44 75.5247 43.74 79.4747 41.46C83.4247 39.18 89.8347 39.18 93.7847 41.46C97.7347 43.74 97.7347 47.44 93.7847 49.72Z'
          fill='#222A37'
        />
        <path
          d='M92.5447 48.9999C89.2747 50.8899 83.9747 50.8899 80.7147 48.9999C77.4447 47.1099 77.4447 44.0499 80.7147 42.1699C83.9847 40.2799 89.2847 40.2799 92.5447 42.1699C95.8147 44.0599 95.8147 47.1199 92.5447 48.9999Z'
          fill='url(#paint1_linear_57_14157)'
        />
        <path
          d='M91.7951 48.5698C88.9351 50.2198 84.3151 50.2198 81.4551 48.5698C78.5951 46.9198 78.5951 44.2498 81.4551 42.5998C84.3151 40.9498 88.9351 40.9498 91.7951 42.5998C94.6551 44.2498 94.6551 46.9198 91.7951 48.5698Z'
          fill='#222A37'
        />
        <path
          d='M83.0745 47.6398C82.4845 47.2998 82.5545 46.6998 83.2445 46.2998L87.8745 43.6298C88.5545 43.2298 89.5945 43.1898 90.1845 43.5298C90.7745 43.8698 90.7045 44.4698 90.0145 44.8698L85.3845 47.5398C84.7045 47.9398 83.6645 47.9798 83.0745 47.6398Z'
          fill='url(#paint2_linear_57_14157)'
        />
        <path
          d='M96.7449 42.6802V44.9702C96.7449 46.4802 95.7549 47.9502 93.7849 49.0902C89.8349 51.3802 83.4249 51.3802 79.4849 49.0902C77.5049 47.9502 76.5249 46.4802 76.5249 44.9702V42.6802H96.7549H96.7449Z'
          fill='#222A37'
        />
        <path
          d='M96.7446 41.6499V43.9399C96.7446 45.0999 96.1746 46.2199 95.0146 47.1999C94.6646 47.4999 94.2446 47.7899 93.7746 48.0599C93.6746 48.1199 93.5646 48.1799 93.4446 48.2299C92.7546 48.6099 92.0046 48.9099 91.2046 49.1299C90.6946 49.2999 90.1746 49.4199 89.6346 49.5099C88.9046 49.6499 88.1546 49.7299 87.3946 49.7599C86.8746 49.7899 86.3546 49.7899 85.8346 49.7599C85.0846 49.7299 84.3246 49.6599 83.5946 49.5099C83.0646 49.4199 82.5246 49.2999 82.0246 49.1299C81.2346 48.9099 80.4746 48.6099 79.7846 48.2299C79.6846 48.1799 79.5646 48.1299 79.4546 48.0599C78.9846 47.7899 78.5746 47.4999 78.2246 47.1999C77.0646 46.2099 76.4946 45.0899 76.4946 43.9399V41.6499H96.7246H96.7446Z'
          fill='url(#paint3_linear_57_14157)'
        />
        <path
          d='M79.8046 41.6499V48.2299C79.7046 48.1799 79.5846 48.1299 79.4746 48.0599C79.0046 47.7899 78.5946 47.4999 78.2446 47.1999V41.6499H79.8046Z'
          fill='#222A37'
        />
        <path
          d='M83.6144 41.6501V49.5001C83.0844 49.4101 82.5444 49.2901 82.0444 49.1201V41.6401H83.6144V41.6501Z'
          fill='#222A37'
        />
        <path d='M87.415 41.6499V49.7499C86.895 49.7799 86.375 49.7799 85.855 49.7499V41.6499H87.415Z' fill='#222A37' />
        <path
          d='M91.2248 41.6499V49.1299C90.7148 49.2999 90.1948 49.4199 89.6548 49.5099V41.6599H91.2248V41.6499Z'
          fill='#222A37'
        />
        <path
          d='M95.0148 41.6499V47.1999C94.6648 47.4999 94.2448 47.7899 93.7748 48.0599C93.6748 48.1199 93.5648 48.1799 93.4448 48.2299V41.6499H95.0048H95.0148Z'
          fill='#222A37'
        />
        <path
          d='M93.7847 45.7801C89.8347 48.0601 83.4247 48.0601 79.4747 45.7801C75.5247 43.5001 75.5247 39.8001 79.4747 37.5201C83.4247 35.2401 89.8347 35.2401 93.7847 37.5201C97.7347 39.8001 97.7347 43.5001 93.7847 45.7801Z'
          fill='#222A37'
        />
        <path
          d='M92.5447 45.06C89.2747 46.95 83.9747 46.95 80.7147 45.06C77.4447 43.17 77.4447 40.11 80.7147 38.23C83.9847 36.34 89.2847 36.34 92.5447 38.23C95.8147 40.12 95.8147 43.18 92.5447 45.06Z'
          fill='url(#paint4_linear_57_14157)'
        />
        <path
          d='M91.7951 44.6299C88.9351 46.2799 84.3151 46.2799 81.4551 44.6299C78.5951 42.9799 78.5951 40.3099 81.4551 38.6599C84.3151 37.0099 88.9351 37.0099 91.7951 38.6599C94.6551 40.3099 94.6551 42.9799 91.7951 44.6299Z'
          fill='#222A37'
        />
        <path
          d='M83.0745 43.6998C82.4845 43.3598 82.5545 42.7598 83.2445 42.3598L87.8745 39.6898C88.5545 39.2898 89.5945 39.2498 90.1845 39.5898C90.7745 39.9298 90.7045 40.5298 90.0145 40.9298L85.3845 43.5998C84.7045 43.9998 83.6645 44.0398 83.0745 43.6998Z'
          fill='url(#paint5_linear_57_14157)'
        />
        <path
          d='M96.7449 38.7402V41.0302C96.7449 42.5402 95.7549 44.0102 93.7849 45.1502C89.8349 47.4402 83.4249 47.4402 79.4849 45.1502C77.5049 44.0102 76.5249 42.5402 76.5249 41.0302V38.7402H96.7549H96.7449Z'
          fill='#222A37'
        />
        <path
          d='M96.7446 37.71V40C96.7446 41.16 96.1746 42.28 95.0146 43.26C94.6646 43.56 94.2446 43.85 93.7746 44.12C93.6746 44.18 93.5646 44.24 93.4446 44.29C92.7546 44.67 92.0046 44.97 91.2046 45.19C90.6946 45.36 90.1746 45.48 89.6346 45.57C88.9046 45.71 88.1546 45.79 87.3946 45.82C86.8746 45.85 86.3546 45.85 85.8346 45.82C85.0846 45.79 84.3246 45.72 83.5946 45.57C83.0646 45.48 82.5246 45.36 82.0246 45.19C81.2346 44.97 80.4746 44.67 79.7846 44.29C79.6846 44.24 79.5646 44.19 79.4546 44.12C78.9846 43.85 78.5746 43.56 78.2246 43.26C77.0646 42.27 76.4946 41.15 76.4946 40V37.71H96.7246H96.7446Z'
          fill='url(#paint6_linear_57_14157)'
        />
        <path
          d='M79.8046 37.71V44.29C79.7046 44.24 79.5846 44.19 79.4746 44.12C79.0046 43.85 78.5946 43.56 78.2446 43.26V37.71H79.8046Z'
          fill='#222A37'
        />
        <path
          d='M83.6144 37.7102V45.5602C83.0844 45.4702 82.5444 45.3502 82.0444 45.1802V37.7002H83.6144V37.7102Z'
          fill='#222A37'
        />
        <path d='M87.415 37.71V45.81C86.895 45.84 86.375 45.84 85.855 45.81V37.71H87.415Z' fill='#222A37' />
        <path d='M91.2248 37.71V45.19C90.7148 45.36 90.1948 45.48 89.6548 45.57V37.72H91.2248V37.71Z' fill='#222A37' />
        <path
          d='M95.0148 37.71V43.26C94.6648 43.56 94.2448 43.85 93.7748 44.12C93.6748 44.18 93.5648 44.24 93.4448 44.29V37.71H95.0048H95.0148Z'
          fill='#222A37'
        />
        <path
          d='M93.7847 41.8401C89.8347 44.1201 83.4247 44.1201 79.4747 41.8401C75.5247 39.5601 75.5247 35.8601 79.4747 33.5801C83.4247 31.3001 89.8347 31.3001 93.7847 33.5801C97.7347 35.8601 97.7347 39.5601 93.7847 41.8401Z'
          fill='#222A37'
        />
        <path
          d='M92.5447 41.1201C89.2747 43.0101 83.9747 43.0101 80.7147 41.1201C77.4447 39.2301 77.4447 36.1701 80.7147 34.2901C83.9847 32.4001 89.2847 32.4001 92.5447 34.2901C95.8147 36.1801 95.8147 39.2401 92.5447 41.1201Z'
          fill='url(#paint7_linear_57_14157)'
        />
        <path
          d='M91.7951 40.6899C88.9351 42.3399 84.3151 42.3399 81.4551 40.6899C78.5951 39.0399 78.5951 36.3699 81.4551 34.7199C84.3151 33.0699 88.9351 33.0699 91.7951 34.7199C94.6551 36.3699 94.6551 39.0399 91.7951 40.6899Z'
          fill='#222A37'
        />
        <path
          d='M83.0745 39.7599C82.4845 39.4199 82.5545 38.8199 83.2445 38.4199L87.8745 35.7499C88.5545 35.3499 89.5945 35.3099 90.1845 35.6499C90.7745 35.9899 90.7045 36.5899 90.0145 36.9899L85.3845 39.6599C84.7045 40.0599 83.6645 40.0999 83.0745 39.7599Z'
          fill='url(#paint8_linear_57_14157)'
        />
        <path
          d='M96.7449 34.7998V37.0898C96.7449 38.5998 95.7549 40.0698 93.7849 41.2098C89.8349 43.4998 83.4249 43.4998 79.4849 41.2098C77.5049 40.0698 76.5249 38.5998 76.5249 37.0898V34.7998H96.7549H96.7449Z'
          fill='#222A37'
        />
        <path
          d='M96.7446 33.7695V36.0595C96.7446 37.2195 96.1746 38.3395 95.0146 39.3195C94.6646 39.6195 94.2446 39.9095 93.7746 40.1795C93.6746 40.2395 93.5646 40.2995 93.4446 40.3495C92.7546 40.7295 92.0046 41.0295 91.2046 41.2495C90.6946 41.4195 90.1746 41.5395 89.6346 41.6295C88.9046 41.7695 88.1546 41.8495 87.3946 41.8795C86.8746 41.9095 86.3546 41.9095 85.8346 41.8795C85.0846 41.8495 84.3246 41.7795 83.5946 41.6295C83.0646 41.5395 82.5246 41.4195 82.0246 41.2495C81.2346 41.0295 80.4746 40.7295 79.7846 40.3495C79.6846 40.2995 79.5646 40.2495 79.4546 40.1795C78.9846 39.9095 78.5746 39.6195 78.2246 39.3195C77.0646 38.3295 76.4946 37.2095 76.4946 36.0595V33.7695H96.7246H96.7446Z'
          fill='url(#paint9_linear_57_14157)'
        />
        <path
          d='M79.8046 33.7695V40.3495C79.7046 40.2995 79.5846 40.2495 79.4746 40.1795C79.0046 39.9095 78.5946 39.6195 78.2446 39.3195V33.7695H79.8046Z'
          fill='#222A37'
        />
        <path
          d='M83.6144 33.7698V41.6198C83.0844 41.5298 82.5444 41.4098 82.0444 41.2398V33.7598H83.6144V33.7698Z'
          fill='#222A37'
        />
        <path d='M87.415 33.7695V41.8695C86.895 41.8995 86.375 41.8995 85.855 41.8695V33.7695H87.415Z' fill='#222A37' />
        <path
          d='M91.2248 33.7695V41.2495C90.7148 41.4195 90.1948 41.5395 89.6548 41.6295V33.7795H91.2248V33.7695Z'
          fill='#222A37'
        />
        <path
          d='M95.0148 33.7695V39.3195C94.6648 39.6195 94.2448 39.9095 93.7748 40.1795C93.6748 40.2395 93.5648 40.2995 93.4448 40.3495V33.7695H95.0048H95.0148Z'
          fill='#222A37'
        />
        <path
          d='M93.7847 37.8997C89.8347 40.1797 83.4247 40.1797 79.4747 37.8997C75.5247 35.6197 75.5247 31.9197 79.4747 29.6397C83.4247 27.3597 89.8347 27.3597 93.7847 29.6397C97.7347 31.9197 97.7347 35.6197 93.7847 37.8997Z'
          fill='#222A37'
        />
        <path
          d='M92.5447 37.1796C89.2747 39.0696 83.9747 39.0696 80.7147 37.1796C77.4447 35.2896 77.4447 32.2296 80.7147 30.3496C83.9847 28.4596 89.2847 28.4596 92.5447 30.3496C95.8147 32.2396 95.8147 35.2996 92.5447 37.1796Z'
          fill='url(#paint10_linear_57_14157)'
        />
        <path
          d='M91.7951 36.7495C88.9351 38.3995 84.3151 38.3995 81.4551 36.7495C78.5951 35.0995 78.5951 32.4295 81.4551 30.7795C84.3151 29.1295 88.9351 29.1295 91.7951 30.7795C94.6551 32.4295 94.6551 35.0995 91.7951 36.7495Z'
          fill='#222A37'
        />
        <path
          d='M83.0745 35.8195C82.4845 35.4795 82.5545 34.8795 83.2445 34.4795L87.8745 31.8095C88.5545 31.4095 89.5945 31.3695 90.1845 31.7095C90.7745 32.0495 90.7045 32.6495 90.0145 33.0495L85.3845 35.7195C84.7045 36.1195 83.6645 36.1595 83.0745 35.8195Z'
          fill='url(#paint11_linear_57_14157)'
        />
        <path
          d='M96.7449 30.8599V33.1499C96.7449 34.6599 95.7549 36.1299 93.7849 37.2699C89.8349 39.5599 83.4249 39.5599 79.4849 37.2699C77.5049 36.1299 76.5249 34.6599 76.5249 33.1499V30.8599H96.7549H96.7449Z'
          fill='#222A37'
        />
        <path
          d='M96.7446 29.8296V32.1196C96.7446 33.2796 96.1746 34.3996 95.0146 35.3796C94.6646 35.6796 94.2446 35.9696 93.7746 36.2396C93.6746 36.2996 93.5646 36.3596 93.4446 36.4096C92.7546 36.7896 92.0046 37.0896 91.2046 37.3096C90.6946 37.4796 90.1746 37.5996 89.6346 37.6896C88.9046 37.8296 88.1546 37.9096 87.3946 37.9396C86.8746 37.9696 86.3546 37.9696 85.8346 37.9396C85.0846 37.9096 84.3246 37.8396 83.5946 37.6896C83.0646 37.5996 82.5246 37.4796 82.0246 37.3096C81.2346 37.0896 80.4746 36.7896 79.7846 36.4096C79.6846 36.3596 79.5646 36.3096 79.4546 36.2396C78.9846 35.9696 78.5746 35.6796 78.2246 35.3796C77.0646 34.3896 76.4946 33.2696 76.4946 32.1196V29.8296H96.7246H96.7446Z'
          fill='url(#paint12_linear_57_14157)'
        />
        <path
          d='M79.8046 29.8296V36.4096C79.7046 36.3596 79.5846 36.3096 79.4746 36.2396C79.0046 35.9696 78.5946 35.6796 78.2446 35.3796V29.8296H79.8046Z'
          fill='#222A37'
        />
        <path
          d='M83.6144 29.8298V37.6798C83.0844 37.5898 82.5444 37.4698 82.0444 37.2998V29.8198H83.6144V29.8298Z'
          fill='#222A37'
        />
        <path d='M87.415 29.8296V37.9296C86.895 37.9596 86.375 37.9596 85.855 37.9296V29.8296H87.415Z' fill='#222A37' />
        <path
          d='M91.2248 29.8296V37.3096C90.7148 37.4796 90.1948 37.5996 89.6548 37.6896V29.8396H91.2248V29.8296Z'
          fill='#222A37'
        />
        <path
          d='M95.0148 29.8296V35.3796C94.6648 35.6796 94.2448 35.9696 93.7748 36.2396C93.6748 36.2996 93.5648 36.3596 93.4448 36.4096V29.8296H95.0048H95.0148Z'
          fill='#222A37'
        />
        <path
          d='M93.7847 33.9597C89.8347 36.2397 83.4247 36.2397 79.4747 33.9597C75.5247 31.6797 75.5247 27.9797 79.4747 25.6997C83.4247 23.4197 89.8347 23.4197 93.7847 25.6997C97.7347 27.9797 97.7347 31.6797 93.7847 33.9597Z'
          fill='#222A37'
        />
        <path
          d='M92.5447 33.2397C89.2747 35.1297 83.9747 35.1297 80.7147 33.2397C77.4447 31.3497 77.4447 28.2897 80.7147 26.4097C83.9847 24.5197 89.2847 24.5197 92.5447 26.4097C95.8147 28.2997 95.8147 31.3597 92.5447 33.2397Z'
          fill='url(#paint13_linear_57_14157)'
        />
        <path
          d='M91.7951 32.8096C88.9351 34.4596 84.3151 34.4596 81.4551 32.8096C78.5951 31.1596 78.5951 28.4896 81.4551 26.8396C84.3151 25.1896 88.9351 25.1896 91.7951 26.8396C94.6551 28.4896 94.6551 31.1596 91.7951 32.8096Z'
          fill='#222A37'
        />
        <path
          d='M83.0745 31.8795C82.4845 31.5395 82.5545 30.9395 83.2445 30.5395L87.8745 27.8695C88.5545 27.4695 89.5945 27.4295 90.1845 27.7695C90.7745 28.1095 90.7045 28.7095 90.0145 29.1095L85.3845 31.7795C84.7045 32.1795 83.6645 32.2195 83.0745 31.8795Z'
          fill='url(#paint14_linear_57_14157)'
        />
        <path
          d='M96.7449 26.9199V29.2099C96.7449 30.7199 95.7549 32.1899 93.7849 33.3299C89.8349 35.6199 83.4249 35.6199 79.4849 33.3299C77.5049 32.1899 76.5249 30.7199 76.5249 29.2099V26.9199H96.7549H96.7449Z'
          fill='#222A37'
        />
        <path
          d='M96.7446 25.8799V28.1699C96.7446 29.3299 96.1746 30.4499 95.0146 31.4299C94.6646 31.7299 94.2446 32.0199 93.7746 32.2899C93.6746 32.3499 93.5646 32.4099 93.4446 32.4599C92.7546 32.8399 92.0046 33.1399 91.2046 33.3599C90.6946 33.5299 90.1746 33.6499 89.6346 33.7399C88.9046 33.8799 88.1546 33.9599 87.3946 33.9899C86.8746 34.0199 86.3546 34.0199 85.8346 33.9899C85.0846 33.9599 84.3246 33.8899 83.5946 33.7399C83.0646 33.6499 82.5246 33.5299 82.0246 33.3599C81.2346 33.1399 80.4746 32.8399 79.7846 32.4599C79.6846 32.4099 79.5646 32.3599 79.4546 32.2899C78.9846 32.0199 78.5746 31.7299 78.2246 31.4299C77.0646 30.4399 76.4946 29.3199 76.4946 28.1699V25.8799H96.7246H96.7446Z'
          fill='url(#paint15_linear_57_14157)'
        />
        <path
          d='M79.8046 25.8794V32.4594C79.7046 32.4094 79.5846 32.3594 79.4746 32.2894C79.0046 32.0194 78.5946 31.7294 78.2446 31.4294V25.8794H79.8046Z'
          fill='#222A37'
        />
        <path
          d='M83.6144 25.8796V33.7296C83.0844 33.6396 82.5444 33.5196 82.0444 33.3496V25.8696H83.6144V25.8796Z'
          fill='#222A37'
        />
        <path d='M87.415 25.8794V33.9794C86.895 34.0094 86.375 34.0094 85.855 33.9794V25.8794H87.415Z' fill='#222A37' />
        <path
          d='M91.2248 25.8794V33.3594C90.7148 33.5294 90.1948 33.6494 89.6548 33.7394V25.8894H91.2248V25.8794Z'
          fill='#222A37'
        />
        <path
          d='M95.0148 25.8794V31.4294C94.6648 31.7294 94.2448 32.0194 93.7748 32.2894C93.6748 32.3494 93.5648 32.4094 93.4448 32.4594V25.8794H95.0048H95.0148Z'
          fill='#222A37'
        />
        <path
          d='M93.7847 30.0198C89.8347 32.2998 83.4247 32.2998 79.4747 30.0198C75.5247 27.7398 75.5247 24.0398 79.4747 21.7598C83.4247 19.4798 89.8347 19.4798 93.7847 21.7598C97.7347 24.0398 97.7347 27.7398 93.7847 30.0198Z'
          fill='#222A37'
        />
        <path
          d='M92.5447 29.2997C89.2747 31.1897 83.9747 31.1897 80.7147 29.2997C77.4447 27.4097 77.4447 24.3497 80.7147 22.4697C83.9847 20.5797 89.2847 20.5797 92.5447 22.4697C95.8147 24.3597 95.8147 27.4197 92.5447 29.2997Z'
          fill='url(#paint16_linear_57_14157)'
        />
        <path
          d='M91.7951 28.8696C88.9351 30.5196 84.3151 30.5196 81.4551 28.8696C78.5951 27.2196 78.5951 24.5496 81.4551 22.8996C84.3151 21.2496 88.9351 21.2496 91.7951 22.8996C94.6551 24.5496 94.6551 27.2196 91.7951 28.8696Z'
          fill='#222A37'
        />
        <path
          d='M83.0745 27.9396C82.4845 27.5996 82.5545 26.9996 83.2445 26.5996L87.8745 23.9296C88.5545 23.5296 89.5945 23.4896 90.1845 23.8296C90.7745 24.1696 90.7045 24.7696 90.0145 25.1696L85.3845 27.8396C84.7045 28.2396 83.6645 28.2796 83.0745 27.9396Z'
          fill='url(#paint17_linear_57_14157)'
        />
        <path
          d='M96.7449 22.98V25.27C96.7449 26.78 95.7549 28.25 93.7849 29.39C89.8349 31.68 83.4249 31.68 79.4849 29.39C77.5049 28.25 76.5249 26.78 76.5249 25.27V22.98H96.7549H96.7449Z'
          fill='#222A37'
        />
        <path
          d='M96.7446 21.9399V24.2299C96.7446 25.3899 96.1746 26.5099 95.0146 27.4899C94.6646 27.7899 94.2446 28.0799 93.7746 28.3499C93.6746 28.4199 93.5646 28.4699 93.4446 28.5199C92.7546 28.8999 92.0046 29.1999 91.2046 29.4199C90.6946 29.5899 90.1746 29.7099 89.6346 29.7999C88.9046 29.9399 88.1546 30.0199 87.3946 30.0499C86.8746 30.0799 86.3546 30.0799 85.8346 30.0499C85.0846 30.0199 84.3246 29.9499 83.5946 29.7999C83.0646 29.7099 82.5246 29.5899 82.0246 29.4199C81.2346 29.1999 80.4746 28.8999 79.7846 28.5199C79.6846 28.4699 79.5646 28.4199 79.4546 28.3499C78.9846 28.0799 78.5746 27.7899 78.2246 27.4899C77.0646 26.4999 76.4946 25.3799 76.4946 24.2299V21.9399H96.7246H96.7446Z'
          fill='url(#paint18_linear_57_14157)'
        />
        <path
          d='M79.8046 21.9395V28.5195C79.7046 28.4695 79.5846 28.4195 79.4746 28.3495C79.0046 28.0795 78.5946 27.7895 78.2446 27.4895V21.9395H79.8046Z'
          fill='#222A37'
        />
        <path
          d='M83.6144 21.9397V29.7897C83.0844 29.6997 82.5444 29.5797 82.0444 29.4097V21.9297H83.6144V21.9397Z'
          fill='#222A37'
        />
        <path d='M87.415 21.9395V30.0395C86.895 30.0695 86.375 30.0695 85.855 30.0395V21.9395H87.415Z' fill='#222A37' />
        <path
          d='M91.2248 21.9395V29.4195C90.7148 29.5895 90.1948 29.7095 89.6548 29.7995V21.9495H91.2248V21.9395Z'
          fill='#222A37'
        />
        <path
          d='M95.0148 21.9395V27.4895C94.6648 27.7895 94.2448 28.0795 93.7748 28.3495C93.6748 28.4195 93.5648 28.4695 93.4448 28.5195V21.9395H95.0048H95.0148Z'
          fill='#222A37'
        />
        <path
          d='M93.7847 26.0799C89.8347 28.3599 83.4247 28.3599 79.4747 26.0799C75.5247 23.7999 75.5247 20.0999 79.4747 17.8199C83.4247 15.5399 89.8347 15.5399 93.7847 17.8199C97.7347 20.0999 97.7347 23.7999 93.7847 26.0799Z'
          fill='#222A37'
        />
        <path
          d='M92.5447 25.36C89.2747 27.25 83.9747 27.25 80.7147 25.36C77.4447 23.47 77.4447 20.41 80.7147 18.53C83.9847 16.65 89.2847 16.64 92.5447 18.53C95.8147 20.42 95.8147 23.48 92.5447 25.36Z'
          fill='url(#paint19_linear_57_14157)'
        />
        <path
          d='M91.7951 24.9297C88.9351 26.5797 84.3151 26.5797 81.4551 24.9297C78.5951 23.2797 78.5951 20.6097 81.4551 18.9597C84.3151 17.3097 88.9351 17.3097 91.7951 18.9597C94.6551 20.6097 94.6551 23.2797 91.7951 24.9297Z'
          fill='#222A37'
        />
        <path
          d='M83.0745 23.9996C82.4845 23.6596 82.5545 23.0596 83.2445 22.6596L87.8745 19.9896C88.5545 19.5896 89.5945 19.5496 90.1845 19.8896C90.7745 20.2296 90.7045 20.8296 90.0145 21.2296L85.3845 23.8996C84.7045 24.2996 83.6645 24.3396 83.0745 23.9996Z'
          fill='url(#paint20_linear_57_14157)'
        />
        <path
          d='M96.7449 19.04V21.33C96.7449 22.84 95.7549 24.31 93.7849 25.45C89.8349 27.74 83.4249 27.74 79.4849 25.45C77.5049 24.31 76.5249 22.84 76.5249 21.33V19.04H96.7549H96.7449Z'
          fill='#222A37'
        />
        <path
          d='M96.7446 18V20.29C96.7446 21.45 96.1746 22.57 95.0146 23.55C94.6646 23.85 94.2446 24.14 93.7746 24.41C93.6746 24.48 93.5646 24.53 93.4446 24.58C92.7546 24.96 92.0046 25.26 91.2046 25.48C90.6946 25.65 90.1746 25.77 89.6346 25.86C88.9046 26 88.1546 26.08 87.3946 26.11C86.8746 26.14 86.3546 26.14 85.8346 26.11C85.0846 26.08 84.3246 26.01 83.5946 25.86C83.0646 25.77 82.5246 25.65 82.0246 25.48C81.2346 25.26 80.4746 24.96 79.7846 24.58C79.6846 24.53 79.5646 24.48 79.4546 24.41C78.9846 24.14 78.5746 23.85 78.2246 23.55C77.0646 22.56 76.4946 21.44 76.4946 20.29V18H96.7246H96.7446Z'
          fill='url(#paint21_linear_57_14157)'
        />
        <path
          d='M79.8046 17.9995V24.5795C79.7046 24.5295 79.5846 24.4795 79.4746 24.4095C79.0046 24.1395 78.5946 23.8495 78.2446 23.5495V17.9995H79.8046Z'
          fill='#222A37'
        />
        <path
          d='M83.6144 17.9997V25.8497C83.0844 25.7597 82.5444 25.6397 82.0444 25.4697V17.9897H83.6144V17.9997Z'
          fill='#222A37'
        />
        <path d='M87.415 17.9995V26.0995C86.895 26.1295 86.375 26.1295 85.855 26.0995V17.9995H87.415Z' fill='#222A37' />
        <path
          d='M91.2248 17.9995V25.4795C90.7148 25.6495 90.1948 25.7695 89.6548 25.8595V18.0095H91.2248V17.9995Z'
          fill='#222A37'
        />
        <path
          d='M95.0148 17.9995V23.5495C94.6648 23.8495 94.2448 24.1395 93.7748 24.4095C93.6748 24.4795 93.5648 24.5295 93.4448 24.5795V17.9995H95.0048H95.0148Z'
          fill='#222A37'
        />
        <path
          d='M93.7847 22.1399C89.8347 24.4199 83.4247 24.4199 79.4747 22.1399C75.5247 19.8599 75.5247 16.1599 79.4747 13.8799C83.4247 11.5999 89.8347 11.5999 93.7847 13.8799C97.7347 16.1599 97.7347 19.8599 93.7847 22.1399Z'
          fill='#222A37'
        />
        <path
          d='M92.5447 21.4199C89.2747 23.3099 83.9747 23.3099 80.7147 21.4199C77.4447 19.5299 77.4447 16.4699 80.7147 14.5899C83.9847 12.6999 89.2847 12.6999 92.5447 14.5899C95.8147 16.4799 95.8147 19.5399 92.5447 21.4199Z'
          fill='url(#paint22_linear_57_14157)'
        />
        <path
          d='M91.7951 20.9897C88.9351 22.6397 84.3151 22.6397 81.4551 20.9897C78.5951 19.3397 78.5951 16.6697 81.4551 15.0197C84.3151 13.3697 88.9351 13.3697 91.7951 15.0197C94.6551 16.6697 94.6551 19.3397 91.7951 20.9897Z'
          fill='#222A37'
        />
        <path
          d='M83.0745 20.0597C82.4845 19.7197 82.5545 19.1197 83.2445 18.7197L87.8745 16.0497C88.5545 15.6497 89.5945 15.6097 90.1845 15.9497C90.7745 16.2897 90.7045 16.8897 90.0145 17.2897L85.3845 19.9597C84.7045 20.3597 83.6645 20.3997 83.0745 20.0597Z'
          fill='url(#paint23_linear_57_14157)'
        />
        <path
          d='M113.695 56.4697V58.7597C113.695 60.2697 112.705 61.7397 110.735 62.8797C106.785 65.1697 100.375 65.1697 96.4351 62.8797C94.4551 61.7397 93.4751 60.2697 93.4751 58.7597V56.4697H113.705H113.695Z'
          fill='#222A37'
        />
        <path
          d='M113.695 55.4395V57.7295C113.695 58.8895 113.125 60.0095 111.965 60.9895C111.615 61.2895 111.195 61.5795 110.725 61.8495C110.625 61.9095 110.515 61.9695 110.395 62.0195C109.705 62.3995 108.955 62.6995 108.155 62.9195C107.645 63.0895 107.125 63.2095 106.585 63.2995C105.855 63.4395 105.105 63.5195 104.345 63.5495C103.825 63.5795 103.305 63.5795 102.785 63.5495C102.035 63.5195 101.275 63.4495 100.545 63.2995C100.015 63.2095 99.4751 63.0895 98.9751 62.9195C98.1851 62.6995 97.4251 62.3995 96.7351 62.0195C96.6351 61.9695 96.5151 61.9195 96.4051 61.8495C95.9351 61.5795 95.5251 61.2895 95.1651 60.9895C94.0051 59.9995 93.4351 58.8795 93.4351 57.7295V55.4395H113.665H113.695Z'
          fill='url(#paint24_linear_57_14157)'
        />
        <path
          d='M96.7551 55.4395V62.0195C96.6551 61.9695 96.5351 61.9195 96.4251 61.8495C95.9551 61.5795 95.5451 61.2895 95.1851 60.9895V55.4395H96.7451H96.7551Z'
          fill='#222A37'
        />
        <path
          d='M100.565 55.4397V63.2897C100.035 63.1997 99.4946 63.0797 98.9946 62.9097V55.4297H100.565V55.4397Z'
          fill='#222A37'
        />
        <path
          d='M104.355 55.4395V63.5395C103.835 63.5695 103.315 63.5695 102.795 63.5395V55.4395H104.355Z'
          fill='#222A37'
        />
        <path
          d='M108.165 55.4395V62.9195C107.655 63.0895 107.135 63.2095 106.595 63.2995V55.4495H108.165V55.4395Z'
          fill='#222A37'
        />
        <path
          d='M111.965 55.4395V60.9895C111.615 61.2895 111.195 61.5795 110.725 61.8495C110.625 61.9095 110.515 61.9695 110.395 62.0195V55.4395H111.955H111.965Z'
          fill='#222A37'
        />
        <path
          d='M110.725 59.5696C106.775 61.8496 100.365 61.8496 96.4151 59.5696C92.4651 57.2896 92.4651 53.5896 96.4151 51.3096C100.365 49.0296 106.775 49.0296 110.725 51.3096C114.675 53.5896 114.675 57.2896 110.725 59.5696Z'
          fill='#222A37'
        />
        <path
          d='M109.495 58.8598C106.225 60.7498 100.925 60.7498 97.6649 58.8598C94.3949 56.9698 94.3949 53.9098 97.6649 52.0298C100.935 50.1398 106.235 50.1398 109.495 52.0298C112.755 53.9198 112.765 56.9798 109.495 58.8598Z'
          fill='url(#paint25_linear_57_14157)'
        />
        <path
          d='M108.745 58.4194C105.885 60.0694 101.265 60.0694 98.4053 58.4194C95.5453 56.7694 95.5453 54.0994 98.4053 52.4494C101.265 50.7994 105.885 50.7994 108.745 52.4494C111.605 54.0994 111.605 56.7694 108.745 58.4194Z'
          fill='#222A37'
        />
        <path
          d='M100.025 57.4894C99.4347 57.1494 99.5047 56.5494 100.195 56.1494L104.825 53.4794C105.505 53.0794 106.545 53.0394 107.135 53.3794C107.725 53.7194 107.655 54.3194 106.965 54.7194L102.335 57.3894C101.655 57.7894 100.615 57.8294 100.025 57.4894Z'
          fill='url(#paint26_linear_57_14157)'
        />
        <path
          d='M113.695 52.5298V54.8198C113.695 56.3298 112.705 57.7998 110.735 58.9398C106.785 61.2298 100.375 61.2298 96.4351 58.9398C94.4551 57.7998 93.4751 56.3298 93.4751 54.8198V52.5298H113.705H113.695Z'
          fill='#222A37'
        />
        <path
          d='M113.695 51.4995V53.7895C113.695 54.9495 113.125 56.0695 111.965 57.0495C111.615 57.3495 111.195 57.6395 110.725 57.9095C110.625 57.9695 110.515 58.0295 110.395 58.0795C109.705 58.4595 108.955 58.7595 108.155 58.9795C107.645 59.1495 107.125 59.2695 106.585 59.3595C105.855 59.4995 105.105 59.5795 104.345 59.6095C103.825 59.6395 103.305 59.6395 102.785 59.6095C102.035 59.5795 101.275 59.5095 100.545 59.3595C100.015 59.2695 99.4751 59.1495 98.9751 58.9795C98.1851 58.7595 97.4251 58.4595 96.7351 58.0795C96.6351 58.0295 96.5151 57.9795 96.4051 57.9095C95.9351 57.6395 95.5251 57.3495 95.1651 57.0495C94.0051 56.0595 93.4351 54.9395 93.4351 53.7895V51.4995H113.665H113.695Z'
          fill='url(#paint27_linear_57_14157)'
        />
        <path
          d='M96.7551 51.4995V58.0795C96.6551 58.0295 96.5351 57.9795 96.4251 57.9095C95.9551 57.6395 95.5451 57.3495 95.1851 57.0495V51.4995H96.7451H96.7551Z'
          fill='#222A37'
        />
        <path
          d='M100.565 51.4997V59.3497C100.035 59.2597 99.4946 59.1397 98.9946 58.9697V51.4897H100.565V51.4997Z'
          fill='#222A37'
        />
        <path
          d='M104.355 51.4995V59.5995C103.835 59.6295 103.315 59.6295 102.795 59.5995V51.4995H104.355Z'
          fill='#222A37'
        />
        <path
          d='M108.165 51.4995V58.9795C107.655 59.1495 107.135 59.2695 106.595 59.3595V51.5095H108.165V51.4995Z'
          fill='#222A37'
        />
        <path
          d='M111.965 51.4995V57.0495C111.615 57.3495 111.195 57.6395 110.725 57.9095C110.625 57.9695 110.515 58.0295 110.395 58.0795V51.4995H111.955H111.965Z'
          fill='#222A37'
        />
        <path
          d='M110.725 55.6297C106.775 57.9097 100.365 57.9097 96.4151 55.6297C92.4651 53.3497 92.4651 49.6497 96.4151 47.3697C100.365 45.0897 106.775 45.0897 110.725 47.3697C114.675 49.6497 114.675 53.3497 110.725 55.6297Z'
          fill='#222A37'
        />
        <path
          d='M109.495 54.9199C106.225 56.8099 100.925 56.8099 97.6651 54.9199C94.4051 53.0299 94.3951 49.9699 97.6651 48.0899C100.935 46.1999 106.235 46.1999 109.495 48.0899C112.765 49.9799 112.765 53.0399 109.495 54.9199Z'
          fill='url(#paint28_linear_57_14157)'
        />
        <path
          d='M108.745 54.4795C105.885 56.1295 101.265 56.1295 98.4053 54.4795C95.5453 52.8295 95.5453 50.1595 98.4053 48.5095C101.265 46.8595 105.885 46.8595 108.745 48.5095C111.605 50.1595 111.605 52.8295 108.745 54.4795Z'
          fill='#222A37'
        />
        <path
          d='M100.025 53.5495C99.4347 53.2095 99.5047 52.6095 100.195 52.2095L104.825 49.5395C105.505 49.1395 106.545 49.0995 107.135 49.4395C107.725 49.7795 107.655 50.3795 106.965 50.7795L102.335 53.4495C101.655 53.8495 100.615 53.8895 100.025 53.5495Z'
          fill='url(#paint29_linear_57_14157)'
        />
        <path
          d='M113.695 48.5898V50.8798C113.695 52.3898 112.705 53.8598 110.735 54.9998C106.785 57.2898 100.375 57.2898 96.4351 54.9998C94.4551 53.8598 93.4751 52.3898 93.4751 50.8798V48.5898H113.705H113.695Z'
          fill='#222A37'
        />
        <path
          d='M113.695 47.5596V49.8496C113.695 51.0096 113.125 52.1296 111.965 53.1096C111.615 53.4096 111.195 53.6996 110.725 53.9696C110.625 54.0296 110.515 54.0896 110.395 54.1396C109.705 54.5196 108.955 54.8196 108.155 55.0396C107.645 55.2096 107.125 55.3296 106.585 55.4196C105.855 55.5596 105.105 55.6396 104.345 55.6696C103.825 55.6996 103.305 55.6996 102.785 55.6696C102.035 55.6396 101.275 55.5696 100.545 55.4196C100.015 55.3296 99.4751 55.2096 98.9751 55.0396C98.1851 54.8196 97.4251 54.5196 96.7351 54.1396C96.6351 54.0896 96.5151 54.0396 96.4051 53.9696C95.9351 53.6996 95.5251 53.4096 95.1651 53.1096C94.0051 52.1196 93.4351 50.9996 93.4351 49.8496V47.5596H113.665H113.695Z'
          fill='url(#paint30_linear_57_14157)'
        />
        <path
          d='M96.7551 47.5596V54.1396C96.6551 54.0896 96.5351 54.0396 96.4251 53.9696C95.9551 53.6996 95.5451 53.4096 95.1851 53.1096V47.5596H96.7451H96.7551Z'
          fill='#222A37'
        />
        <path
          d='M100.565 47.5598V55.4098C100.035 55.3198 99.4946 55.1998 98.9946 55.0298V47.5498H100.565V47.5598Z'
          fill='#222A37'
        />
        <path
          d='M104.355 47.5596V55.6596C103.835 55.6896 103.315 55.6896 102.795 55.6596V47.5596H104.355Z'
          fill='#222A37'
        />
        <path
          d='M108.165 47.5596V55.0396C107.655 55.2096 107.135 55.3296 106.595 55.4196V47.5696H108.165V47.5596Z'
          fill='#222A37'
        />
        <path
          d='M111.965 47.5596V53.1096C111.615 53.4096 111.195 53.6996 110.725 53.9696C110.625 54.0296 110.515 54.0896 110.395 54.1396V47.5596H111.955H111.965Z'
          fill='#222A37'
        />
        <path
          d='M110.725 51.6897C106.775 53.9697 100.365 53.9697 96.4151 51.6897C92.4651 49.4097 92.4651 45.7097 96.4151 43.4297C100.365 41.1497 106.775 41.1497 110.725 43.4297C114.675 45.7097 114.675 49.4097 110.725 51.6897Z'
          fill='#222A37'
        />
        <path
          d='M109.495 50.9697C106.225 52.8597 100.925 52.8597 97.6651 50.9697C94.4051 49.0797 94.3951 46.0197 97.6651 44.1397C100.935 42.2497 106.235 42.2497 109.495 44.1397C112.755 46.0297 112.765 49.0897 109.495 50.9697Z'
          fill='url(#paint31_linear_57_14157)'
        />
        <path
          d='M108.745 50.5395C105.885 52.1895 101.265 52.1895 98.4053 50.5395C95.5453 48.8895 95.5453 46.2195 98.4053 44.5695C101.265 42.9195 105.885 42.9195 108.745 44.5695C111.605 46.2195 111.605 48.8895 108.745 50.5395Z'
          fill='#222A37'
        />
        <path
          d='M100.025 49.6095C99.4347 49.2695 99.5047 48.6695 100.195 48.2695L104.825 45.5995C105.505 45.1995 106.545 45.1595 107.135 45.4995C107.725 45.8395 107.655 46.4395 106.965 46.8395L102.335 49.5095C101.655 49.9095 100.615 49.9495 100.025 49.6095Z'
          fill='url(#paint32_linear_57_14157)'
        />
        <path
          d='M113.695 44.6499V46.9399C113.695 48.4499 112.705 49.9199 110.735 51.0599C106.785 53.3499 100.375 53.3499 96.4351 51.0599C94.4551 49.9199 93.4751 48.4499 93.4751 46.9399V44.6499H113.705H113.695Z'
          fill='#222A37'
        />
        <path
          d='M113.695 43.6196V45.9096C113.695 47.0696 113.125 48.1896 111.965 49.1696C111.615 49.4696 111.195 49.7596 110.725 50.0296C110.625 50.0896 110.515 50.1496 110.395 50.1996C109.705 50.5796 108.955 50.8796 108.155 51.0996C107.645 51.2696 107.125 51.3896 106.585 51.4796C105.855 51.6196 105.105 51.6996 104.345 51.7296C103.825 51.7596 103.305 51.7596 102.785 51.7296C102.035 51.6996 101.275 51.6296 100.545 51.4796C100.015 51.3896 99.4751 51.2696 98.9751 51.0996C98.1851 50.8796 97.4251 50.5796 96.7351 50.1996C96.6351 50.1496 96.5151 50.0996 96.4051 50.0296C95.9351 49.7596 95.5251 49.4696 95.1651 49.1696C94.0051 48.1796 93.4351 47.0596 93.4351 45.9096V43.6196H113.665H113.695Z'
          fill='url(#paint33_linear_57_14157)'
        />
        <path
          d='M96.7551 43.6196V50.1996C96.6551 50.1496 96.5351 50.0996 96.4251 50.0296C95.9551 49.7596 95.5451 49.4696 95.1851 49.1696V43.6196H96.7451H96.7551Z'
          fill='#222A37'
        />
        <path
          d='M100.565 43.6199V51.4699C100.035 51.3799 99.4946 51.2599 98.9946 51.0899V43.6099H100.565V43.6199Z'
          fill='#222A37'
        />
        <path
          d='M104.355 43.6196V51.7196C103.835 51.7496 103.315 51.7496 102.795 51.7196V43.6196H104.355Z'
          fill='#222A37'
        />
        <path
          d='M108.165 43.6196V51.0996C107.655 51.2696 107.135 51.3896 106.595 51.4796V43.6296H108.165V43.6196Z'
          fill='#222A37'
        />
        <path
          d='M111.965 43.6196V49.1696C111.615 49.4696 111.195 49.7596 110.725 50.0296C110.625 50.0896 110.515 50.1496 110.395 50.1996V43.6196H111.955H111.965Z'
          fill='#222A37'
        />
        <path
          d='M110.725 47.7498C106.775 50.0298 100.365 50.0298 96.4151 47.7498C92.4651 45.4698 92.4651 41.7698 96.4151 39.4898C100.365 37.2098 106.775 37.2098 110.725 39.4898C114.675 41.7698 114.675 45.4698 110.725 47.7498Z'
          fill='#222A37'
        />
        <path
          d='M109.495 47.0297C106.225 48.9197 100.925 48.9197 97.6651 47.0297C94.4051 45.1397 94.3951 42.0797 97.6651 40.1997C100.935 38.3097 106.235 38.3097 109.495 40.1997C112.755 42.0897 112.765 45.1497 109.495 47.0297Z'
          fill='url(#paint34_linear_57_14157)'
        />
        <path
          d='M108.745 46.5996C105.885 48.2496 101.265 48.2496 98.4053 46.5996C95.5453 44.9496 95.5453 42.2796 98.4053 40.6296C101.265 38.9796 105.885 38.9796 108.745 40.6296C111.605 42.2796 111.605 44.9496 108.745 46.5996Z'
          fill='#222A37'
        />
        <path
          d='M100.025 45.6696C99.4347 45.3296 99.5047 44.7296 100.195 44.3296L104.825 41.6596C105.505 41.2596 106.545 41.2196 107.135 41.5596C107.725 41.8996 107.655 42.4996 106.965 42.8996L102.335 45.5696C101.655 45.9696 100.615 46.0096 100.025 45.6696Z'
          fill='url(#paint35_linear_57_14157)'
        />
        <path
          d='M93.7351 58.9897V61.2797C93.7351 62.7897 92.7451 64.2597 90.7751 65.3997C86.8251 67.6897 80.4151 67.6897 76.4751 65.3997C74.4951 64.2597 73.5151 62.7897 73.5151 61.2797V58.9897H93.7451H93.7351Z'
          fill='#222A37'
        />
        <path
          d='M93.7349 57.9595V60.2495C93.7349 61.4095 93.1649 62.5295 92.0049 63.5095C91.6549 63.8095 91.2349 64.0995 90.7649 64.3695C90.6649 64.4295 90.5549 64.4895 90.4349 64.5395C89.7449 64.9195 88.9949 65.2195 88.1949 65.4395C87.6849 65.6095 87.1649 65.7295 86.6249 65.8195C85.8949 65.9595 85.1449 66.0395 84.3849 66.0695C83.8649 66.0995 83.3449 66.0995 82.8249 66.0695C82.0749 66.0395 81.3149 65.9695 80.5849 65.8195C80.0549 65.7295 79.5149 65.6095 79.0149 65.4395C78.2249 65.2195 77.4649 64.9195 76.7749 64.5395C76.6749 64.4895 76.5549 64.4395 76.4449 64.3695C75.9749 64.0995 75.5649 63.8095 75.2149 63.5095C74.0549 62.5195 73.4849 61.3995 73.4849 60.2495V57.9595H93.7149H93.7349Z'
          fill='url(#paint36_linear_57_14157)'
        />
        <path
          d='M76.7949 57.9595V64.5395C76.6949 64.4895 76.5749 64.4395 76.4649 64.3695C75.9949 64.0995 75.5849 63.8095 75.2349 63.5095V57.9595H76.7949Z'
          fill='#222A37'
        />
        <path
          d='M80.6047 57.9597V65.8097C80.0747 65.7197 79.5347 65.5997 79.0347 65.4297V57.9497H80.6047V57.9597Z'
          fill='#222A37'
        />
        <path
          d='M84.3945 57.9595V66.0595C83.8745 66.0895 83.3545 66.0895 82.8345 66.0595V57.9595H84.3945Z'
          fill='#222A37'
        />
        <path
          d='M88.2053 57.9595V65.4395C87.6953 65.6095 87.1753 65.7295 86.6353 65.8195V57.9695H88.2053V57.9595Z'
          fill='#222A37'
        />
        <path
          d='M92.0051 57.9595V63.5095C91.6551 63.8095 91.2351 64.0995 90.7651 64.3695C90.6651 64.4295 90.5551 64.4895 90.4351 64.5395V57.9595H91.9951H92.0051Z'
          fill='#222A37'
        />
        <path
          d='M90.7749 62.0896C86.8249 64.3696 80.4149 64.3696 76.4649 62.0896C72.5149 59.8096 72.5149 56.1096 76.4649 53.8296C80.4149 51.5496 86.8249 51.5496 90.7749 53.8296C94.7249 56.1096 94.7249 59.8096 90.7749 62.0896Z'
          fill='#222A37'
        />
        <path
          d='M89.5349 61.3696C86.2649 63.2596 80.9649 63.2596 77.7049 61.3696C74.4349 59.4796 74.4349 56.4196 77.7049 54.5396C80.9749 52.6496 86.2749 52.6496 89.5349 54.5396C92.8049 56.4296 92.8049 59.4896 89.5349 61.3696Z'
          fill='url(#paint37_linear_57_14157)'
        />
        <path
          d='M88.7853 60.9394C85.9253 62.5894 81.3053 62.5894 78.4453 60.9394C75.5853 59.2894 75.5853 56.6194 78.4453 54.9694C81.3053 53.3194 85.9253 53.3194 88.7853 54.9694C91.6453 56.6194 91.6453 59.2894 88.7853 60.9394Z'
          fill='#222A37'
        />
        <path
          d='M80.0647 60.0094C79.4747 59.6694 79.5447 59.0694 80.2347 58.6694L84.8647 55.9994C85.5447 55.5994 86.5847 55.5594 87.1747 55.8994C87.7647 56.2394 87.6947 56.8394 87.0047 57.2394L82.3747 59.9094C81.6947 60.3094 80.6547 60.3494 80.0647 60.0094Z'
          fill='url(#paint38_linear_57_14157)'
        />
        <path
          d='M93.7351 55.0498V57.3398C93.7351 58.8498 92.7451 60.3198 90.7751 61.4598C86.8251 63.7498 80.4151 63.7498 76.4751 61.4598C74.4951 60.3198 73.5151 58.8498 73.5151 57.3398V55.0498H93.7451H93.7351Z'
          fill='#222A37'
        />
        <path
          d='M93.7349 54.0195V56.3095C93.7349 57.4695 93.1649 58.5895 92.0049 59.5695C91.6549 59.8695 91.2349 60.1595 90.7649 60.4295C90.6649 60.4895 90.5549 60.5495 90.4349 60.5995C89.7449 60.9795 88.9949 61.2795 88.1949 61.4995C87.6849 61.6695 87.1649 61.7895 86.6249 61.8795C85.8949 62.0195 85.1449 62.0995 84.3849 62.1295C83.8649 62.1595 83.3449 62.1595 82.8249 62.1295C82.0749 62.0995 81.3149 62.0295 80.5849 61.8795C80.0549 61.7895 79.5149 61.6695 79.0149 61.4995C78.2249 61.2795 77.4649 60.9795 76.7749 60.5995C76.6749 60.5495 76.5549 60.4995 76.4449 60.4295C75.9749 60.1595 75.5649 59.8695 75.2149 59.5695C74.0549 58.5795 73.4849 57.4595 73.4849 56.3095V54.0195H93.7149H93.7349Z'
          fill='url(#paint39_linear_57_14157)'
        />
        <path
          d='M76.7949 54.0195V60.5995C76.6949 60.5495 76.5749 60.4995 76.4649 60.4295C75.9949 60.1595 75.5849 59.8695 75.2349 59.5695V54.0195H76.7949Z'
          fill='#222A37'
        />
        <path
          d='M80.6047 54.0198V61.8698C80.0747 61.7798 79.5347 61.6598 79.0347 61.4898V54.0098H80.6047V54.0198Z'
          fill='#222A37'
        />
        <path
          d='M84.3945 54.0195V62.1195C83.8745 62.1495 83.3545 62.1495 82.8345 62.1195V54.0195H84.3945Z'
          fill='#222A37'
        />
        <path
          d='M88.2053 54.0195V61.4995C87.6953 61.6695 87.1753 61.7895 86.6353 61.8795V54.0295H88.2053V54.0195Z'
          fill='#222A37'
        />
        <path
          d='M92.0051 54.0195V59.5695C91.6551 59.8695 91.2351 60.1595 90.7651 60.4295C90.6651 60.4895 90.5551 60.5495 90.4351 60.5995V54.0195H91.9951H92.0051Z'
          fill='#222A37'
        />
        <path
          d='M90.7749 58.1497C86.8249 60.4297 80.4149 60.4297 76.4649 58.1497C72.5149 55.8697 72.5149 52.1697 76.4649 49.8897C80.4149 47.6097 86.8249 47.6097 90.7749 49.8897C94.7249 52.1697 94.7249 55.8697 90.7749 58.1497Z'
          fill='#222A37'
        />
        <path
          d='M89.5349 57.4296C86.2649 59.3196 80.9649 59.3196 77.7049 57.4296C74.4349 55.5396 74.4349 52.4796 77.7049 50.5996C80.9749 48.7096 86.2749 48.7096 89.5349 50.5996C92.8049 52.4896 92.8049 55.5496 89.5349 57.4296Z'
          fill='url(#paint40_linear_57_14157)'
        />
        <path
          d='M88.7853 56.9995C85.9253 58.6495 81.3053 58.6495 78.4453 56.9995C75.5853 55.3495 75.5853 52.6795 78.4453 51.0295C81.3053 49.3795 85.9253 49.3795 88.7853 51.0295C91.6453 52.6795 91.6453 55.3495 88.7853 56.9995Z'
          fill='#222A37'
        />
        <path
          d='M80.0647 56.0695C79.4747 55.7295 79.5447 55.1295 80.2347 54.7295L84.8647 52.0595C85.5447 51.6595 86.5847 51.6195 87.1747 51.9595C87.7647 52.2995 87.6947 52.8995 87.0047 53.2995L82.3747 55.9695C81.6947 56.3695 80.6547 56.4095 80.0647 56.0695Z'
          fill='url(#paint41_linear_57_14157)'
        />
        <path d='M93.5958 84.0999L66.9458 99.4899V89.4999L93.5958 74.1099V84.0999Z' fill='#222A37' />
        <path d='M93.5958 76.8197L66.9458 92.2097V90.8597L93.5958 75.4697V76.8197Z' fill='#222A37' />
        <path d='M93.5958 79.7797L66.9458 95.1697V93.8197L93.5958 78.4297V79.7797Z' fill='#222A37' />
        <path d='M93.5958 82.7299L66.9458 98.1199V96.7699L93.5958 81.3799V82.7299Z' fill='#222A37' />
        <path d='M66.9454 99.49L5.08545 63.77V53.79L66.9454 89.5V99.49Z' fill='#222A37' />
        <path d='M66.9454 92.2099L5.08545 56.4999V55.1499L66.9454 90.8599V92.2099Z' fill='#485262' />
        <path d='M66.9454 95.1701L5.08545 59.4501V58.1001L66.9454 93.8201V95.1701Z' fill='#485262' />
        <path d='M66.9454 98.1201L5.08545 62.4101V61.0601L66.9454 96.7701V98.1201Z' fill='#485262' />
        <path d='M40.5958 84.27L31.4458 78.99V69L40.5958 74.28V84.27Z' fill='#151A26' />
        <path d='M5.08545 53.7899L31.7454 38.3999L93.5955 74.1099L66.9454 89.4999L5.08545 53.7899Z' fill='#485262' />
        <path d='M9.87549 54.0497L32.1855 41.1597L88.8155 73.8597L66.4955 86.7397L9.87549 54.0497Z' fill='#00CDAC' />
        <path d='M13.0659 54.1998L32.4559 43.0098L85.6259 73.6998L66.2259 84.8998L13.0659 54.1998Z' fill='#485262' />
        <path
          d='M70.6753 76.2699C67.9653 77.8399 63.5653 77.8399 60.8553 76.2699C58.1453 74.6999 58.1453 72.1699 60.8553 70.5999C63.5653 69.0299 67.9653 69.0299 70.6753 70.5999C73.3853 72.1699 73.3853 74.6999 70.6753 76.2699Z'
          fill='#00CDAC'
        />
        <path d='M75.5657 71.6397L68.8657 67.7697L70.2057 66.9897L76.9157 70.8698L75.5657 71.6397Z' fill='#00CDAC' />
        <path d='M62.6657 79.0897L55.9557 75.2197L54.6157 75.9997L61.3157 79.8697L62.6657 79.0897Z' fill='#00CDAC' />
        <path
          d='M28.0154 51.6399C25.3054 53.2099 25.3054 55.7399 28.0154 57.3099C30.7254 58.8799 35.1254 58.8799 37.8354 57.3099C40.5454 55.7399 40.5454 53.2099 37.8354 51.6399C35.1254 50.0699 30.7254 50.0699 28.0154 51.6399Z'
          fill='#00CDAC'
        />
        <path d='M36.0259 48.8098L42.7259 52.6798L44.0759 51.8998L37.3659 48.0298L36.0259 48.8098Z' fill='#00CDAC' />
        <path d='M23.1159 56.2598L29.8259 60.1298L28.4759 60.9098L21.7759 57.0398L23.1159 56.2598Z' fill='#00CDAC' />
        <path
          d='M57.3054 68.5501C52.9054 71.0901 45.7854 71.0901 41.3854 68.5501C36.9854 66.0101 36.9854 61.9001 41.3854 59.3601C45.7854 56.8201 52.9054 56.8201 57.3054 59.3601C61.7054 61.9001 61.7054 66.0101 57.3054 68.5501Z'
          fill='#00CDAC'
        />
        <path d='M40.5958 74.2901L31.4458 69.0001L58.0958 53.6201L67.2458 58.9001L40.5958 74.2901Z' fill='#222A37' />
        <path
          d='M43.6052 67.2599C42.7552 66.7699 42.8652 65.9199 43.8452 65.3499L51.7752 60.7699C52.7552 60.2099 54.2352 60.1399 55.0752 60.6299C55.9252 61.1199 55.8152 61.9699 54.8352 62.5399L46.9052 67.1199C45.9252 67.6799 44.4452 67.7499 43.6052 67.2599Z'
          fill='url(#paint42_linear_57_14157)'
        />
        <path d='M101.575 64.9002L74.9146 80.2802V70.3002L101.575 54.9102V64.9002Z' fill='#222A37' />
        <path d='M101.575 57.62L74.9146 73.01V71.66L101.575 56.27V57.62Z' fill='#222A37' />
        <path d='M101.575 60.58L74.9146 75.96V74.62L101.575 59.23V60.58Z' fill='#222A37' />
        <path d='M101.575 63.5302L74.9146 78.9202V77.5702L101.575 62.1802V63.5302Z' fill='#222A37' />
        <path d='M74.9149 80.2801L13.0649 44.5701V34.5801L74.9149 70.3001V80.2801Z' fill='#222A37' />
        <path d='M74.9149 73.0102L13.0649 37.3002V35.9502L74.9149 71.6602V73.0102Z' fill='#485262' />
        <path d='M74.9149 75.9604L13.0649 40.2504V38.9004L74.9149 74.6204V75.9604Z' fill='#485262' />
        <path d='M74.9149 78.9203L13.0649 43.2104V41.8604L74.9149 77.5704V78.9203Z' fill='#485262' />
        <path d='M48.5645 65.0703L39.4146 59.7903V49.8003L48.5645 55.0803V65.0703Z' fill='#151A26' />
        <path d='M13.0649 34.5902L39.7149 19.2002L101.575 54.9102L74.9149 70.3002L13.0649 34.5902Z' fill='#485262' />
        <path d='M17.8452 34.85L40.1652 21.96L96.7852 54.65L74.4752 67.54L17.8452 34.85Z' fill='#00CDAC' />
        <path d='M21.0347 35.0003L40.4347 23.8003L93.5947 54.5003L74.2047 65.7003L21.0347 35.0003Z' fill='#485262' />
        <path
          d='M78.644 57.0702C75.934 58.6402 71.534 58.6402 68.824 57.0702C66.114 55.5002 66.114 52.9702 68.824 51.4002C71.534 49.8302 75.934 49.8302 78.644 51.4002C81.354 52.9702 81.354 55.5002 78.644 57.0702Z'
          fill='#00CDAC'
        />
        <path d='M83.5445 52.44L76.8345 48.57L78.1845 47.79L84.8845 51.67L83.5445 52.44Z' fill='#00CDAC' />
        <path d='M70.6345 59.89L63.9345 56.02L62.5845 56.8L69.2945 60.67L70.6345 59.89Z' fill='#00CDAC' />
        <path
          d='M35.9842 32.4299C33.2742 33.9999 33.2742 36.5299 35.9842 38.0999C38.6942 39.6699 43.0942 39.6699 45.8042 38.0999C48.5142 36.5299 48.5142 33.9999 45.8042 32.4299C43.0942 30.8599 38.6942 30.8599 35.9842 32.4299Z'
          fill='#00CDAC'
        />
        <path d='M43.9946 29.6101L50.7046 33.4801L52.0446 32.7001L45.3446 28.8301L43.9946 29.6101Z' fill='#00CDAC' />
        <path d='M31.0846 37.0601L37.7946 40.9301L36.4546 41.7101L29.7446 37.8401L31.0846 37.0601Z' fill='#00CDAC' />
        <path
          d='M65.2752 49.3504C60.8752 51.8904 53.7552 51.8904 49.3552 49.3504C44.9552 46.8104 44.9552 42.7004 49.3552 40.1604C53.7552 37.6204 60.8752 37.6204 65.2752 40.1604C69.6752 42.7004 69.6752 46.8104 65.2752 49.3504Z'
          fill='#00CDAC'
        />
        <path d='M48.5645 55.0904L39.4146 49.8004L66.0646 34.4204L75.2146 39.7004L48.5645 55.0904Z' fill='#222A37' />
        <path
          d='M51.5847 48.0602C50.7347 47.5702 50.8447 46.7202 51.8247 46.1502L59.7547 41.5702C60.7347 41.0102 62.2147 40.9402 63.0547 41.4302C63.9047 41.9202 63.7947 42.7702 62.8147 43.3402L54.8847 47.9202C53.9047 48.4802 52.4247 48.5502 51.5847 48.0602Z'
          fill='#253243'
        />
        <path d='M88.8148 45.7L62.1548 61.08V51.1L88.8148 35.71V45.7Z' fill='#222A37' />
        <path d='M88.8148 38.4198L62.1548 53.8098V52.4598L88.8148 37.0698V38.4198Z' fill='#222A37' />
        <path d='M88.8148 41.3798L62.1548 56.7598V55.4098L88.8148 40.0298V41.3798Z' fill='#222A37' />
        <path d='M88.8148 44.33L62.1548 59.72V58.37L88.8148 42.98V44.33Z' fill='#222A37' />
        <path d='M62.1552 61.0799L0.305176 25.3699V15.3799L62.1552 51.0999V61.0799Z' fill='#222A37' />
        <path d='M62.1552 53.81L0.305176 18.1V16.75L62.1552 52.46V53.81Z' fill='#485262' />
        <path d='M62.1552 56.7602L0.305176 21.0502V19.7002L62.1552 55.4102V56.7602Z' fill='#485262' />
        <path d='M62.1552 59.7202L0.305176 24.0102V22.6602L62.1552 58.3702V59.7202Z' fill='#485262' />
        <path d='M35.8048 45.8701L26.6548 40.5901V30.6001L35.8048 35.8801V45.8701Z' fill='#151A26' />
        <path d='M0.305176 15.39L26.9552 0L88.8152 35.71L62.1552 51.1L0.305176 15.39Z' fill='#485262' />
        <path d='M5.08545 15.6498L27.4054 2.75977L84.0255 35.4498L61.7155 48.3398L5.08545 15.6498Z' fill='#00CDAC' />
        <path d='M8.28564 15.8001L27.6756 4.6001L80.8356 35.3001L61.4456 46.4901L8.28564 15.8001Z' fill='#485262' />
        <path
          d='M65.8842 37.8597C63.1742 39.4297 58.7742 39.4297 56.0642 37.8597C53.3542 36.2897 53.3542 33.7597 56.0642 32.1897C58.7742 30.6197 63.1742 30.6197 65.8842 32.1897C68.5942 33.7597 68.5942 36.2897 65.8842 37.8597Z'
          fill='#00CDAC'
        />
        <path d='M70.7847 33.2398L64.0747 29.3698L65.4247 28.5898L72.1347 32.4598L70.7847 33.2398Z' fill='#00CDAC' />
        <path d='M57.8747 40.6898L51.1747 36.8198L49.8247 37.5998L56.5347 41.4698L57.8747 40.6898Z' fill='#00CDAC' />
        <path
          d='M23.2244 13.2297C20.5144 14.7997 20.5144 17.3297 23.2244 18.8997C25.9344 20.4697 30.3344 20.4697 33.0444 18.8997C35.7544 17.3297 35.7544 14.7997 33.0444 13.2297C30.3344 11.6597 25.9344 11.6597 23.2244 13.2297Z'
          fill='#00CDAC'
        />
        <path d='M31.2349 10.4099L37.9449 14.2799L39.2849 13.4999L32.5849 9.62988L31.2349 10.4099Z' fill='#00CDAC' />
        <path d='M18.3349 17.8599L25.0349 21.7299L23.6949 22.5099L16.9849 18.6299L18.3349 17.8599Z' fill='#00CDAC' />
        <path
          d='M52.5154 30.1502C48.1154 32.6902 40.9954 32.6902 36.5954 30.1502C32.1954 27.6102 32.1954 23.5002 36.5954 20.9602C40.9954 18.4202 48.1154 18.4202 52.5154 20.9602C56.9154 23.5002 56.9154 27.6102 52.5154 30.1502Z'
          fill='#00CDAC'
        />
        <path d='M35.8048 35.88L26.6548 30.6L53.3048 15.21L62.4548 20.5L35.8048 35.88Z' fill='#222A37' />
        <path
          d='M38.825 28.86C37.975 28.37 38.085 27.52 39.065 26.95L46.995 22.37C47.975 21.81 49.455 21.74 50.295 22.23C51.145 22.72 51.035 23.57 50.055 24.14L42.125 28.72C41.145 29.28 39.665 29.35 38.825 28.86Z'
          fill='url(#paint43_linear_57_14157)'
        />
      </g>
      <defs>
        <linearGradient
          id='paint0_linear_57_14157'
          x1='76.4946'
          y1='53.7223'
          x2='82.1631'
          y2='39.6967'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#8B1666' />
          <stop offset='1' stopColor='#E52979' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_57_14157'
          x1='78.2622'
          y1='50.4174'
          x2='86.6866'
          y2='35.9224'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#8B1666' />
          <stop offset='1' stopColor='#E52979' />
        </linearGradient>
        <linearGradient
          id='paint2_linear_57_14157'
          x1='82.6753'
          y1='47.8698'
          x2='86.6598'
          y2='41.0179'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#8B1666' />
          <stop offset='1' stopColor='#E52979' />
        </linearGradient>
        <linearGradient
          id='paint3_linear_57_14157'
          x1='76.4946'
          y1='49.7824'
          x2='82.1631'
          y2='35.7568'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#8B1666' />
          <stop offset='1' stopColor='#E52979' />
        </linearGradient>
        <linearGradient
          id='paint4_linear_57_14157'
          x1='78.2622'
          y1='46.4775'
          x2='86.6866'
          y2='31.9825'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#8B1666' />
          <stop offset='1' stopColor='#E52979' />
        </linearGradient>
        <linearGradient
          id='paint5_linear_57_14157'
          x1='82.6753'
          y1='43.9298'
          x2='86.6598'
          y2='37.078'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#8B1666' />
          <stop offset='1' stopColor='#E52979' />
        </linearGradient>
        <linearGradient
          id='paint6_linear_57_14157'
          x1='76.4946'
          y1='45.8425'
          x2='82.1631'
          y2='31.8168'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#8B1666' />
          <stop offset='1' stopColor='#E52979' />
        </linearGradient>
        <linearGradient
          id='paint7_linear_57_14157'
          x1='78.2622'
          y1='42.5376'
          x2='86.6866'
          y2='28.0425'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#8B1666' />
          <stop offset='1' stopColor='#E52979' />
        </linearGradient>
        <linearGradient
          id='paint8_linear_57_14157'
          x1='82.6753'
          y1='39.9899'
          x2='86.6598'
          y2='33.1381'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#8B1666' />
          <stop offset='1' stopColor='#E52979' />
        </linearGradient>
        <linearGradient
          id='paint9_linear_57_14157'
          x1='76.4946'
          y1='41.902'
          x2='82.1631'
          y2='27.8764'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#8B1666' />
          <stop offset='1' stopColor='#E52979' />
        </linearGradient>
        <linearGradient
          id='paint10_linear_57_14157'
          x1='78.2622'
          y1='38.5971'
          x2='86.6866'
          y2='24.1021'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#8B1666' />
          <stop offset='1' stopColor='#E52979' />
        </linearGradient>
        <linearGradient
          id='paint11_linear_57_14157'
          x1='82.6753'
          y1='36.0495'
          x2='86.6598'
          y2='29.1976'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#8B1666' />
          <stop offset='1' stopColor='#E52979' />
        </linearGradient>
        <linearGradient
          id='paint12_linear_57_14157'
          x1='76.4946'
          y1='37.9621'
          x2='82.1631'
          y2='23.9365'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#8B1666' />
          <stop offset='1' stopColor='#E52979' />
        </linearGradient>
        <linearGradient
          id='paint13_linear_57_14157'
          x1='78.2622'
          y1='34.6572'
          x2='86.6866'
          y2='20.1621'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#8B1666' />
          <stop offset='1' stopColor='#E52979' />
        </linearGradient>
        <linearGradient
          id='paint14_linear_57_14157'
          x1='82.6753'
          y1='32.1095'
          x2='86.6598'
          y2='25.2577'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#8B1666' />
          <stop offset='1' stopColor='#E52979' />
        </linearGradient>
        <linearGradient
          id='paint15_linear_57_14157'
          x1='76.4946'
          y1='34.0124'
          x2='82.1631'
          y2='19.9867'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#8B1666' />
          <stop offset='1' stopColor='#E52979' />
        </linearGradient>
        <linearGradient
          id='paint16_linear_57_14157'
          x1='78.2622'
          y1='30.7172'
          x2='86.6866'
          y2='16.2222'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#8B1666' />
          <stop offset='1' stopColor='#E52979' />
        </linearGradient>
        <linearGradient
          id='paint17_linear_57_14157'
          x1='82.6753'
          y1='28.1696'
          x2='86.6598'
          y2='21.3178'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#8B1666' />
          <stop offset='1' stopColor='#E52979' />
        </linearGradient>
        <linearGradient
          id='paint18_linear_57_14157'
          x1='76.4946'
          y1='30.0724'
          x2='82.1631'
          y2='16.0468'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#8B1666' />
          <stop offset='1' stopColor='#E52979' />
        </linearGradient>
        <linearGradient
          id='paint19_linear_57_14157'
          x1='78.2622'
          y1='26.7775'
          x2='86.6817'
          y2='12.2852'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#8B1666' />
          <stop offset='1' stopColor='#E52979' />
        </linearGradient>
        <linearGradient
          id='paint20_linear_57_14157'
          x1='82.6753'
          y1='24.2296'
          x2='86.6598'
          y2='17.3778'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#8B1666' />
          <stop offset='1' stopColor='#E52979' />
        </linearGradient>
        <linearGradient
          id='paint21_linear_57_14157'
          x1='76.4946'
          y1='26.1325'
          x2='82.1631'
          y2='12.1069'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#8B1666' />
          <stop offset='1' stopColor='#E52979' />
        </linearGradient>
        <linearGradient
          id='paint22_linear_57_14157'
          x1='78.2622'
          y1='22.8374'
          x2='86.6866'
          y2='8.34232'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#8B1666' />
          <stop offset='1' stopColor='#E52979' />
        </linearGradient>
        <linearGradient
          id='paint23_linear_57_14157'
          x1='82.6753'
          y1='20.2897'
          x2='86.6598'
          y2='13.4379'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#8B1666' />
          <stop offset='1' stopColor='#E52979' />
        </linearGradient>
        <linearGradient
          id='paint24_linear_57_14157'
          x1='93.4351'
          y1='63.572'
          x2='99.1015'
          y2='49.5444'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#8B1666' />
          <stop offset='1' stopColor='#E52979' />
        </linearGradient>
        <linearGradient
          id='paint25_linear_57_14157'
          x1='95.2124'
          y1='60.2773'
          x2='103.638'
          y2='45.7839'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#8B1666' />
          <stop offset='1' stopColor='#E52979' />
        </linearGradient>
        <linearGradient
          id='paint26_linear_57_14157'
          x1='99.6255'
          y1='57.7194'
          x2='103.61'
          y2='50.8676'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#8B1666' />
          <stop offset='1' stopColor='#E52979' />
        </linearGradient>
        <linearGradient
          id='paint27_linear_57_14157'
          x1='93.4351'
          y1='59.632'
          x2='99.1015'
          y2='45.6044'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#8B1666' />
          <stop offset='1' stopColor='#E52979' />
        </linearGradient>
        <linearGradient
          id='paint28_linear_57_14157'
          x1='95.2163'
          y1='56.3374'
          x2='103.642'
          y2='41.844'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#8B1666' />
          <stop offset='1' stopColor='#E52979' />
        </linearGradient>
        <linearGradient
          id='paint29_linear_57_14157'
          x1='99.6255'
          y1='53.7794'
          x2='103.61'
          y2='46.9276'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#8B1666' />
          <stop offset='1' stopColor='#E52979' />
        </linearGradient>
        <linearGradient
          id='paint30_linear_57_14157'
          x1='93.4351'
          y1='55.6921'
          x2='99.1015'
          y2='41.6645'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#8B1666' />
          <stop offset='1' stopColor='#E52979' />
        </linearGradient>
        <linearGradient
          id='paint31_linear_57_14157'
          x1='95.2163'
          y1='52.3872'
          x2='103.643'
          y2='37.8954'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#8B1666' />
          <stop offset='1' stopColor='#E52979' />
        </linearGradient>
        <linearGradient
          id='paint32_linear_57_14157'
          x1='99.6255'
          y1='49.8395'
          x2='103.61'
          y2='42.9877'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#8B1666' />
          <stop offset='1' stopColor='#E52979' />
        </linearGradient>
        <linearGradient
          id='paint33_linear_57_14157'
          x1='93.4351'
          y1='51.7521'
          x2='99.1015'
          y2='37.7245'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#8B1666' />
          <stop offset='1' stopColor='#E52979' />
        </linearGradient>
        <linearGradient
          id='paint34_linear_57_14157'
          x1='95.2163'
          y1='48.4472'
          x2='103.643'
          y2='33.9555'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#8B1666' />
          <stop offset='1' stopColor='#E52979' />
        </linearGradient>
        <linearGradient
          id='paint35_linear_57_14157'
          x1='99.6255'
          y1='45.8995'
          x2='103.61'
          y2='39.0477'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#8B1666' />
          <stop offset='1' stopColor='#E52979' />
        </linearGradient>
        <linearGradient
          id='paint36_linear_57_14157'
          x1='73.4849'
          y1='66.092'
          x2='79.1533'
          y2='52.0663'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#8B1666' />
          <stop offset='1' stopColor='#E52979' />
        </linearGradient>
        <linearGradient
          id='paint37_linear_57_14157'
          x1='75.2524'
          y1='62.7871'
          x2='83.6769'
          y2='48.292'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#8B1666' />
          <stop offset='1' stopColor='#E52979' />
        </linearGradient>
        <linearGradient
          id='paint38_linear_57_14157'
          x1='79.6655'
          y1='60.2394'
          x2='83.65'
          y2='53.3876'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#8B1666' />
          <stop offset='1' stopColor='#E52979' />
        </linearGradient>
        <linearGradient
          id='paint39_linear_57_14157'
          x1='73.4849'
          y1='62.152'
          x2='79.1533'
          y2='48.1264'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#8B1666' />
          <stop offset='1' stopColor='#E52979' />
        </linearGradient>
        <linearGradient
          id='paint40_linear_57_14157'
          x1='75.2524'
          y1='58.8471'
          x2='83.6769'
          y2='44.3521'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#8B1666' />
          <stop offset='1' stopColor='#E52979' />
        </linearGradient>
        <linearGradient
          id='paint41_linear_57_14157'
          x1='79.6655'
          y1='56.2995'
          x2='83.65'
          y2='49.4476'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#8B1666' />
          <stop offset='1' stopColor='#E52979' />
        </linearGradient>
        <linearGradient
          id='paint42_linear_57_14157'
          x1='43.0327'
          y1='67.5876'
          x2='49.3828'
          y2='56.6612'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#8B1666' />
          <stop offset='1' stopColor='#E52979' />
        </linearGradient>
        <linearGradient
          id='paint43_linear_57_14157'
          x1='38.2524'
          y1='29.1877'
          x2='44.6026'
          y2='18.2613'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#8B1666' />
          <stop offset='1' stopColor='#E52979' />
        </linearGradient>
        <clipPath id='clip0_57_14157'>
          <rect width='113.39' height='99.49' fill='white' transform='translate(0.305176)' />
        </clipPath>
      </defs>
    </svg>
  )
}

import { useMemo, useState } from 'react'
import { apiBuyTickets } from 'src/api/powerball.api'
import DrawCountdown from 'src/components/DrawCountdown'
import LuckyNumbers from 'src/components/LuckyNumbers'
import TicketsInCirculation from 'src/components/TicketsInCirculation'
import TotalPrizePool from 'src/components/TotalPrizePool'
import AddTicketsButton from 'src/components/buttons/AddTicketsButton'
import AutoFillAllTicketsButton from 'src/components/buttons/AutoFillAllTicketsButton'
import ClearAllTicketsButton from 'src/components/buttons/ClearAllTicketsButton'
import WadOfMoneyIcon from 'src/components/icons/WadOfMoneyIcon'
import useAppSelector from 'src/hooks/useAppSelector'
import Layout from 'src/layouts'
import { formatNumber, generateRandomTicket, getErrorMessage, getStringFromTicket } from 'src/utils/helpers'
import { formatEther, zeroAddress } from 'viem'
import { toast } from 'react-hot-toast'
import TicketToBuy from 'src/components/TicketToBuy'

const luckyTickets = [generateRandomTicket(), generateRandomTicket(), generateRandomTicket()]

export default function Powerball() {
  const [loading, setLoading] = useState<boolean>(false)
  const [tickets, setTickets] = useState<Array<number[]>>([])
  const { nextDrawTime, ticketsCount, totalPrizePool } = useAppSelector((state) => state.config)
  const ticketPrice = useAppSelector((state) => state.user.ticketPrice)
  const filledTicketCount = useMemo(() => {
    let count = 0

    for (let ticket of tickets) {
      let flag = true
      for (let i = 0; i < 6; i++) {
        if (ticket[i] === 0) {
          flag = false
          break
        }
      }

      if (flag) {
        count++
      }
    }

    return count
  }, [tickets])

  const handleAddTickets = (count: number) => {
    const _tickets = Array(count)
      .fill(0)
      .map((_) =>
        Array(6)
          .fill(0)
          .map((__) => __)
      )

    console.log(_tickets)

    setTickets((prev) => [...prev, ..._tickets])
  }

  const handleChangeTicketNumber = ({ index, value, i }: { index: number; value: string; i: number }) => {
    const _tickets = [...tickets]

    _tickets[i][index] = +value.slice(0, 2)

    setTickets(_tickets)
  }

  const handleClickAutoFill = (index: number) => {
    const _tickets = [...tickets]

    _tickets[index] = generateRandomTicket()

    setTickets(_tickets)
  }

  const handleClickErase = (index: number) => {
    const _tickets = [...tickets]

    _tickets[index] = [0, 0, 0, 0, 0, 0]

    setTickets(_tickets)
  }

  const handleClickRemove = (index: number) => {
    const _tickets = []

    for (let i = 0; i < tickets.length; i++) {
      if (i !== index) {
        _tickets.push(tickets[i])
      }
    }

    setTickets([..._tickets])
  }

  const handleClickAutoFillAll = () => {
    const _tickets = [...tickets]

    for (let i = 0; i < _tickets.length; i++) {
      if (_tickets[i][0] === 0) {
        _tickets[i] = generateRandomTicket()
      }
    }

    setTickets(_tickets)
  }

  const handleClickEraseAll = () => {
    setTickets((prev) => Array(prev.length).fill(Array(6).fill(0)))
  }

  const handleGetTickets = async () => {
    try {
      setLoading(true)

      const filledTickets = []

      for (let ticket of tickets) {
        let flag = true

        for (let i = 0; i < 6; i++) {
          if (ticket[i] === 0) {
            flag = false
          }
        }

        if (flag) {
          filledTickets.push(+getStringFromTicket(ticket))
        }
      }

      if (filledTickets.length === 0) {
        toast.error('No tickets are filled')
        return
      }

      await apiBuyTickets(filledTickets, zeroAddress)

      toast.success(`Successfully bought ${filledTickets.length} tickets`)

      setTickets([])
    } catch (err) {
      toast.error(getErrorMessage(err))
    } finally {
      setLoading(false)
    }
  }

  return (
    <Layout>
      <div className='container mt-[126px] space-y-6 lg:mt-[204px]'>
        <h2>Powerball</h2>
        <div className='grid grid-cols-4 gap-6 xl:grid-cols-3'>
          <div className='col-span-3 space-y-6'>
            <div className='grid grid-cols-3 gap-6 md:grid-cols-2 sm:grid-cols-1'>
              <TicketsInCirculation ticketCount={ticketsCount} />
              <TotalPrizePool totalPrize={totalPrizePool[0]?.amount_wei ? totalPrizePool[0].amount_wei : '0'} />
              <DrawCountdown nextDrawTime={nextDrawTime} />
            </div>
            <div className='flex items-center justify-end gap-7'>
              <ClearAllTicketsButton onClick={handleClickEraseAll} />
              <AutoFillAllTicketsButton onClick={handleClickAutoFillAll} />
            </div>
            {tickets.length > 0 ? (
              <div className='grid grid-cols-3 gap-6 md:grid-cols-2 sm:grid-cols-1'>
                {tickets.map((ticket, i) => (
                  <TicketToBuy
                    key={i}
                    ticket={ticket}
                    ticketNumber={i + 1}
                    onChangeTicketNumber={(props) => handleChangeTicketNumber({ ...props, i })}
                    onClickAutoFill={() => handleClickAutoFill(i)}
                    onClickErase={() => handleClickErase(i)}
                    onClickRemove={() => handleClickRemove(i)}
                  />
                ))}
              </div>
            ) : (
              <p className='col-span-3 py-[90px] text-center text-sm font-medium text-white'>
                You do not have any tickets, click on the button below to add tickets
              </p>
            )}
            <AddTicketsButton onAddTickets={(count) => handleAddTickets(count)} />
          </div>
          <div className='grid gap-6 xl:col-span-3 xl:grid-cols-3 md:grid-cols-2 sm:grid-cols-1'>
            <div className='space-y-[22px] rounded-2xl bg-card-primary p-[22px] xl:col-span-2 md:col-span-1'>
              <div className='space-y-0.5'>
                <p className='text-2xl font-medium text-white'>Payment</p>
                <p className='text-sm text-gray'>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididun
                </p>
              </div>
              <div className='h-px bg-card-secondary' />
              <div className='flex items-center justify-between'>
                <p className='font-medium text-white'>Filled-out Tickets</p>
                <p className='text-sm text-white'>{filledTicketCount}</p>
              </div>
              <div className='h-px bg-card-secondary' />
              <div className='space-y-3'>
                <div className='flex items-center justify-between'>
                  <p className='font-medium text-white'>Total:</p>
                  <div className='flex items-center gap-2'>
                    <WadOfMoneyIcon className='h-6' mode='gray' />
                    <p className='text-sm font-medium text-white'>
                      {formatNumber(
                        filledTicketCount * (ticketPrice[0]?.amount_wei ? +formatEther(ticketPrice[0].amount_wei) : 0)
                      )}
                    </p>
                  </div>
                </div>
                <button
                  className='btn-primary h-[54px] w-full text-sm font-bold text-white'
                  disabled={loading}
                  onClick={handleGetTickets}
                >
                  Get tickets
                </button>
              </div>
            </div>
            <LuckyNumbers tickets={luckyTickets} />
          </div>
        </div>
      </div>
    </Layout>
  )
}

import { useEffect, useState } from 'react'
import { apiGetRecentDrawings } from 'src/api/public.api'
import Number from 'src/components/Number'
import CupIcon from 'src/components/icons/CupIcon'
import { formatDate, getTicketFromString } from 'src/utils/helpers'
import { formatEther } from 'viem'

interface DrawProps {
  drawing: Record<string, any>
}

const Draw: React.FC<DrawProps> = ({ drawing }) => {
  return (
    <div className='space-y-[22px] rounded-2xl bg-card-primary p-[22px]'>
      <div className='flex items-center gap-2'>
        <CupIcon className='h-6' />
        <p className='text-lg font-medium text-gray'>{formatDate(drawing.timestamp)}</p>
      </div>
      <p className='shadow-1 flex h-[58px] items-center rounded-[5px] bg-card-secondary px-2 text-[32px] font-semibold text-white'>
        {formatEther(drawing.prize_pool[0].amount_wei)}
      </p>
      <div className='flex justify-between'>
        {getTicketFromString(drawing.numbers).map((number, i) => (
          <Number key={i} number={number} theme='secondary' thickShadow />
        ))}
      </div>
      <div>
        <div className='flex h-10 items-center justify-between'>
          <p className='text-sm font-medium text-gray'>Draw #</p>
          <p className='text-sm font-medium text-white'>{drawing.id}</p>
        </div>
        <div className='flex h-10 items-center justify-between border-y border-dashed border-card-secondary'>
          <p className='text-sm font-medium text-gray'>Tickets sold</p>
          <p className='text-sm font-medium text-white'>{drawing.tickets_count}</p>
        </div>
        <div className='flex h-10 items-center justify-between'>
          <p className='text-sm font-medium text-gray'>Winners</p>
          <p className='text-sm font-medium text-white'>{drawing.winners}</p>
        </div>
      </div>
    </div>
  )
}

export default function RecentDrawsSection() {
  const [drawings, setDrawings] = useState<any[]>([])

  useEffect(() => {
    ;(async () => {
      try {
        const data = await apiGetRecentDrawings(1)
        setDrawings(data.drawings)
      } catch (err) {
        console.log(err)
      }
    })()
  }, [])

  return (
    <section className='lg:mt-12'>
      <div className='container space-y-8 xl:space-y-6'>
        <h1>Recent Draws</h1>
        <div className='grid grid-cols-4 gap-6 lg:grid-cols-2 sm:grid-cols-1'>
          {drawings.map((drawing, i) => (
            <Draw drawing={drawing} key={i} />
          ))}
        </div>
      </div>
    </section>
  )
}

import { useState } from 'react'
import Layout from 'src/layouts/Layout'
import CurrentTicketsTable from './Components/CurrentTicketsTable'
import HistoryTable from './Components/HistoryTable'

export default function Inventory() {
  const [tab, setTab] = useState<'currently' | 'history'>('currently')

  return (
    <Layout>
      <div className='container mt-[126px] space-y-6 lg:mt-[204px]'>
        <h2>Inventory</h2>
        {tab === 'currently' ? (
          <CurrentTicketsTable onTabChange={(arg) => setTab(arg)} />
        ) : (
          <HistoryTable onTabChange={(arg) => setTab(arg)} />
        )}
      </div>
    </Layout>
  )
}

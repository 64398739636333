import { cx } from 'src/utils/helpers'
import TicketIcon from './icons/TicketIcon'

interface Props {
  className?: string
  ticketCount: number
}

export default function YourTicketInGame({ className, ticketCount }: Props) {
  return (
    <div className={cx('space-y-[22px] rounded-2xl bg-card-primary p-[22px]', className)}>
      <div className='flex items-center gap-2'>
        <TicketIcon className='h-6' />
        <p className='text-lg font-medium text-white'>Your tickets in game</p>
      </div>
      <p className='text-5xl font-medium text-white'>{ticketCount}</p>
    </div>
  )
}

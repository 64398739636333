import { type PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { apiGetPublicStats } from 'src/api/public.api'

interface IConfig {
  nextDrawTime: number | null
  ticketsCount: number
  totalPrizePool: {
    amount_wei: string
    currency_address: `0x${string}`
  }[]
}

const initialState: IConfig = {
  nextDrawTime: null,
  ticketsCount: 0,
  totalPrizePool: [],
}

export const getPublicStats = createAsyncThunk('/public/getStats', async () => {
  try {
    const data = await apiGetPublicStats()
    return data
  } catch (err: any) {
    throw err
  }
})

const configSlice = createSlice({
  name: 'public',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPublicStats.fulfilled, (state, action: PayloadAction<any>) => {
      state = {
        ...state,
        nextDrawTime: action.payload.next_draw_time,
        ticketsCount: action.payload.tickets_count,
        totalPrizePool: action.payload.total_prize_pool,
      }

      return state
    })
  },
})

export const {} = configSlice.actions //eslint-disable-line

export default configSlice.reducer

import Header from './Header'
import Footer from './Footer'

interface Props {
  children?: React.ReactNode
  hideHeader?: boolean
  hideShineImage?: boolean
}

export default function Layout({ children, hideHeader = false, hideShineImage = false }: Props) {
  return (
    <div className='relative flex min-h-screen flex-col overflow-hidden'>
      <Header hideHeader={hideHeader} />
      <main className='pb-[90px]'>{children}</main>
      <Footer />
      {!hideShineImage && (
        <>
          <img
            src='/images/shine.png'
            alt=''
            className='absolute left-1/2 top-0 -z-[2] w-[1440px] -translate-x-1/2 mix-blend-overlay'
          />
          <div className='absolute left-0 top-0 -z-[1] h-[660px] w-[660px] -translate-x-1/3 -translate-y-1/4 rounded-full bg-card-secondary opacity-30 blur-[200px]' />
        </>
      )}
      <div className='tanuki-gradient absolute left-1/2 top-[100vh] -z-[1] aspect-square h-[840px] -translate-y-1/2 translate-x-1/4 rounded-full opacity-30 blur-[200px] sm:hidden' />
      <div className='tanuki-gradient absolute right-1/2 top-[400vh] -z-[1] aspect-square h-[840px] -translate-x-1/4 -translate-y-1/2 rounded-full opacity-30 blur-[200px] sm:hidden' />
    </div>
  )
}

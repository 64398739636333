import { Link } from 'react-router-dom'
import DrawCountdown from 'src/components/DrawCountdown'
import TicketsInCirculation from 'src/components/TicketsInCirculation'
import TotalPrizePool from 'src/components/TotalPrizePool'
import useAppSelector from 'src/hooks/useAppSelector'

export default function OverviewSection() {
  const { totalPrizePool, nextDrawTime, ticketsCount } = useAppSelector((state) => state.config)

  return (
    <section className='-translate-y-1/2 lg:translate-y-0'>
      <div className='container'>
        <div className='flex justify-between rounded-2xl bg-card-primary px-12 py-6 lg:grid lg:grid-cols-3 lg:gap-6 lg:px-6 md:grid-cols-1'>
          <TotalPrizePool
            className='w-72 !p-0 lg:w-60 sm:w-full'
            totalPrize={totalPrizePool[0]?.amount_wei ? totalPrizePool[0].amount_wei : '0'}
          />
          <DrawCountdown className='!p-0' nextDrawTime={nextDrawTime} />
          <TicketsInCirculation className='!p-0' ticketCount={ticketsCount} />
          <Link to='/powerball' className='lg:col-span-3 lg:mx-auto md:col-span-1 md:w-full'>
            <button className='btn-primary h-[54px] w-[250px] text-sm font-bold md:w-full'>Play now</button>
          </Link>
        </div>
      </div>
    </section>
  )
}

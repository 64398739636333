import useAppDispatch from 'src/hooks/useAppDispatch'
import useAppSelector from 'src/hooks/useAppSelector'
import CloseIcon from '../icons/CloseIcon'
import { setWalletModalOpen } from 'src/redux/slices/modal'
import EthereumIcon from '../icons/EthereumIcon'
import SafepalIcon from '../icons/SafepalIcon'
import { cx } from 'src/utils/helpers'
import useClickOutside from 'src/hooks/useClickOutside'
import { useAccount, useConnect } from 'wagmi'

export default function ConnectWalletModal() {
  const dispatch = useAppDispatch()
  const { isConnected } = useAccount()
  const { connect, connectors } = useConnect()

  const isOpen = useAppSelector((state) => state.modal.walletModalOpen)

  const handleCloseModal = () => {
    dispatch(setWalletModalOpen(false))
  }

  const ref = useClickOutside(handleCloseModal)

  if (isConnected) {
    handleCloseModal()
  }
  const handleConnect = async (id: number) => {
    connect({ connector: connectors[id] })
  }

  return (
    <div
      className={cx(
        'fixed inset-0 z-40 bg-background/60 backdrop-blur-[10px] transition-all duration-300 ease-in-out lg:z-[60]',
        isOpen ? 'pointer-events-auto opacity-100' : 'pointer-events-none opacity-0'
      )}
    >
      <div className='container mt-[102px] lg:mt-0 lg:flex lg:items-center lg:justify-center lg:h-full'>
        <div
          className='ml-auto w-full max-w-[400px] rounded-2xl border border-card-secondary bg-card-primary lg:mx-auto'
          ref={ref}
        >
          <div className='flex items-center justify-between border-b border-card-secondary p-4'>
            <p className='font-semibols text-xl text-[#F1F1F1]'>Connect wallet</p>
            <CloseIcon className='h-7 cursor-pointer' onClick={handleCloseModal} />
          </div>
          <div className='flex items-center gap-2.5 px-6 py-4'>
            <div className='flex h-9 w-9 items-center justify-center rounded-lg bg-[#6679E7]'>
              <EthereumIcon className='h-7' />
            </div>
            <p className='text-2xl text-[#F1F1F1]'>Ethereum Network</p>
          </div>
          <div className='space-y-6 px-6 py-4'>
            <p className='font-medium text-[#F1F1f1]'>Select Wallet</p>
            <div className='grid grid-cols-2 gap-4'>
              <div
                className='flex h-24 cursor-pointer flex-col items-center justify-center gap-2 rounded-[10px] bg-card-secondary transition duration-300 ease-in-out hover:bg-[#303c4e]'
                onClick={() => handleConnect(0)}
              >
                <img src='/images/wallets/metamask.png' alt='' className='h-9 w-9' />
                <p className='text-[#F1F1F1]'>Metamask</p>
              </div>
              <div
                className='flex h-24 cursor-pointer flex-col items-center justify-center gap-2 rounded-[10px] bg-card-secondary transition duration-300 ease-in-out hover:bg-[#303c4e]'
                onClick={() => handleConnect(1)}
              >
                <img src='/images/wallets/coinbase.png' alt='' className='h-9 w-9' />
                <p className='text-[#F1F1F1]'>Coinbase</p>
              </div>
              <div
                className='flex h-24 cursor-pointer flex-col items-center justify-center gap-2 rounded-[10px] bg-card-secondary transition duration-300 ease-in-out hover:bg-[#303c4e]'
                onClick={() => handleConnect(0)}
              >
                <div className='flex h-9 w-9 items-center justify-center rounded-lg bg-white'>
                  <SafepalIcon className='h-[22px]' />
                </div>
                <p className='text-[#F1F1F1]'>Safepal</p>
              </div>
              <div
                className='flex h-24 cursor-pointer flex-col items-center justify-center gap-2 rounded-[10px] bg-card-secondary transition duration-300 ease-in-out hover:bg-[#303c4e]'
                onClick={() => handleConnect(2)}
              >
                <img src='/images/wallets/walletconnect.png' alt='' className='h-9 w-9' />
                <p className='text-[#F1F1F1]'>Wallet Connect</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

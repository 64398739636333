export const cx = (...classNames: (string | undefined)[]) => classNames.filter(Boolean).join(' ')

export const formatDate = (timestamp: string | number) => {
  const date = new Date(+timestamp * 1000)

  const options: any = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  }

  return date.toLocaleString('en-US', options)
}

export const formatDateTime = (timestamp: string | number) => {
  const date = new Date(+timestamp * 1000)

  const options: any = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false, // Use 24-hour format
  }

  return date.toLocaleString('en-GB', options).replace('/', '.').replace('/', '.').replace(',', '')
}

export const formatLeftTime = (leftTime: number) => {
  return [
    Math.floor(leftTime / 86400)
      .toString()
      .padStart(2, '0'),
    Math.floor((leftTime % 86400) / 3600)
      .toString()
      .padStart(2, '0'),
    Math.floor((leftTime % 3600) / 60)
      .toString()
      .padStart(2, '0'),
    (leftTime % 60).toString().padStart(2, '0'),
  ]
}

export const formatNumber = (arg: string | number) => {
  return Math.floor((typeof arg === 'string' ? +arg : arg) * 1e10) / 1e10
}

export const generateLink = (hash: string) => {
  return `https://goerli.etherscan.io/tx/${hash}`
}

export const generateRandomNumber = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1)) + min
}

export const generateRandomTicket = () => {
  const minTicketNumber = 1
  const maxTicketNumber = 99
  let ticket = []

  for (let i = 0; i < 6; i++) {
    ticket.push(generateRandomNumber(minTicketNumber, maxTicketNumber))
  }

  return ticket
}

export const getErrorMessage = (err: any) => {
  return err.response?.data?.error || err.response?.data?.message || err.message
}

export const getStringFromTicket = (ticket: number[]) => {
  let str = ''

  for (let i = 0; i < 6; i++) {
    str = str + ticket[i].toString().padStart(2, '0')
  }

  return str
}

export const getTicketFromString = (str: string) => {
  let tmp: number = +str

  return [
    Math.floor(tmp / 1e10),
    Math.floor((tmp % 1e10) / 1e8),
    Math.floor((tmp % 1e8) / 1e6),
    Math.floor((tmp % 1e6) / 1e4),
    Math.floor((tmp % 1e4) / 1e2),
    Math.floor(tmp % 1e2),
  ]
}

export const shortenAddress = (arg: string | undefined) => (!arg ? '' : `${arg.slice(0, 6)}...${arg.slice(-4)}`)

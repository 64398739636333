import { cx, formatDateTime } from 'src/utils/helpers'
import EraseSquareIcon from './icons/EraseSquareIcon'
import RandomCube1Icon from './icons/RandomCube1Icon'
import CloseIcon from './icons/CloseIcon'
import Number from './Number'

interface Props {
  className?: string
  hideCloseButton?: boolean
  hideControls?: boolean
  theme?: 'primary' | 'secondary'
  ticket: number[]
  ticketNumber: number
  timestamp?: number
  onClickAutoFill?: () => void
  onClickErase?: () => void
  onClickRemove?: () => void
}

export default function Ticket({
  className,
  hideCloseButton = false,
  hideControls = false,
  theme = 'primary',
  ticket,
  ticketNumber,
  timestamp,
  onClickAutoFill = () => null,
  onClickErase = () => null,
  onClickRemove = () => null,
}: Props) {
  return (
    <div className={cx('relative', className)}>
      {!hideCloseButton && (
        <CloseIcon className='absolute -right-4 -top-4 z-[1] h-8 cursor-pointer' onClick={onClickRemove} />
      )}
      <div
        className={cx(
          'relative flex items-center justify-between rounded-2xl px-[22px] py-4',
          theme === 'primary' ? 'bg-card-primary' : 'bg-card-secondary'
        )}
      >
        <div>
          <p className='text-lg font-medium text-white'>Ticket #{ticketNumber.toString().padStart(3, '0')}</p>
          <p className='text-sm text-gray'>{timestamp ? formatDateTime(timestamp) : 'Choose 6 numbers'}</p>
        </div>
        {!hideControls && (
          <div className='flex gap-1.5'>
            {ticket[0] !== 0 && <EraseSquareIcon className='h-6 cursor-pointer' mode='gray' onClick={onClickErase} />}
            <RandomCube1Icon className='h-6 cursor-pointer' mode='gray' onClick={onClickAutoFill} />
          </div>
        )}
        <div className='absolute bottom-0 w-[calc(100%-44px)] border-t-2 border-dashed border-background' />
      </div>
      <div
        className={cx(
          'flex justify-between rounded-2xl px-[22px] py-4',
          theme === 'primary' ? 'bg-card-primary' : 'bg-card-secondary'
        )}
      >
        {ticket.map((number, i) => (
          <Number number={number} theme={number !== 0 ? 'tanuki' : theme === 'primary' ? '' : 'primary'} key={i} />
        ))}
      </div>
    </div>
  )
}

import { useEffect, useState } from 'react'
import { apiGetCurrentTickets } from 'src/api/inventory.api'
import InventoryFilter from 'src/components/InventoryFilter'
import Pagination from 'src/components/Pagination'
import Ticket from 'src/components/Ticket'
import CheckBox from 'src/components/forms/CheckBox'
import { getTicketFromString } from 'src/utils/helpers'

interface Props {
  onTabChange: React.Dispatch<'currently' | 'history'>
}

export default function CurrentTicketsTable({ onTabChange }: Props) {
  const [sort, setSort] = useState<string>('time')
  const [currentTickets, setCurrentTickets] = useState<any[]>([])
  const [currentPage, setCurrentPage] = useState<number>(0)
  const [hasNextPage, setHasNextPage] = useState<boolean>(false)

  useEffect(() => {
    getCurrentTicket(1, sort)
  }, [sort])

  const getCurrentTicket = async (page: number, sort: string) => {
    try {
      const data = await apiGetCurrentTickets(page, sort)
      setCurrentTickets(data.tickets)
      setCurrentPage(page)
      setHasNextPage(data.next_page)
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <div className='shadow-1 rounded-2xl bg-card-primary'>
      <div className='space-y-1 px-6 py-5'>
        <p className='text-lg font-medium text-white'>Tickets Inventory</p>
        <p className='text-sm text-gray'>All currently available tickets are shown here</p>
      </div>
      <div className='flex items-center justify-between border-b border-card-secondary px-6 py-5 sm:flex-col sm:items-start sm:gap-4'>
        <InventoryFilter value='currently' onClick={() => onTabChange('history')} />
        <div className='flex items-center gap-6 sm:flex-col sm:items-start'>
          <p className='text-sm font-semibold text-white'>Sort by:</p>
          <CheckBox checked={sort === 'number'} label='by ticket number' onClick={() => setSort('number')} />
          <CheckBox checked={sort === 'time'} label='by the date of purchase' onClick={() => setSort('time')} />
        </div>
      </div>
      <div className='grid grid-cols-4 gap-6 p-6 xl:grid-cols-3 md:grid-cols-2 sm:grid-cols-1'>
        {currentTickets.map((ticket, i) => (
          <Ticket
            hideCloseButton
            hideControls
            key={i}
            theme='secondary'
            ticket={getTicketFromString(ticket.numbers)}
            ticketNumber={ticket.id}
            timestamp={ticket.timestamp}
          />
        ))}
      </div>
      <div className='px-6 pb-4 pt-3'>
        <Pagination
          isFirstPage={currentPage === 1}
          isLastPage={!hasNextPage}
          onPrev={() => getCurrentTicket(currentPage - 1, sort)}
          onNext={() => getCurrentTicket(currentPage + 1, sort)}
        />
      </div>
    </div>
  )
}

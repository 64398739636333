import { cx } from 'src/utils/helpers'
import TicketIcon from './icons/TicketIcon'

interface Props {
  className?: string
  ticketCount: number
}

export default function TicketsInCirculation({ className, ticketCount }: Props) {
  return (
    <div className={cx('space-y-[22px] rounded-2xl bg-card-primary p-[22px]', className)}>
      <div className='flex items-center gap-2'>
        <TicketIcon className='h-6' />
        <p className='text-lg font-medium text-white'>Tickets in circulation</p>
      </div>
      <div>
        <div className='flex gap-0.5'>
          {Array(4)
            .fill(0)
            .map((_, i) => (
              <p
                className='shadow-1 flex h-[58px] w-[58px] items-center justify-center rounded-[5px] bg-card-secondary text-[32px] font-semibold text-white'
                key={i}
              >
                {ticketCount.toString().padStart(4, '0')[i]}
              </p>
            ))}
        </div>
        <p className='text-sm font-medium leading-loose text-gray'>Tickets</p>
      </div>
    </div>
  )
}

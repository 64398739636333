export default function Learning2Icon({ className }: IconProps) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 110 100' fill='none' className={className}>
      <g clipPath='url(#clip0_57_13975)'>
        <path d='M66.6751 97.6299L0.225098 59.2599L43.3251 34.3799L109.775 72.7399L66.6751 97.6299Z' fill='#485262' />
        <path
          d='M104.655 72.8098L66.805 94.6598L58.335 89.7698L51.545 85.8498L44.345 81.6998L26.775 71.5498L5.35498 59.1898L43.195 37.3398L54.475 43.8498L72.055 53.9898L79.245 58.1498L86.035 62.0698L104.655 72.8098Z'
          fill='url(#paint0_linear_57_13975)'
        />
        <path d='M79.2243 65.3199L56.1743 52.0099L59.4343 50.1299L82.4843 63.4399L79.2243 65.3199Z' fill='#151A26' />
        <path d='M69.8447 65.8198L55.3247 57.4298L58.5747 55.5498L73.1047 63.9398L69.8447 65.8198Z' fill='#151A26' />
        <path d='M87.605 80.4799L79.145 75.5899L80.285 74.9399L88.745 79.8199L87.605 80.4799Z' fill='#253242' />
        <path d='M84.7144 82.1501L76.2544 77.2601L77.3944 76.6001L85.8544 81.4901L84.7144 82.1501Z' fill='#253242' />
        <path d='M81.8247 83.81L73.3647 78.93L74.5047 78.27L82.9647 83.16L81.8247 83.81Z' fill='#253242' />
        <path d='M78.9345 85.48L73.1646 82.15L74.3046 81.5L80.0745 84.83L78.9345 85.48Z' fill='#253242' />
        <path d='M72.6548 89.1098L64.1948 84.2298L65.3348 83.5698L73.7948 88.4498L72.6548 89.1098Z' fill='#253242' />
        <path d='M69.7646 90.78L63.9946 87.45L65.1346 86.79L70.9046 90.12L69.7646 90.78Z' fill='#253242' />
        <path
          d='M44.8151 60.1901L37.4651 55.9501C36.8751 55.6101 36.9451 55.0101 37.6351 54.6201C38.3151 54.2301 39.3451 54.1801 39.9351 54.5201L47.2851 58.7601C47.8751 59.1001 47.8051 59.7001 47.1151 60.0901C46.4351 60.4801 45.4051 60.5301 44.8151 60.1901Z'
          fill='#253242'
        />
        <path
          d='M54.7449 64.5C53.2649 65.36 50.8649 65.35 49.3849 64.5C47.9049 63.65 47.9049 62.26 49.3849 61.4C50.8649 60.54 53.2649 60.55 54.7449 61.4C56.2249 62.25 56.2249 63.64 54.7449 64.5Z'
          fill='#141B25'
        />
        <path
          d='M62.2547 68.8301C60.7747 69.6801 58.3747 69.6801 56.8947 68.8301C55.4147 67.9801 55.4147 66.5901 56.8947 65.7301C58.3747 64.8701 60.7747 64.8801 62.2547 65.7301C63.7347 66.5801 63.7347 67.9701 62.2547 68.8301Z'
          fill='#151A26'
        />
        <path
          d='M69.7654 73.1701C68.2854 74.0201 65.8854 74.0201 64.4054 73.1701C62.9254 72.3201 62.9254 70.9301 64.4054 70.0701C65.8854 69.2201 68.2854 69.2201 69.7654 70.0701C71.2454 70.9201 71.2454 72.3101 69.7654 73.1701Z'
          fill='#151A26'
        />
        <path
          d='M38.075 64.0803L28.305 58.4403C27.715 58.1003 27.785 57.5003 28.475 57.1103C29.155 56.7203 30.185 56.6703 30.775 57.0103L40.545 62.6503C41.135 62.9903 41.065 63.5903 40.375 63.9803C39.695 64.3703 38.665 64.4203 38.075 64.0803Z'
          fill='#253242'
        />
        <path
          d='M47.9949 68.3903C46.5149 69.2403 44.1149 69.2403 42.6349 68.3903C41.1549 67.5403 41.1549 66.1503 42.6349 65.2903C44.1149 64.4403 46.5149 64.4403 47.9949 65.2903C49.4749 66.1403 49.4749 67.5303 47.9949 68.3903Z'
          fill='#151A26'
        />
        <path
          d='M55.5047 72.7302C54.0247 73.5802 51.6247 73.5802 50.1447 72.7302C48.6647 71.8802 48.6647 70.4902 50.1447 69.6302C51.6247 68.7802 54.0247 68.7802 55.5047 69.6302C56.9847 70.4802 56.9847 71.8702 55.5047 72.7302Z'
          fill='#141B25'
        />
        <path
          d='M63.0252 77.0603C61.5452 77.9103 59.1452 77.9103 57.6652 77.0603C56.1852 76.2103 56.1852 74.8203 57.6652 73.9603C59.1452 73.1103 61.5452 73.1103 63.0252 73.9603C64.5052 74.8103 64.5052 76.2003 63.0252 77.0603Z'
          fill='#141B25'
        />
        <path
          d='M31.3249 67.97L18.5149 60.58C17.9249 60.24 17.9949 59.64 18.6849 59.25C19.3649 58.86 20.3949 58.81 20.9849 59.15L33.7949 66.54C34.3849 66.88 34.3149 67.48 33.6249 67.87C32.9449 68.26 31.9149 68.31 31.3249 67.97Z'
          fill='#253242'
        />
        <path
          d='M41.2547 72.2798C39.7747 73.1298 37.3747 73.1298 35.8947 72.2798C34.4147 71.4298 34.4147 70.0398 35.8947 69.1798C37.3747 68.3198 39.7747 68.3298 41.2547 69.1798C42.7347 70.0298 42.7347 71.4198 41.2547 72.2798Z'
          fill='#141B25'
        />
        <path
          d='M48.7654 76.6202C47.2854 77.4702 44.8854 77.4702 43.4054 76.6202C41.9254 75.7702 41.9254 74.3802 43.4054 73.5202C44.8854 72.6602 47.2854 72.6702 48.7654 73.5202C50.2454 74.3702 50.2454 75.7602 48.7654 76.6202Z'
          fill='#151A26'
        />
        <path
          d='M56.2752 80.9499C54.7952 81.7999 52.3952 81.7999 50.9152 80.9499C49.4352 80.0999 49.4352 78.7099 50.9152 77.8499C52.3952 76.9999 54.7952 76.9999 56.2752 77.8499C57.7552 78.6999 57.7552 80.0899 56.2752 80.9499Z'
          fill='#151A26'
        />
        <path
          d='M49.165 44.64C46.595 43.15 42.415 43.15 39.845 44.64C37.275 46.13 37.275 48.54 39.845 50.02C42.415 51.5 46.595 51.51 49.165 50.02C51.735 48.53 51.735 46.12 49.165 44.64ZM40.815 49.46C38.775 48.28 38.785 46.38 40.815 45.2C42.855 44.02 46.155 44.02 48.185 45.2C50.225 46.38 50.225 48.28 48.185 49.46C46.145 50.64 42.835 50.64 40.795 49.46H40.815Z'
          fill='#151A26'
        />
        <path
          d='M47.0848 48.8301C45.6548 49.6501 43.3448 49.6501 41.9148 48.8301C40.4848 48.0101 40.4848 46.6701 41.9148 45.8401C43.3448 45.0201 45.6548 45.0201 47.0848 45.8401C48.5148 46.6601 48.5148 48.0001 47.0848 48.8301Z'
          fill='#151A26'
        />
        <path d='M66.6751 99.4898L0.225098 61.1198V59.2798L66.6751 97.6498V99.4898Z' fill='#222A37' />
        <path d='M109.775 74.5998L66.6753 99.4898V97.6498L109.775 72.7598V74.5998Z' fill='#222A37' />
        <path d='M75.5852 57.0601L102.435 41.5601L41.9452 6.64014L15.0952 22.1401L75.5852 57.0601Z' fill='#253242' />
        <path d='M75.3456 55.2702L99.3356 41.4202L42.1856 8.43018L18.2056 22.2802L75.3456 55.2702Z' fill='#00CDAC' />
        <path
          d='M77.9549 52.41L94.3749 42.93C95.6249 42.21 95.7649 41.11 94.6749 40.48L43.8149 11.12C42.7249 10.49 40.8349 10.57 39.5749 11.3L23.1549 20.78C21.9049 21.5 21.7649 22.6 22.8549 23.23L73.7149 52.59C74.8049 53.22 76.6949 53.14 77.9549 52.41Z'
          fill='#253242'
        />
        <path
          d='M51.3856 27.5903C46.4956 30.4103 45.8456 34.6103 49.9156 36.9603C53.9956 39.3103 61.2556 38.9303 66.1456 36.1103C71.0356 33.2903 71.6856 29.0903 67.6156 26.7403C63.5356 24.3903 56.2756 24.7703 51.3856 27.5903Z'
          fill='#00CDAC'
        />
        <path
          d='M38.9157 20.3904C37.2957 21.3204 37.2957 22.8404 38.9157 23.7704C40.5357 24.7004 43.1557 24.7004 44.7757 23.7704C46.3957 22.8404 46.3957 21.3204 44.7757 20.3904C43.1557 19.4604 40.5357 19.4604 38.9157 20.3904Z'
          fill='#00CDAC'
        />
        <path
          d='M78.3255 43.1503C76.7055 44.0803 74.0855 44.0803 72.4655 43.1503C70.8455 42.2203 70.8455 40.7003 72.4655 39.7703C74.0855 38.8403 76.7055 38.8403 78.3255 39.7703C79.9455 40.7003 79.9455 42.2203 78.3255 43.1503Z'
          fill='#00CDAC'
        />
        <path
          d='M76.7748 42.2501C76.0148 42.6901 74.7748 42.6901 74.0148 42.2501C73.2548 41.8101 73.2548 41.1001 74.0148 40.6601C74.7748 40.2201 76.0148 40.2201 76.7748 40.6601C77.5348 41.1001 77.5348 41.8101 76.7748 42.2501Z'
          fill='#253242'
        />
        <path d='M31.4849 23.0102L40.2449 28.0702L41.7149 27.2202L32.9549 22.1602L31.4849 23.0102Z' fill='#00CDAC' />
        <path d='M44.4653 15.5102L53.2253 20.5702L54.7053 19.7202L45.9353 14.6602L44.4653 15.5102Z' fill='#00CDAC' />
        <path d='M43.7954 17.5702L49.6554 20.9602L51.1254 20.1102L45.2654 16.7202L43.7954 17.5702Z' fill='#00CDAC' />
        <path d='M62.5249 44.2399L70.5749 48.8899L73.3249 47.2899L65.2849 42.6499L62.5249 44.2399Z' fill='#00CDAC' />
        <path d='M76.6548 34.1L87.6048 40.42L89.0748 39.57L78.1348 33.25L76.6548 34.1Z' fill='#00CDAC' />
        <path d='M76.9556 36.7999L82.9356 40.2499L84.4056 39.3999L78.4256 35.9399L76.9556 36.7999Z' fill='#00CDAC' />
        <path
          d='M55.1255 30.24C54.8655 30.79 53.6055 30.94 53.1255 31.65C52.7055 32.29 52.9354 33.12 53.0054 33.32C53.0254 33.35 53.0254 33.37 53.0254 33.37C53.0254 33.44 52.9454 33.5 52.8354 33.57C52.9254 33.73 53.0654 33.87 53.2754 34C54.8754 34.96 57.3154 35.11 59.9854 33.78C60.0454 33.31 61.3855 32.49 63.0354 31.86C64.7155 31.23 64.6654 30.85 63.8454 30.23C63.0254 29.59 63.9055 28.76 62.7155 28.5C61.5055 28.23 59.5754 28.39 57.9554 29.18C56.3254 29.96 55.3954 29.69 55.1454 30.25L55.1255 30.24Z'
          fill='#00CDAC'
        />
        <path
          d='M53.9749 30.3103C52.9149 30.9203 51.6149 31.3303 51.0349 31.6803C50.7549 31.8603 51.2549 32.1103 51.8249 32.4103C52.1249 32.5603 52.4349 32.7203 52.6349 32.8903C52.8349 33.0203 52.9949 33.1703 52.9949 33.3103C52.9349 33.1103 52.6949 32.2803 53.1149 31.6403C53.5949 30.9303 54.8549 30.7803 55.1149 30.2303C55.3649 29.6703 56.2949 29.9403 57.9249 29.1603C59.5449 28.3703 61.4649 28.2103 62.6849 28.4803C63.8849 28.7403 62.9949 29.5703 63.8149 30.2103C64.6349 30.8303 64.6849 31.2203 63.0049 31.8403C61.3549 32.4703 60.0249 33.2903 59.9549 33.7603C57.2849 35.0903 54.8449 34.9403 53.2449 33.9803C53.0449 33.8503 52.9049 33.7103 52.8049 33.5503C52.6049 33.6703 52.2649 33.7703 51.8449 33.8903C51.0849 34.0803 50.0449 34.2703 49.0649 34.4203C49.4249 35.1303 50.0749 35.7703 51.0049 36.3103C52.0149 36.8903 53.2549 37.2903 54.5749 37.4903C56.3349 37.7503 58.2749 37.7103 60.1549 37.3603C60.5249 36.6803 60.8549 36.0103 61.2749 35.6003C62.4049 34.5103 64.4449 34.5603 64.5349 33.5903C64.6249 32.6203 66.6449 31.2703 65.8549 30.3903C65.0449 29.5103 64.5449 28.6703 63.7849 28.2803C63.7549 28.2603 63.7249 28.2403 63.6749 28.2403C62.0249 27.4703 59.3349 27.9103 57.4749 28.2703C55.5749 28.6303 55.0049 29.7003 53.9449 30.3103H53.9749Z'
          fill='#253242'
        />
        <path
          d='M51.8647 33.9001C52.6447 34.7601 54.1847 35.6001 55.9347 35.5301C57.7847 35.4501 59.9747 33.7701 59.9747 33.7701C57.3047 35.1001 54.8647 34.9501 53.2647 33.9901C53.0647 33.8601 52.9247 33.7201 52.8247 33.5601C52.6247 33.6801 52.2847 33.7801 51.8647 33.9001Z'
          fill='#253242'
        />
        <path
          d='M58.6849 30.3604C58.6849 30.3604 56.1749 31.0304 55.9049 31.3004C55.6249 31.5704 56.6249 32.3204 56.9249 32.3204C57.2249 32.3204 56.6649 31.9304 56.7649 31.6004C56.8649 31.2704 58.6849 30.3604 58.6849 30.3604Z'
          fill='#253242'
        />
        <path
          d='M60.8155 32.4299C60.9155 32.3699 60.7755 31.1899 59.7255 30.8299C58.6755 30.4699 59.0155 31.1199 58.3655 31.3099C57.7155 31.4999 60.1255 32.8799 60.8255 32.4299H60.8155Z'
          fill='#253242'
        />
        <path
          d='M57.9455 30.0201C57.9955 29.6201 57.0455 29.2401 56.1455 29.2901C55.2455 29.3401 55.7055 29.8101 56.2055 30.1601C56.6955 30.5101 57.8755 30.6401 57.9455 30.0301V30.0201Z'
          fill='#253242'
        />
        <path
          d='M53.9757 30.3101C52.9157 30.9201 51.6157 31.3301 51.0357 31.6801C50.7557 31.8601 51.2557 32.1101 51.8257 32.4101C52.1257 32.5601 52.4357 32.7201 52.6357 32.8901C52.8357 33.0201 52.9957 33.1701 52.9957 33.3101C52.9357 33.1101 52.6957 32.2801 53.1157 31.6401C53.5957 30.9301 54.8557 30.7801 55.1157 30.2301C55.3657 29.6701 56.2957 29.9401 57.9257 29.1601C59.5457 28.3701 61.4657 28.2101 62.6857 28.4801C63.8857 28.7401 62.9957 29.5701 63.8157 30.2101C64.6357 30.8301 64.6857 31.2201 63.0057 31.8401C61.3557 32.4701 60.0257 33.2901 59.9557 33.7601C59.8957 34.2301 57.8857 35.2001 56.5857 35.8001C55.6857 36.2101 54.8557 36.8501 54.5857 37.5001C56.3457 37.7601 58.2857 37.7201 60.1657 37.3701C60.5357 36.6901 60.8657 36.0201 61.2857 35.6101C62.4157 34.5201 64.4557 34.5701 64.5457 33.6001C64.6357 32.6301 66.6557 31.2801 65.8657 30.4001C65.0557 29.5201 64.5557 28.6801 63.7957 28.2901C63.7657 28.2701 63.7357 28.2501 63.6857 28.2501C62.0357 27.4801 59.3457 27.9201 57.4857 28.2801C55.5857 28.6401 55.0157 29.7101 53.9557 30.3201L53.9757 30.3101Z'
          fill='#253242'
        />
        <path
          d='M56.2753 33.2903C56.1653 32.8903 55.7653 32.5703 55.1953 32.4503C54.6253 32.3303 54.1453 31.8003 54.1453 31.8003C54.1453 31.8003 54.0353 32.5303 54.6253 32.8503C55.2253 33.1703 56.2753 33.2903 56.2753 33.2903Z'
          fill='#253242'
        />
        <path d='M75.5852 58.2301L15.0952 23.3101V22.1401L75.5852 57.0601V58.2301Z' fill='#222A37' />
        <path d='M102.435 42.7301L75.5854 58.2301V57.0601L102.435 41.5601V42.7301Z' fill='#222A37' />
        <path d='M75.6947 52.89L103.665 39.52L46.0847 0L18.1147 13.36L75.6947 52.89Z' fill='#253242' />
        <path d='M75.5948 51.0798L100.585 39.1398L46.1848 1.7998L21.1948 13.7398L75.5948 51.0798Z' fill='#00CDAC' />
        <path
          d='M78.4152 48.4302L95.5252 40.2602C96.8352 39.6402 97.0552 38.5502 96.0152 37.8402L47.6052 4.61018C46.5752 3.90018 44.6752 3.83018 43.3652 4.46018L26.2552 12.6302C24.9452 13.2502 24.7252 14.3402 25.7652 15.0502L74.1852 48.2802C75.2152 48.9902 77.1152 49.0602 78.4252 48.4302H78.4152Z'
          fill='#253242'
        />
        <path
          d='M53.8645 21.6202C48.7745 24.0502 47.7945 28.1802 51.6745 30.8502C55.5545 33.5101 62.8245 33.7002 67.9145 31.2702C73.0045 28.8401 73.9845 24.7102 70.1045 22.0402C66.2245 19.3802 58.9545 19.1902 53.8645 21.6202Z'
          fill='#00CDAC'
        />
        <path
          d='M41.9844 13.4698C40.2944 14.2798 40.1844 15.7898 41.7244 16.8398C43.2644 17.8998 45.8844 18.0998 47.5744 17.2998C49.2644 16.4998 49.3744 14.9798 47.8344 13.9298C46.2944 12.8698 43.6744 12.6698 41.9844 13.4698Z'
          fill='#00CDAC'
        />
        <path
          d='M79.5152 39.2299C77.8252 40.0399 75.2152 39.8299 73.6652 38.7699C72.1252 37.7099 72.2452 36.1999 73.9252 35.3999C75.6152 34.5899 78.2252 34.7999 79.7752 35.8599C81.3152 36.9199 81.1952 38.4299 79.5152 39.2299Z'
          fill='#00CDAC'
        />
        <path
          d='M78.0349 38.2098C77.2449 38.5898 76.0049 38.4898 75.2849 37.9998C74.5549 37.4998 74.6149 36.7898 75.4049 36.4098C76.1949 36.0298 77.4349 36.1298 78.1549 36.6198C78.8849 37.1198 78.8249 37.8298 78.0349 38.2098Z'
          fill='#253242'
        />
        <path d='M34.3745 15.5L42.7145 21.23L44.2545 20.49L35.9145 14.77L34.3745 15.5Z' fill='#00CDAC' />
        <path d='M47.9048 9.0398L56.2448 14.7598L57.7848 14.0298L49.4348 8.2998L47.9048 9.0398Z' fill='#00CDAC' />
        <path d='M47.0747 11.0401L52.6547 14.8701L54.1847 14.1401L48.6047 10.3101L47.0747 11.0401Z' fill='#00CDAC' />
        <path d='M63.6743 39.08L71.3343 44.34L74.2043 42.97L66.5443 37.71L63.6743 39.08Z' fill='#00CDAC' />
        <path d='M78.5552 30.0698L88.9752 37.2298L90.5052 36.4898L80.0852 29.3398L78.5552 30.0698Z' fill='#00CDAC' />
        <path d='M78.6343 32.7898L84.3243 36.6898L85.8643 35.9598L80.1643 32.0498L78.6343 32.7898Z' fill='#00CDAC' />
        <path
          d='M57.3845 24.5499C57.0745 25.0799 55.8145 25.1299 55.2745 25.7999C54.8045 26.3999 54.9745 27.2499 55.0245 27.4499C55.0345 27.4799 55.0345 27.4999 55.0345 27.4999C55.0345 27.5699 54.9445 27.6299 54.8345 27.6799C54.9145 27.8499 55.0445 27.9899 55.2345 28.1399C56.7545 29.2199 59.1745 29.5599 61.9445 28.4499C62.0445 27.9899 63.4345 27.2699 65.1345 26.7699C66.8545 26.2699 66.8445 25.8899 66.0645 25.1999C65.2945 24.4999 66.2445 23.7399 65.0645 23.3899C63.8745 23.0299 61.9445 23.0399 60.2745 23.6999C58.5845 24.3499 57.6745 24.0099 57.3845 24.5499Z'
          fill='#00CDAC'
        />
        <path
          d='M56.2349 24.5298C55.1349 25.0598 53.7949 25.3698 53.1949 25.6698C52.9049 25.8298 53.3749 26.1198 53.9249 26.4598C54.2049 26.6298 54.5049 26.8198 54.6949 27.0098C54.8849 27.1598 55.0249 27.3098 55.0149 27.4598C54.9649 27.2598 54.8049 26.4098 55.2649 25.8098C55.8049 25.1398 57.0649 25.0898 57.3749 24.5598C57.6649 24.0198 58.5749 24.3698 60.2649 23.7098C61.9349 23.0498 63.8749 23.0398 65.0549 23.3998C66.2249 23.7498 65.2749 24.5198 66.0549 25.2098C66.8249 25.8898 66.8449 26.2798 65.1249 26.7798C63.4349 27.2798 62.0349 27.9998 61.9349 28.4598C59.1649 29.5798 56.7449 29.2298 55.2249 28.1498C55.0349 27.9998 54.9049 27.8498 54.8249 27.6898C54.6149 27.7898 54.2649 27.8698 53.8349 27.9498C53.0649 28.0798 52.0049 28.1898 51.0249 28.2598C51.3249 28.9898 51.9249 29.6898 52.8149 30.2998C53.7749 30.9598 54.9849 31.4498 56.2849 31.7598C58.0149 32.1598 59.9549 32.2598 61.8549 32.0598C62.2849 31.4098 62.6549 30.7598 63.1049 30.3898C64.3249 29.3998 66.3449 29.5998 66.5149 28.6398C66.6849 27.6798 68.8049 26.4998 68.0849 25.5498C67.3449 24.6098 66.9149 23.7298 66.1849 23.2898C66.1549 23.2698 66.1249 23.2498 66.0849 23.2398C64.5049 22.3398 61.7849 22.5698 59.9049 22.7798C57.9849 22.9898 57.3349 24.0098 56.2249 24.5398L56.2349 24.5298Z'
          fill='#253242'
        />
        <path
          d='M53.8452 27.9402C54.5552 28.8602 56.0252 29.8102 57.7752 29.8902C59.6252 29.9502 61.9452 28.4502 61.9452 28.4502C59.1752 29.5702 56.7552 29.2202 55.2352 28.1402C55.0452 27.9902 54.9152 27.8402 54.8352 27.6802C54.6252 27.7802 54.2752 27.8602 53.8452 27.9402Z'
          fill='#253242'
        />
        <path
          d='M60.9244 24.9502C60.9244 24.9502 58.3744 25.4202 58.0744 25.6702C57.7744 25.9202 58.7144 26.7402 59.0144 26.7702C59.3144 26.8002 58.7844 26.3602 58.9044 26.0402C59.0244 25.7202 60.9244 24.9502 60.9244 24.9502Z'
          fill='#253242'
        />
        <path
          d='M62.8948 27.1802C62.9948 27.1302 62.9548 25.9402 61.9248 25.5002C60.9048 25.0602 61.1948 25.7302 60.5248 25.8702C59.8648 26.0102 62.1548 27.5702 62.8848 27.1702L62.8948 27.1802Z'
          fill='#253242'
        />
        <path
          d='M60.2146 24.5498C60.2946 24.1598 59.3746 23.6998 58.4746 23.6798C57.5746 23.6598 57.9946 24.1598 58.4646 24.5498C58.9346 24.9398 60.0946 25.1598 60.2146 24.5498Z'
          fill='#253242'
        />
        <path
          d='M56.2344 24.5298C55.1344 25.0598 53.7944 25.3698 53.1944 25.6698C52.9044 25.8298 53.3744 26.1198 53.9244 26.4598C54.2044 26.6298 54.5044 26.8198 54.6944 27.0098C54.8844 27.1598 55.0244 27.3098 55.0144 27.4598C54.9644 27.2598 54.8044 26.4098 55.2644 25.8098C55.8044 25.1398 57.0644 25.0898 57.3744 24.5598C57.6644 24.0198 58.5744 24.3698 60.2644 23.7098C61.9344 23.0498 63.8744 23.0398 65.0544 23.3998C66.2244 23.7498 65.2744 24.5198 66.0544 25.2098C66.8244 25.8898 66.8444 26.2798 65.1244 26.7798C63.4344 27.2798 62.0344 27.9998 61.9344 28.4598C61.8344 28.9198 59.7644 29.7398 58.4144 30.2298C57.4844 30.5698 56.6144 31.1398 56.2844 31.7598C58.0144 32.1598 59.9544 32.2598 61.8544 32.0598C62.2844 31.4098 62.6544 30.7598 63.1044 30.3898C64.3244 29.3998 66.3444 29.5998 66.5144 28.6398C66.6844 27.6798 68.8044 26.4998 68.0844 25.5498C67.3444 24.6098 66.9144 23.7298 66.1844 23.2898C66.1544 23.2698 66.1244 23.2498 66.0844 23.2398C64.5044 22.3398 61.7844 22.5698 59.9044 22.7798C57.9844 22.9898 57.3344 24.0098 56.2244 24.5398L56.2344 24.5298Z'
          fill='#253242'
        />
        <path
          d='M58.2947 27.6798C58.2147 27.2698 57.8447 26.9198 57.2847 26.7598C56.7247 26.5898 56.2947 26.0298 56.2947 26.0298C56.2947 26.0298 56.1247 26.7498 56.6947 27.1198C57.2647 27.4898 58.3047 27.6898 58.3047 27.6898L58.2947 27.6798Z'
          fill='#253242'
        />
        <path d='M75.6049 54.0501L18.0249 14.5201L18.1149 13.3701L75.6949 52.8901L75.6049 54.0501Z' fill='#222A37' />
        <path d='M103.575 40.68L75.605 54.05L75.695 52.89L103.665 39.52L103.575 40.68Z' fill='#222A37' />
      </g>
      <defs>
        <linearGradient
          id='paint0_linear_57_13975'
          x1='5.35498'
          y1='94.6598'
          x2='55.305'
          y2='8.67247'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#8B1666' />
          <stop offset='1' stopColor='#E52979' />
        </linearGradient>
        <clipPath id='clip0_57_13975'>
          <rect width='109.55' height='99.49' fill='white' transform='translate(0.225098)' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default function LogoMarkIcon({ className }: IconProps) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 244 244' fill='none' className={className}>
      <path
        d='M122 244C189.379 244 244 189.379 244 122C244 54.6213 189.379 0 122 0C54.6213 0 0 54.6213 0 122C0 189.379 54.6213 244 122 244Z'
        fill='url(#paint0_linear_60_37634)'
      />
      <path
        d='M122.001 202.023C95.4326 202.023 70.4451 190.275 53.4555 169.761C42.7466 156.838 35.9688 141.249 33.8 124.666L33.4385 121.91L52.1451 103.203V54.3125H57.7933C70.0385 54.3125 81.1089 60.5029 87.5251 70.534C97.8726 64.2081 109.666 60.9095 122.001 60.9095C134.337 60.9095 146.266 64.2532 156.658 70.6243C163.075 60.7288 174.281 54.4029 186.345 54.3125H191.993V103.203L210.564 121.774L210.203 124.53C208.079 141.068 201.347 156.612 190.683 169.535C173.693 190.184 148.661 202.023 121.956 202.023H122.001Z'
        fill='white'
      />
      <path
        d='M212.598 115.855L205.775 109.032L197.596 100.853V48.5742H186.932C178.799 48.6646 170.756 50.9238 163.933 55.352C160.544 57.5661 157.562 60.232 155.032 63.2142C144.865 57.9276 133.659 55.2164 121.956 55.2164C110.253 55.2164 99.1376 57.9276 89.0161 63.169C86.7117 60.4579 84.0457 58.0179 81.0635 55.9394C74.1954 51.195 66.1524 48.6646 57.748 48.6646H46.4969V100.853L38.3183 109.032L31.3598 115.991L27.4287 119.922L28.1517 125.434C30.4561 143.056 37.6857 159.639 49.0724 173.376C67.1465 195.155 93.7154 207.671 121.956 207.671C150.197 207.671 176.992 195.065 195.021 173.15C206.317 159.413 213.501 142.876 215.761 125.299L216.484 119.786L212.552 115.855H212.598ZM195.337 152.636L168.632 124.124C162.984 118.114 152.953 117.211 146.356 122.181L146.221 122.271C139.307 127.468 137.5 137.002 141.973 144.367L148.615 155.256C159.189 172.608 147.486 194.974 127.152 196.194C125.435 196.285 123.718 196.33 122.001 196.33C120.284 196.33 118.567 196.285 116.895 196.194C96.6072 194.974 84.9043 172.608 95.4324 155.256L101.849 144.683C105.961 137.905 105.102 129.094 99.4539 123.582C92.6761 116.939 81.8317 117.211 75.3702 124.079L48.6657 152.591C43.9213 143.87 40.7132 134.155 39.3576 123.898L46.3161 116.939L57.7932 105.462V59.8705C70.3546 59.8705 81.0635 67.6424 85.4013 78.6676C89.6487 75.4594 94.348 72.7935 99.3635 70.7601L113.235 94.7987C117.121 101.531 126.836 101.531 130.722 94.7987L144.594 70.7601C149.655 72.7935 154.399 75.5046 158.692 78.7579C162.984 67.7327 173.829 59.9157 186.345 59.8253V105.417L197.822 116.894L204.645 123.717C203.335 134.019 200.081 143.734 195.337 152.545V152.636Z'
        fill='#161D27'
      />
      <path
        d='M128.598 154.172H115.404C109.485 154.172 106.457 161.266 110.524 165.559L117.799 173.195C120.058 175.59 123.899 175.59 126.158 173.195L133.433 165.559C137.545 161.266 134.472 154.172 128.553 154.172H128.598Z'
        fill='#161D27'
      />
      <defs>
        <linearGradient
          id='paint0_linear_60_37634'
          x1='-1.68853e-06'
          y1='244'
          x2='244.768'
          y2='0.77335'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#8B1666' />
          <stop offset='1' stopColor='#E52979' />
        </linearGradient>
      </defs>
    </svg>
  )
}

import { cx } from 'src/utils/helpers'
import WadOfMoneyIcon from './icons/WadOfMoneyIcon'

interface Props {
  amount?: number | string | null
  className?: string
  hideUnit?: boolean
  size?: 'small' | 'medium'
  unit?: 'ETH' | 'USD'
  whiteIcon?: boolean
}

export default function Money({
  amount = 0,
  className,
  hideUnit = false,
  size = 'medium',
  unit = 'ETH',
  whiteIcon = false,
}: Props) {
  return (
    <div className={cx('flex w-fit items-center', size === 'medium' ? 'gap-3' : 'gap-2', className)}>
      <WadOfMoneyIcon className='h-6' mode={whiteIcon ? 'white' : 'gradient'} />
      <p className='text-sm font-medium text-white'>
        {amount ? (typeof amount === 'number' ? amount : +amount).toLocaleString() : 0}
      </p>
      {!hideUnit && <p className='text-sm font-medium text-gray'>{unit}</p>}
    </div>
  )
}

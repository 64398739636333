import Layout from 'src/layouts'
import HeroSection from './Components/HeroSection'
import OverviewSection from './Components/OverviewSection'
import RecentDrawsSection from './Components/RecentDrawsSection'
import HowDoesItWorkSection from './Components/HowDoesItWorkSection'
import RecentBetsSection from './Components/RecentBetsSection'
import RecentWinnersSection from './Components/RecentWinnersSection'

export default function Home() {
  return (
    <Layout hideHeader hideShineImage>
      <HeroSection />
      <OverviewSection />
      <RecentDrawsSection />
      <HowDoesItWorkSection />
      <RecentBetsSection />
      <RecentWinnersSection />
    </Layout>
  )
}

import axios from 'axios'
import { BACKEND_URL } from 'src/config'

const instance = axios.create({
  baseURL: BACKEND_URL,
  headers: {
    'Content-Type': 'application/json',
  },
})

instance.interceptors.request.use((config) => {
  config.headers.Authorization = localStorage.getItem('auth-token')

  return config
})

export default instance

export default function CheckIcon({ className }: IconProps) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 13 12' fill='none' className={className}>
      <path
        d='M10.1665 3L4.6665 8.5L2.1665 6'
        stroke='url(#paint0_linear_967_792)'
        strokeWidth='1.6666'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <defs>
        <linearGradient
          id='paint0_linear_967_792'
          x1='2.1665'
          y1='8.5'
          x2='7.32964'
          y2='1.03729'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#8B1666' />
          <stop offset='1' stopColor='#E52979' />
        </linearGradient>
      </defs>
    </svg>
  )
}

import { useState } from 'react'
import MinusButton from './MinusButton'
import PlusButton from './PlusButton'
import { cx } from 'src/utils/helpers'

interface Props {
  className?: string
  onAddTickets?: React.Dispatch<number>
}

export default function AddTicketsButton({ className, onAddTickets }: Props) {
  const [count, setCount] = useState<number>(1)

  return (
    <div
      className={cx(
        'btn-primary-outlined-no-hover relative flex h-[54px] items-center justify-center sm:pl-[128px]',
        className
      )}
    >
      <div className='absolute left-2 top-1/2 flex -translate-y-1/2 items-center'>
        <MinusButton onClick={() => setCount((prev) => Math.max(1, prev - 1))} />
        <p className='w-9 text-center text-sm font-medium text-white'>{count}</p>
        <PlusButton onClick={() => setCount((prev) => prev + 1)} />
      </div>
      <p
        className='cursor-pointer text-sm font-bold uppercase text-white'
        onClick={() => (onAddTickets ? onAddTickets(count) : null)}
      >
        Add Tickets
      </p>
    </div>
  )
}

import { cx } from 'src/utils/helpers'
import CopyButton from './buttons/CopyButton'

interface Props {
  className?: string
  ticket: number[]
  ticketNumber: number
}

export default function MyTicket({ className, ticket, ticketNumber }: Props) {
  return (
    <div className={cx('', className)}>
      <div className='relative flex h-[60px] items-center justify-between rounded-2xl bg-card-secondary px-3'>
        <p className='text-lg font-medium text-white'>Ticket #{ticketNumber.toString().padStart(2, '0')}</p>
        <CopyButton />
      </div>
      <div className='ml-5 w-[calc(100%-40px)] border-t-2 border-dashed border-card-secondary' />
      <div className='flex justify-between rounded-2xl border border-card-secondary bg-card-primary px-5 py-4'>
        {ticket.map((number, i) => (
          <p
            className='shadow-1 base-gradient flex h-9 w-9 items-center justify-center rounded-full text-sm font-semibold text-white'
            key={i}
          >
            {number.toString().padStart(2, '0')}
          </p>
        ))}
      </div>
    </div>
  )
}

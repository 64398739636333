import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { apiGetWinnings } from 'src/api/public.api'
import ExternalLinkIcon from 'src/components/icons/ExternalLinkIcon'
import WadOfMoneyIcon from 'src/components/icons/WadOfMoneyIcon'
import { formatDate, generateLink, shortenAddress } from 'src/utils/helpers'
import { formatEther } from 'viem'

interface WinnerProps {
  winning: Record<string, any>
}

const Winner: React.FC<WinnerProps> = ({ winning }) => {
  return (
    <div className='space-y-[22px] rounded-2xl bg-card-primary p-[22px]'>
      <div className='flex items-center justify-between'>
        <div>
          <Link to={generateLink(winning.address)} className='flex items-center gap-1.5' target='_blank'>
            <p className='text-sm font-medium text-white'>{shortenAddress(winning.address)}</p>
            <ExternalLinkIcon className='h-6' />
          </Link>
          <p className='text-sm text-gray'>{formatDate(winning.timestamp)}</p>
        </div>
        {/* <button className='shadow-1 h-7 w-[120px] rounded-lg bg-card-secondary text-sm font-semibold text-white'>
          # 123 
        </button> */}
      </div>
      <div className='flex items-center justify-between'>
        <div className='flex items-center gap-3'>
          <WadOfMoneyIcon className='h-[58px]' />
          <p className='text-4xl font-medium tracking-[-1px] text-white'>
            {(+formatEther(winning.prize_amount[0].amount_wei)).toFixed(2)}
          </p>
        </div>
        <p className='text-sm font-medium text-gray'>ETH</p>
      </div>
    </div>
  )
}

export default function RecentWinnersSection() {
  const [winnings, setWinnings] = useState<any[]>([])

  useEffect(() => {
    getWinnings(1)
  }, [])

  const getWinnings = async (page: number) => {
    const data = await apiGetWinnings(page)
    setWinnings(data.winnings)
  }

  return (
    <section className='mt-[90px] lg:mt-12'>
      <div className='container space-y-8'>
        <h1>Recent winners</h1>
        <div className='grid grid-cols-4 gap-6 lg:grid-cols-2 sm:grid-cols-1'>
          {winnings.map((winning, i) => (
            <Winner winning={winning} key={i} />
          ))}
        </div>
      </div>
    </section>
  )
}

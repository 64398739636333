import { toast } from 'react-hot-toast'
import { cx, getStringFromTicket } from 'src/utils/helpers'
import CopyButton from './buttons/CopyButton'

interface Props {
  className?: string
  tickets: Array<number[]>
}

export default function LuckyNumbers({ className, tickets }: Props) {
  const handleCopyTicket = (ticket: number[]) => {
    const str = getStringFromTicket(ticket)

    navigator.clipboard.writeText(str)

    toast.success(`Ticket ${str} copied`)
  }

  return (
    <div className={cx('space-y-3 rounded-2xl bg-card-primary p-[22px]', className)}>
      <p className='text-lg font-medium text-white'>Lucky numbers</p>
      {tickets.map((ticket, i) => (
        <div className='space-y-3' key={i}>
          <div className='flex justify-between'>
            {ticket.map((number, j) => (
              <p
                className='secondary-gradient shadow-1 flex h-9 w-9 items-center justify-center rounded-full text-sm font-semibold text-white'
                key={`ticket-number-${j}`}
              >
                {number.toString().padStart(2, '0')}
              </p>
            ))}
          </div>
          <CopyButton className='ml-auto block' onClick={() => handleCopyTicket(ticket)} />
          {i !== tickets.length - 1 && <div className='h-px bg-card-secondary' />}
        </div>
      ))}
    </div>
  )
}

import axios from 'src/services/axios'

export const apiGetCurrentTickets = async (page: number, sort_method: string) => {
  const { data } = await axios.post('/get-current-tickets', { page, sort_method })
  return data
}

export const apiGetInventoryHistory = async (page: number, sort_method: string, sort_direction: string) => {
  const { data } = await axios.post('/inventory-history', { page, sort_method, sort_direction })
  return data
}

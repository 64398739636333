import { cx } from 'src/utils/helpers'
import ChevronLeftIcon from '../icons/ChevronLeftIcon'

interface Props {
  className?: string
}

export default function BackToDrawButotn({ className }: Props) {
  return (
    <button className={cx('btn-primary-outlined flex h-10 items-center justify-center pl-3 pr-6', className)}>
      <ChevronLeftIcon className='h-6 stroke-white' />
      <p className='text-sm font-semibold text-white'>Back to Draw</p>
    </button>
  )
}
